import { toLonLat } from 'ol/src/proj';

import history from './history';

let historyTimeout;
let lastHistoryUpdate = 0;
let historyUpdateCount = 0;
let lastView;

let query;
if ('URLSearchParams' in window) {
	query = new URLSearchParams(history.location.search);
} else {
	query = {
		set: () => {},
		toString: () => '',
	};
}

function updateUri(map = false, uriPrefix = false, searchUpdates = {}) {
	lastView = map ? map.getView() : lastView;
	if (!lastView) return;

	const now = Date.now();
	clearTimeout(historyTimeout);
	if (lastHistoryUpdate + 300 > now) {
		historyTimeout = setTimeout(() => {
			updateUri(false, uriPrefix);
		}, 300);
		return;
	}

	historyUpdateCount = (historyUpdateCount || 0) + 1;
	lastHistoryUpdate = now;

	// debugger;

	let newUri = `${uriPrefix || history.location.pathname.split('@')[0]}`;

	const center = toLonLat(lastView.getCenter());
	newUri += `@${center[1].toFixed(5)},${center[0].toFixed(5)},z${Math.round(lastView.getZoom())}`;

	Object.entries(searchUpdates).forEach(([key, value]) => {
		if (value === null || value === undefined) {
			query.delete(key);
			return;
		}

		query.set(key, value);
	});
	const search = query.toString();

	history.replace(`${newUri}${search ? `?${search}` : ''}`);
}

export default updateUri;
