export const routeItems = [
	{
		title: 'bikeSettings.route.commuter',
		value: 'commuter',
	},
	{
		title: 'bikeSettings.route.leisure',
		value: 'leisure',
	},
	{
		title: 'bikeSettings.route.individual.title',
		value: 'individual',
	},
];

export const fitnessItems = [
	{
		title: 'bikeSettings.fitness.untrained',
		value: 'untrained',
	},
	{
		title: 'bikeSettings.fitness.average',
		value: 'average',
	},
	{
		title: 'bikeSettings.fitness.sporty',
		value: 'sporty',
	},
	{
		title: 'bikeSettings.fitness.electric',
		value: 'electric',
	},
	{
		title: 'bikeSettings.fitness.individual',
		value: 'individual',
	},
];

export const pavedPathsItems = [
	{
		title: 'bikeSettings.pavedPaths.only',
		value: 'only',
	},
	{
		title: 'bikeSettings.pavedPaths.prefer',
		value: 'prefer',
	},
	{
		title: 'bikeSettings.pavedPaths.avoid',
		value: 'avoid',
	},
];

export const speedItems = [
	{
		title: 'bikeSettings.fitness.speed.slow',
		value: 10,
	},
	{
		title: 'bikeSettings.fitness.speed.normal',
		value: 15,
	},
	{
		title: 'bikeSettings.fitness.speed.fast',
		value: 20,
	},
	{
		title: 'bikeSettings.fitness.speed.superfast',
		value: 25,
	},
];

export const pitchItems = [
	{
		title: 'bikeSettings.fitness.pitch.low',
		value: 0,
	},
	{
		title: 'bikeSettings.fitness.pitch.medium',
		value: 10,
	},
	{
		title: 'bikeSettings.fitness.pitch.high',
		value: 80,
	},
	{
		title: 'bikeSettings.fitness.pitch.full',
		value: 100,
	},
];
