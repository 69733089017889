import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as ArrowLeft } from './icons/arrow-left.svg';

const StyledHeader = styled.div`
	background-color: ${({ backgroundColor }) => backgroundColor};
	min-height: ${({ isSmall }) => (isSmall ? '5rem' : '10rem')};
	display: flex;
	flex-shrink: 0;
	position: relative;
	z-index: 10;
`;

const StyledButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: none;
	outline: none;
	position: absolute;
	top: 24px;
	left: 8px;
	width: 48px;
	height: 48px;
	padding: 0;
	color: white;
	cursor: pointer;

	svg {
		width: 100%;
		height: 100%;
	}

	path {
		fill: ${({ color }) => color};
	}
`;

const StyledHeadline = styled.h2`
	color: #fff;
	align-self: flex-start;
	margin-left: 3rem;
	padding: 2rem 1rem;
`;

export default function SidebarHeader({
	children,
	backgroundColor,
	title,
	isSmall,
	onBack,
	backButtonColor,
	className,
}) {
	return (
		<StyledHeader className={className} backgroundColor={backgroundColor} isSmall={isSmall}>
			<StyledButton onClick={onBack} color={backButtonColor}>
				<ArrowLeft />
			</StyledButton>
			{title && <StyledHeadline>{title}</StyledHeadline>}
			{children}
		</StyledHeader>
	);
}

SidebarHeader.propTypes = {
	children: PropTypes.node,
	backgroundColor: PropTypes.string.isRequired,
	title: PropTypes.string,
	isSmall: PropTypes.bool,
	onBack: PropTypes.func.isRequired,
	backButtonColor: PropTypes.string,
	className: PropTypes.string,
};

SidebarHeader.defaultProps = {
	backButtonColor: '#fff',
	isSmall: false,
	className: '',
};
