import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {breakpoints as media} from 'shared';

const StyledSubheadline = styled.h3`
	margin: 0;
	padding: 0 32px;
	font-size: 16px;

	${media.lg`
		font-size: 18px;
		padding: 0 48px;
	`}
`;

export default function Subheadline({ children }) {
	return <StyledSubheadline>{children}</StyledSubheadline>;
}

Subheadline.propTypes = {
	children: PropTypes.node.isRequired,
};
