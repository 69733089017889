import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors, fonts, boxshadow } from '../../utils/config';

const colorMapping = {
	blue: 'cyandark',
};

const StyledButton = styled.button`
	height: ${({ light }) => (light ? 'auto' : '55px')};
	border: 0;
	border-radius: 5px;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	padding: ${({ light }) => (light ? '8px 16px' : '8px 32px')};
	font-weight: ${({ light }) => (light ? fonts.weightdefault : fonts.weightbold)};
	font-size: 1rem;
	width: ${props => (props.stretch ? '100%' : 'auto')};
	max-width: 460px;

	&:hover {
		cursor: pointer;
	}
	&:disabled:hover {
		cursor: default;
	}

	${({ flat }) => (flat ? `box-shadow: ${boxshadow.default};` : '')}

	${props => {
		if (!props.color) return '';

		const color = colorMapping[props.color] || props.color;

		return css`
			background-color: ${colors[color]};
			color: ${colors.white};

			${props.disabled &&
				css`
					background-color: ${colors[color]}77;
				`}
		`;
	}}
`;

export default function Button({
	href,
	children,
	color,
	stretch,
	target,
	type,
	light,
	flat,
	disabled,
	onClick,
	className,
}) {
	return (
		<StyledButton
			href={href}
			color={color}
			stretch={stretch}
			target={target}
			type={type}
			onClick={onClick}
			light={light}
			flat={flat}
			disabled={disabled}
			className={className}
		>
			{children}
		</StyledButton>
	);
}

Button.propTypes = {
	href: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired])
		.isRequired,
	color: PropTypes.oneOf(['orange', 'blue']),
	stretch: PropTypes.bool,
	target: PropTypes.oneOf(['_self', 'blank', '_parent', '_top']),
	type: PropTypes.oneOf(['button', 'submit']),
	onClick: PropTypes.func,
	light: PropTypes.bool,
	flat: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
};

Button.defaultProps = {
	stretch: false,
	type: 'button',
	light: false,
	flat: false,
	className: '',
};
