// This must be the first line in src/index.js
// https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CookieBanner, GlobalStyle, GlobalMapStyles } from 'shared';
import { Route, Router } from 'react-router-dom';

import 'normalize.css';
import './globalStyles.css';

import RRP from './RRP';
import Page from './Page';
import * as serviceWorker from './serviceWorker';
import { ContextProvider } from './components/context';

import history from './utils/history';

import './i18n';

import patchVersion from './version';
import { mapRoot } from './utils';

const [majorVersion, minorVersion] = process.env.REACT_APP_VERSION.split('.');

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://3ff7ab205fee4ca0863f0e109ab0e3db@o15304.ingest.sentry.io/5619697',
		integrations: [new Integrations.BrowserTracing()],
		release: `vvs-radroutenplaner@${majorVersion}.${minorVersion}.${patchVersion}`,

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 0.001,
	});
}

window.additionalApiParams = '';

if ('URLSearchParams' in window) {
	const params = new URLSearchParams(window.location.search);
	if (params.has('apiDomain')) {
		window.additionalApiParams = `domain=${params.get('apiDomain')}`;
	}

	if (params.has('themeroutesDomain')) {
		window.additionalThemeroutesParams = `domain=${params.get('themeroutesDomain')}`;
	}
}

ReactDOM.render(
	<React.StrictMode>
		<GlobalStyle />
		<Router history={history}>
			<Route path={`${mapRoot}(@.+)?`}>
				<GlobalMapStyles />
				<ContextProvider>
					<RRP />
				</ContextProvider>
			</Route>
			<Route path="/" exact>
				<Page />
			</Route>
		</Router>
		<CookieBanner />
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
