import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const StyledDatePicker = styled.div`
	display: flex;
	grid-column-start: 2;
	z-index: 100;

	.react-datepicker-wrapper {
		width: 100%;
	}

	.react-datepicker__triangle {
		visibility: hidden;
	}

	.react-datepicker-popper {
		margin-top: 0;
	}

	.date-label {
		display: flex;
		align-items: center;
		margin-left: 12px;
		color: #fff;
	}

	.toggle-button {
		flex: 48px 48px auto;
		height: 48px;
		width: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		background-color: transparent;
		outline: none;
		padding: 0;
		margin: 0;
		cursor: pointer;

		svg {
			width: 18px;
			height: 18px;
		}
	}
`;

export default function DateTimePicker({ date, onChange }) {
	const { i18n, t } = useTranslation();
	// eslint-disable-next-line react/prop-types
	const DatePickerInput = forwardRef(({ onClick }, ref) => (
		<StyledDatePicker ref={ref} onClick={onClick}>
			<span className="date-label">
				{date.toLocaleDateString(i18n.language, {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
					hour: '2-digit',
					minute: '2-digit',
				})}
				{i18n.language === 'de' ? ' Uhr' : ''}
			</span>
			<button className="toggle-button" type="button" onClick={onClick}>
				<svg viewBox="0 0 16 16">
					<path d="M2 5 L8 11 L14 5" strokeWidth="2" stroke="#fff" fill="none" />
				</svg>
			</button>
		</StyledDatePicker>
	));

	return (
		<StyledDatePicker>
			<DatePicker
				locale={i18n.language}
				selected={date}
				onChange={onChange}
				minDate={date}
				showTimeSelect
				timeCaption={t('common.time')}
				timeIntervals={5}
				customInput={<DatePickerInput />}
				portalId="date-picker-portal"
			/>
		</StyledDatePicker>
	);
}

DateTimePicker.propTypes = {
	date: PropTypes.instanceOf(Date).isRequired,
	onChange: PropTypes.func.isRequired,
};
