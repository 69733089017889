import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import media from '../../utils/Breakpoints';
import { colors, boxshadow } from '../../utils/config';

import SearchResultListItem from './SearchResultListItem';
import HighLight from '../HighLight';
import { removeDuplicateStrings } from '../../utils';

const StyledSearchResultList = styled.ul`
	margin: 8px auto 0;
	overflow: auto;
	padding: 0;
	width: 100%;
	max-height: 330px;
	border-radius: 2px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-left: 1px solid ${colors.graylight};
	border-right: 1px solid ${colors.graylight};
	box-shadow: ${boxshadow.default};
	background-color: #fff;
	z-index: 40;
	position: absolute;

	${media.iphone`
		max-width: 358px;
	`}

	${({ positionrelative, searchResultsRelative }) =>
		positionrelative &&
		searchResultsRelative &&
		media.lg`
			position: relative;
			left: auto;
		`}

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		transform: scale(0.752);
		transform-origin: top left;
		top: 51px;
	}

	${props =>
		!props.slimLayout
			? css`
					width: 96%;
					width: calc(100% - 16px);

					${media.lg`
						margin-left: 16px;
						margin-top: 16px;
						width: 460px;
						max-width: none;
					`}

					${media.xl`
						margin-left: 32px;
						margin-top: 32px;
					`}
			  `
			: css`
					margin-top: 0;
					margin-bottom: 8px;
			  `}

	${({ positionrelative }) =>
		!positionrelative &&
		css`
			left: 8px;

			${media.lg`
				left: 0;
			`}
		`}

	${({ searchResultsRelative, slimLayout }) =>
		searchResultsRelative
			? css`
					top: 0;
			  `
			: css`
					top: ${slimLayout ? '38px' : '48px'};

					${media.lg`
						top: ${slimLayout ? '52px' : '68px'};
					`}
			  `}
`;

export default function SearchResultList({
	keyPrefix,
	items,
	active,
	searchRegex,
	onSelect,
	setActive,
	className,
	positionrelative,
	searchResultsRelative,
	slimLayout,
}) {
	return (
		<StyledSearchResultList
			className={className}
			slimLayout={slimLayout}
			positionrelative={positionrelative}
			searchResultsRelative={searchResultsRelative}
		>
			{items.map((item, i) => {
				let title = item.disassembledName || item.name;
				let subtitle = '';
				let icon = item.type;
				let keySuffix = item.id || item.shownLabel;

				if (item.type === 'category') {
					title = item.shownLabel;
					subtitle = 'Kategorie';
					icon = item.icon;
				} else if (item.type === 'tip') {
					title = item.title;
					subtitle = item.address.city || 'Ausflugstip';
					icon = 'tip';
					keySuffix = `${item.id}-${item.title.replace(/[^A-Za-z]/g, '_')}`;
				} else if (item.type === 'routeCache') {
					title = 'Route';
					subtitle = item.name;
				} else {
					subtitle = item?.parent?.name || '';
				}

				[title, ...subtitle] = removeDuplicateStrings(title, subtitle);

				return (
					<SearchResultListItem
						key={`${keyPrefix}-${keySuffix}`}
						title={<HighLight haystack={title} needle={searchRegex} />}
						subtitle={subtitle.join(', ')}
						type={item.type}
						icon={icon}
						active={active === i}
						onClick={() => onSelect(item)}
						onMouseEnter={() => setActive(i)}
						slimLayout={slimLayout}
					/>
				);
			})}
		</StyledSearchResultList>
	);
}

SearchResultList.propTypes = {
	keyPrefix: PropTypes.string,
	items: PropTypes.array,
	active: PropTypes.number,
	searchRegex: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
	setActive: PropTypes.func.isRequired,
	className: PropTypes.string,
	positionrelative: PropTypes.bool,
	searchResultsRelative: PropTypes.bool,
	slimLayout: PropTypes.bool,
};

SearchResultList.defaultProps = {
	keyPrefix: '',
	active: 0,
	className: '',
	positionrelative: false,
	searchResultsRelative: false,
	slimLayout: false,
};
