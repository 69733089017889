import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { registerLocale } from 'react-datepicker';

import dateDe from 'date-fns/locale/de';
import de from './assets/translations/de';
import en from './assets/translations/en';

registerLocale('de', dateDe);

i18n.use(initReactI18next).init({
	resources: {
		en: { translation: en },
		de: { translation: de },
	},

	lng: 'de',
	fallbackLng: 'de',

	interpolation: {
		escapeValue: false,
	},

	react: {
		wait: true,
	},

	debug: process.env.NODE_ENV === 'development',
});

export default i18n;
