import rbahn from '../../assets/marker/transportation/rbahn-o-echtzeit.svg';
import sbahn from '../../assets/marker/transportation/sbahn-o-echtzeit.svg';
import stadtbahn from '../../assets/marker/transportation/ubahn-o-echtzeit.svg';
import bus from '../../assets/marker/transportation/bus-o-echtzeit.svg';
import rbahnRT from '../../assets/marker/transportation/rbahn-echtzeit.svg';
import sbahnRT from '../../assets/marker/transportation/sbahn-echtzeit.svg';
import stadtbahnRT from '../../assets/marker/transportation/ubahn-echtzeit.svg';
import busRT from '../../assets/marker/transportation/bus-echtzeit.svg';

export const positionIcons = {
	rbahn,
	sbahn,
	stadtbahn,
	bus,
};

export const positionIconsRT = {
	rbahn: rbahnRT,
	sbahn: sbahnRT,
	stadtbahn: stadtbahnRT,
	bus: busRT,
};

export default {
	positionIcons,
	positionIconsRT,
};
