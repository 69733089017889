import { useEffect, useContext, useRef } from 'react';
import { fetchers } from 'shared';

import Context from '../components/context';

import updateStopMarkers from '../components/MapMarkers/updateStopMarkers';
import updatePositionMarkers from '../components/MapMarkers/updatePositionMarkers';
import updatePOIMarkers from '../components/MapMarkers/updatePOIMarkers';
import updateSharingMarkers from '../components/MapMarkers/updateSharingMarkers';

export default function useMapMarkers({ position }) {
	const {
		map,
		settings,
		addPoint,
		openPopup,
		setStopMarkers,
		setPositionMarkers,
		setPoiMarkers,
		setSharingMarkers,
	} = useContext(Context);

	const operators = useRef({});

	const updateMarkers = useRef(() => {});

	useEffect(() => {
		if (map !== null) {
			const newUpdateMarkers = () => {
				updateStopMarkers(map, settings.publicTransport.stops, addPoint, setStopMarkers);
				updatePositionMarkers(map, settings.publicTransport, setPositionMarkers);

				updatePOIMarkers(map, settings.poi, addPoint, setPoiMarkers);

				updateSharingMarkers(
					map,
					settings.sharing,
					operators.current,
					addPoint,
					openPopup,
					setSharingMarkers,
				);
			};

			newUpdateMarkers();
			updateMarkers.current = newUpdateMarkers;
		}
	}, [
		map,
		settings,
		addPoint,
		openPopup,
		setPoiMarkers,
		setPositionMarkers,
		setSharingMarkers,
		setStopMarkers,
	]);

	useEffect(() => {
		if (map !== null) {
			let promise;
			if (Object.keys(operators.current).length > 0) {
				promise = Promise.resolve(operators.current);
			} else {
				promise = fetchers.fetchSharingOperators();
			}
			promise.then(newOperators => {
				operators.current = newOperators;

				updateSharingMarkers(
					map,
					settings.sharing,
					operators.current,
					addPoint,
					openPopup,
					setSharingMarkers,
				);
			});
		}
	}, [map, settings.sharing, addPoint, openPopup, setSharingMarkers]);

	useEffect(() => {
		updateMarkers.current();
	}, [position]);

	return updateMarkers.current;
}
