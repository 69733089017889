import React, { useContext, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import VectorSource from 'ol/src/source/Vector';

import { colors, fetchers, SidebarHeader, TextBlockLoadingIndicator, OutlineButton } from 'shared';

import { Bike, Train } from '../../assets/icons';

import Context from '../context';

import RouteDetails from './route-details';
import Route from './Route';
import SearchHeader from './SearchHeader';
import {
	ResultContainer,
	ResultItem,
	ResultPreview,
	// ResultTitle,
	// ResultTime as ResultInfo,
	ErrorContainer,
} from './ResultStyles';

import {
	activateRouteType,
	leaveLayer,
	getOrCreateLinesLayer,
	drawRouteOnMap,
	routeTypes,
	updateUri,
	enterLayer,
	mapRoot,
} from '../../utils';
import useDrawLegsTransportationMarkers from '../../hooks/useDrawLegsTransportationMarkers';

const Content = styled.div`
	flex-grow: 1;
	padding: 1rem 0;
	position: relative;
`;

const Buttons = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	z-index: 1;

	button ~ button {
		margin-left: 0.6rem;
	}
`;

function requestRoutes(
	map,
	setRoutes,
	abortController,
	setAbortController,
	points,
	bikeSettings,
	publicTransportSettings,
	date,
	savedType,
	additionalOptions = {},
) {
	const newRoutes = [null, null, null, null];

	// Trigger display of loading indicators
	setRoutes(newRoutes);

	const lineLayer = getOrCreateLinesLayer(map, 'lines');
	lineLayer.setSource(new VectorSource());

	if (abortController) {
		abortController.abort();
	}

	let signal;
	if ('AbortController' in window) {
		const newAbortController = new window.AbortController();
		signal = newAbortController.signal;
		setAbortController(newAbortController);
	}

	//
	// These requests need to be run for each searched route.
	//
	const promises = routeTypes.map((routeType, i) =>
		fetchers
			.fetchBikeRoute(
				points,
				routeType,
				{
					...additionalOptions,
					routeType: bikeSettings.route,
					fitnessLevel: bikeSettings.fitness,
					maxTimeAccess: publicTransportSettings.maxTimeAccess,
					maxTimeExit: publicTransportSettings.maxTimeExit,
					transportations: {
						cityTrain: publicTransportSettings.cityTrain, // Straßenbahn / U-Bahn
						regionalTrain: publicTransportSettings.regionalTrain, // IRE, RE, RB
						cityRailway: publicTransportSettings.cityRailway, // S-Bahn
						cogRailway: publicTransportSettings.cogRailway, // Zahnradbahn
						bus: publicTransportSettings.bus, // Bus
					},
					date,
				},
				bikeSettings,
				{ signal },
			)
			.then(route => {
				newRoutes[i] = route;

				if (route === false) {
					newRoutes[i] = {
						success: false,
					};
				} else {
					newRoutes[i].success = true;
				}

				if (routeType === savedType) {
					newRoutes[i].saved = true;
				}

				newRoutes[i].routeType = routeType;
				setRoutes([...newRoutes]);

				return route;
			})
			.catch(e => {
				if (e.name !== 'AbortError') {
					newRoutes[i] = {
						routeType,
						success: false,
					};
					setRoutes([...newRoutes]);
				}
				return { success: false, routeType };
			}),
	);

	return Promise.all(promises);
}

export default function RoutePlanning({ onBack }) {
	const {
		points,
		setPoints,
		map,
		bikeSettings,
		publicTransportSettings,
		date,
		routes,
		activeRoute,
		setActiveRoute,
		hoverRoute,
		setHoverRoute,
		abortController,
		setAbortController,
		setRoutes,
		setCurrentRoute,
		debugEnabled,
		usePseudoRouting,
	} = useContext(Context);

	const [invalidPoints, setInvalidPoints] = useState(false);
	const [[lastPoints, lastSettings], setLast] = useState(['', '']);
	const allRequestsFinished = useRef(true);
	const setLegs = useDrawLegsTransportationMarkers();

	const { t } = useTranslation();

	// Handle request for routes if enough points are entered
	useEffect(() => {
		if (!map) {
			return;
		}
		const checkedPoints = points.filter(p => p);
		if (checkedPoints.length < 2) {
			if (abortController) {
				abortController.abort();
			}
			if (routes.length > 0) {
				setRoutes([]);
			}
			return;
		}
		let pointsStr = '';
		let previousPointId;
		let hasSamePoint = false;
		checkedPoints.forEach(point => {
			pointsStr += `${point.id}_` || '_';
			if (point.id === previousPointId) {
				hasSamePoint = true;
			}
			previousPointId = point.id;
		});
		const newSettings = JSON.stringify(bikeSettings) + JSON.stringify(publicTransportSettings);
		if (pointsStr === lastPoints && newSettings === lastSettings) {
			return;
		}
		setLast([pointsStr, newSettings]);
		setInvalidPoints(hasSamePoint);

		if (hasSamePoint) return;

		setHoverRoute(null);

		allRequestsFinished.current = false;
		requestRoutes(
			map,
			setRoutes,
			abortController,
			setAbortController,
			points,
			bikeSettings,
			publicTransportSettings,
			date,
			undefined,
			{ debugEnabled, usePseudoRouting },
		).then(newRoutes => {
			allRequestsFinished.current = true;
			newRoutes.some(route => {
				if (route.success) {
					const layer = getOrCreateLinesLayer(map, 'lines');
					setHoverRoute(route.routeType);
					enterLayer(layer, route.routeType);
				}
				return route.success;
			});
		});
	}, [
		points,
		map,
		setRoutes,
		hoverRoute,
		setHoverRoute,
		abortController,
		setAbortController,
		bikeSettings,
		publicTransportSettings,
		routes.length,
		date,
		debugEnabled,
		usePseudoRouting,
		lastPoints,
		lastSettings,
		setLast,
	]);

	// Handle drawing of routes on map
	useEffect(() => {
		if (!map) return;
		if (activeRoute) {
			routes.forEach(route => {
				if (route && route.routeType !== activeRoute) {
					// eslint-disable-next-line no-param-reassign
					route.drawn = false;
				}
			});
			return;
		}
		if (routes.every(route => !route || route.drawn)) return;
		if (
			window.location.pathname.match(
				new RegExp(`^(/${mapRoot}/routenplanung/details)(@.+)?$`),
			)
		) {
			updateUri(false, `${mapRoot}/routenplanung`);
		}

		const lineLayer = getOrCreateLinesLayer(map, 'lines');
		lineLayer.setSource(new VectorSource());

		routes.forEach(route => {
			if (route) {
				// eslint-disable-next-line no-param-reassign
				route.drawn = drawRouteOnMap(map, route, route.routeType);
			}
			return route;
		});

		if (hoverRoute) {
			enterLayer(lineLayer, hoverRoute);
		}
	}, [map, routes, activeRoute, hoverRoute, setRoutes]);

	// Handle setting and drawing legs of hovered routes
	useEffect(() => {
		routes.some(route => {
			if (route && route.success && route.routeType === hoverRoute) {
				setLegs(route.legs);
				return true;
			}
			return false;
		});
	}, [routes, hoverRoute, setLegs]);

	if (activeRoute !== null) {
		const index = routeTypes.indexOf(activeRoute);
		if (index > -1 && routes[index]) {
			return (
				<RouteDetails
					route={routes[index]}
					onBack={() => {
						const layer = getOrCreateLinesLayer(map, 'lines');
						setCurrentRoute(null);
						setActiveRoute(null);
						activateRouteType(layer, null);
						leaveLayer(layer, activeRoute);
						updateUri(false, `${mapRoot}/routenplanung`);
					}}
				/>
			);
		}
	}

	const newPoints = [...points];
	while (newPoints.length < 2) {
		newPoints.unshift(null);
	}

	let Routes = false;
	if (invalidPoints) {
		Routes = (
			<ErrorContainer>
				<h3>{t('routePlanning.invalidPoints')}</h3>
				<p>{t('routePlanning.twoSamePointsNotAllowed')}</p>
			</ErrorContainer>
		);
	} else if (routes !== false) {
		Routes = routes.map((route, index) => {
			if (route === null) {
				return (
					<ResultItem key={routeTypes[index]}>
						<TextBlockLoadingIndicator rows={2} />
					</ResultItem>
				);
			}

			if (['bikeTransport', 'bikeAndRide'].includes(route.routeType)) {
				if (route?.fare?.tickets?.length === 0) return false;
			}

			if (route.success === false) {
				return false;
			}

			return <Route key={route.routeType} route={route} index={index} />;
		});
	}

	return (
		<>
			<SidebarHeader
				backgroundColor={colors.orange}
				onBack={() => {
					setRoutes([]);
					setPoints([]);
					onBack();
				}}
			>
				<SearchHeader
					newPoints={newPoints}
					setRoutes={setRoutes}
					onRequestRoutes={() => {
						const checkedPoints = newPoints.filter(p => p);
						if (checkedPoints.length < 2) {
							return;
						}
						let pointsStr = '';
						checkedPoints.forEach(point => {
							pointsStr += point.id || '_';
						});
						setLast([pointsStr, lastSettings]);

						allRequestsFinished.current = false;
						requestRoutes(
							map,
							setRoutes,
							abortController,
							setAbortController,
							points,
							bikeSettings,
							publicTransportSettings,
							date,
							undefined,
							{ debugEnabled, usePseudoRouting },
						).then(() => {
							allRequestsFinished.current = true;
						});
					}}
				/>
			</SidebarHeader>
			<Content>
				<Buttons>
					<OutlineButton
						type="button"
						onClick={() => updateUri(false, `${mapRoot}/rad-einstellungen`)}
					>
						<Bike />
						{t('bikeSettings.title')}
					</OutlineButton>
					<OutlineButton
						type="button"
						onClick={() => updateUri(false, `${mapRoot}/oepnv-einstellungen`)}
					>
						<Train />
						{t('publicTransportSettings.title')}
					</OutlineButton>
				</Buttons>

				{routes === false || routes.filter(route => route !== false).length === 0 ? (
					<ResultPreview />
				) : (
					<ResultContainer>{Routes}</ResultContainer>
				)}
			</Content>
		</>
	);
}

RoutePlanning.propTypes = {
	onBack: PropTypes.func.isRequired,
};

RoutePlanning.defaultProps = {};
