import React, { useState, useCallback, useRef, createContext } from 'react';
import PropTypes from 'prop-types';

export const ScrollContext = createContext();

export default function ScrollContextProvider({ children }) {
	const ref = useRef();

	const [scrollPosition, setScrollPosition] = useState(0);

	const saveScrollPosition = useCallback(() => {
		setScrollPosition(ref.current.scrollTop);
	}, [ref, setScrollPosition]);

	const applyScrollPosition = useCallback(() => {
		if (ref && scrollPosition)
			setTimeout(() => {
				ref.current.scrollTo(0, scrollPosition);
			}, 200);
	}, [ref, scrollPosition]);

	return (
		<ScrollContext.Provider
			value={{
				ref,
				scrollPosition,
				saveScrollPosition,
				setScrollPosition,
				applyScrollPosition,
			}}
		>
			{children}
		</ScrollContext.Provider>
	);
}

ScrollContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
