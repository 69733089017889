import Feature from 'ol/src/Feature';
// import VectorSource from 'ol/src/source/Vector';
import LineString from 'ol/src/geom/LineString';
import { fromLonLat } from 'ol/src/proj';

import { getOrCreateLinesLayer } from './getOrCreateLinesLayer';

export default function drawRouteOnMap(map, route, routeType) {
	// Get Layer for lines on map. If it is missing, create it.
	// const lineLayer = getOrCreateLinesLayer(map, `lines-${routeType}`);
	const lineLayer = getOrCreateLinesLayer(map, 'lines');

	/**
	 * Remove old features / lines from the lineLayer
	 * => Needs to be done before calling this function because
	 *    multiple features could be added to the same layer.
	 */
	// lineLayer.setSource(new VectorSource());

	if (route === false || route.success === false) return true;
	if (
		['bikeTransport', 'bikeAndRide'].includes(routeType) &&
		route?.fare?.tickets?.length === 0
	) {
		return true;
	}

	const lines = [];
	let lastLegCoord;

	const filteredLegs = route.legs.filter(leg => leg.coords);

	if (filteredLegs.length !== route.legs.length) {
		return false;
	}

	route.legs.forEach(leg => {
		const linePoints = [];

		if (lastLegCoord) {
			const firstCoord = leg.coords[0];
			if (lastLegCoord[0] !== firstCoord[0] || lastLegCoord[1] !== firstCoord[1]) {
				const lonLat1 = fromLonLat([lastLegCoord[1], lastLegCoord[0]]);
				const lonLat2 = fromLonLat([firstCoord[1], firstCoord[0]]);
				const line = new Feature({
					geometry: new LineString([lonLat1, lonLat2]),
					productName: 'footpath',
					routeType,
				});
				lines.push(line);
			}
		}

		leg.coords.forEach(coord => {
			const lonLat = fromLonLat([coord[1], coord[0]]);
			linePoints.push(lonLat);

			lastLegCoord = coord;
		});
		let productName = leg.transportation.product.name;
		if (productName.match(/^SEV-Bus/)) {
			productName = 'SEV-Bus';
		}
		const line = new Feature({
			geometry: new LineString(linePoints),
			productName,
			routeType,
		});
		// line.renderIntent = line.productName;
		lines.push(line);
	});
	lineLayer.getSource().addFeatures(lines);

	return true;
}
