import styled from 'styled-components';

const StyledPopupOption = styled.li`
	border-top: 1px solid #999;

	> a,
	> button {
		background-color: rgba(0, 0, 0, 0);
		border-radius: 0;
		color: #000;
		display: block;
		padding: 12px;
		text-align: center;
		text-decoration: none;
		width: 100%;
	}
`;

export default StyledPopupOption;
