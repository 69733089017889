import styled, { keyframes } from 'styled-components';

import { IconProps } from '../Icons';

import media from '../../utils/Breakpoints';
import { colors, boxshadow } from '../../utils/config';

const pulsate = keyframes`
  0%,
  20% {
	opacity: 1;
	transform: scale(0) translateZ(0);
  }

  70% {
	opacity: .6;
  }

  100% {
	opacity: 0;
	transform: scale(1) translateZ(0);
  }
`;

export default styled.div`
	/* max-width: 502px; */
	height: 100%;
	overflow: hidden;

	.map {
		user-select: none;
	}

	.marker--transportation {
		display: flex;
		justify-content: center;

		img {
			margin-top: 12px;
			position: relative;
			max-width: 24px;
			max-height: 24px;
		}
	}
/*
	// Marker Tooltip
	.marker:hover .marker__tooltip,
	.marker.active .marker__tooltip,
	.marker[data-state='3'] .marker__tooltip {
		display: block;
	}
 */
	.marker:hover {
		z-index: 30 !important;
	}

	.marker__tooltip {
		/* display: none; */
		cursor: default;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform-origin: top center;
		transform: translate(-50%, -75px);
		min-width: 220px;
		padding: 8px 16px;
		text-align: center;
		border-radius: 3px;
		background-color: ${colors.white};
		border: 1px solid ${colors.graylight};
		box-shadow: ${boxshadow.tooltip};
		z-index: 2;

		&::before,
		&::after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			top: auto;
			bottom: 0;
			left: 50%;
			border: solid transparent;
			border-width: 16px;
			transform: translate(-50%, 50%);
		}

		&::before {
			bottom: -17px;
			border-color: rgba(0, 0, 0, 0);
			border-top-color: rgba(0, 0, 0, 0.1);
			filter: blur(2px);
		}

		&::after {
			bottom: -16px;
			border-color: rgba(255, 255, 255, 0);
			border-top-color: #fff;
			z-index: 3;
		}
	}

	.marker--position .marker__tooltip,
	.marker__tooltip--position {
		min-width: 200px;
		text-align: left;
		padding-left: 48px;
		background: 2px center no-repeat ${colors.white};
		top: 50%;
		left: 100%;
		bottom: auto;
		transform: translate(16px, -50%);

		&::before,
		&::after {
			top: 50%;
			transform: translate(-50%, -50%);
		}

		&::before {
			left: -16px;
			border-color: rgba(0, 0, 0, 0);
			border-right-color: rgba(0, 0, 0, 0.1);
		}

		&::after {
			left: -12px;
			border-color: rgba(255, 255, 255, 0);
			border-right-color: #fff;
		}
	}

	.marker--position[data-type='Bus'] .marker__tooltip {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.bus.path
		}' fill='%23C31924' fill-rule='nonzero'/%3E%3C/svg%3E");
	}

	.marker--position[data-type='Stadtbahn'] .marker__tooltip {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.ubahn.path
		}' fill='%230AA1E2' fill-rule='nonzero'/%3E%3C/svg%3E");
	}

	.marker--position[data-type='S-Bahn'] .marker__tooltip {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.sbahn.path
		}' fill='%2352B331' fill-rule='nonzero'/%3E%3C/svg%3E");
	}

	.marker--position[data-type='R-Bahn'] .marker__tooltip {
		background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${
			IconProps.rbahn.path
		}' fill='%238F908F' fill-rule='nonzero'/%3E%3C/svg%3E");
	}

	.marker__tooltip--sharing,
	.marker__tooltip--pr,
	.marker--sharing .marker__tooltip,
	.marker--pr .marker__tooltip,
	.marker--city .marker__tooltip {
		min-width: 280px;
		text-align: left;

		p {
			font-size: 15px;
			line-height: 19px;
			margin-bottom: 16px;
		}
	}

	.marker__tooltip--pr1,
	.marker__tooltip--pr2,
	.marker__tooltip--pr3,
	.marker--pr .marker__tooltip,
	.marker--city .marker__tooltip {
		svg {
			margin: 0 5px -2px 0;
			width: auto;
			height: 20px;
		}
		table,
		tr,
		th,
		td {
			border: 0;
			border-collapse: collapse;
			vertical-align: top;
		}

		table {
			margin-bottom: 1em;
		}

		th:not(:last-child),
		td:not(:last-child) {
			padding-right: 10px;
		}

		.tooltipcontent__title {
			font-size: 21px;
			line-height: 27px;
			margin-bottom: 16px;
		}

		a {
			color: ${colors.cyandark};
		}
	}
	.marker--pr-1 .marker__tooltip,
	.marker--pr-2 .marker__tooltip,
	.marker--pr-3 .marker__tooltip,
	.marker__tooltip--pr1,
	.marker__tooltip--pr2,
	.marker__tooltip--pr3 {
		svg {
			border-radius: 2px;
			padding: 2px;
		}
		path {
			fill: #fff;
		}
	}
	.marker--pr-1 .marker__tooltip svg,
	.marker__tooltip--pr1 svg {
		// Green
		background-color: #009110;
	}
	.marker--pr-2 .marker__tooltip svg,
	.marker__tooltip--pr2 svg {
		// Orange
		background-color: #FC631E;
	}
	.marker--pr-3 .marker__tooltip svg,
	.marker__tooltip--pr3 svg {
		// Red
		background-color: #F50000;
	}

	.marker--sharing .marker__tooltip,
	.marker__tooltip--sharing {
		.tooltipcontent__address {
			position: relative;
			padding-left: 24px;

			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 14px;
				height: 18px;
				background-image: url("data:image/svg+xml,%3Csvg width='14' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.95 12.477L7 17.389l-4.95-4.912c-2.733-2.764-2.733-7.252 0-10.015a6.95 6.95 0 019.9 0c2.733 2.763 2.733 7.251 0 10.015z' fill='%23BDC8D7' fill-rule='nonzero'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
			}

            span {
                display: block;
            }
		}
	}

	.marker--sharing[data-operator='RegioRad'] .marker__tooltip,
	.marker__tooltip--sharing-regiorad {
		min-width: 240px;
	}

	.marker--start .marker__tooltip,
	.marker__tooltip--start {
		transform: translate(-50%, -47px);
	}

	/* Marker Tooltip Inner Link */
	.marker__tooltip__link {
		display: block;
		color: ${colors.cyandark};

		&::before {
			content: '';
			display: inline-block;
			width: 18px;
			height: 18px;
			margin-right: 8px;
			background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke-width='2' stroke='%2398AAB3' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M8.467 17H17V1H1v8.533'/%3E%3Cpath d='M10 13.25V8H4.75M10 8l-7 7'/%3E%3C/g%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-size: 70%;
			background-position: center bottom;
		}
	}

	/* Marker tooltip delay color */
	.orange,
	.marker__delay {
		color: ${colors.orange};

		&[data-delay="0"] {
			display: none;
		}
	}

	.marker__delay {
		background-color: #fff;
		border: 1px solid ${colors.orange};
		line-height: 30px;
		margin-left: 2px;
		position: absolute;
		top: 50%;
		left: 100%;
		text-align: center;
		width: 30px;
		height: 30px;
		transform: translateY(-50%);
	}

	.pulsate {
		transform-origin: center center;
		animation: ${pulsate} 2s infinite ease-out;
	}

	.footer-link {
		color: #000;
	}
	.footer-break {
		display: none;

		@media (min-width: 420px) and (max-width: 485px) {
			display: block;
		}

		${media.sm`
			display: none;
		`}
	}
`;
