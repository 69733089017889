const LOCAL_STORAGE_KEY = 'SEARCH_CACHE_V2';
const MAX_CACHE_SIZE = 20;

const cache = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};

export function addItem(item) {
	cache[item.id] = {
		datetime: Date.now(),
		item,
	};

	Object.keys(cache)
		.sort((a, b) => cache[b].datetime - cache[a].datetime)
		.splice(MAX_CACHE_SIZE)
		.forEach((id) => {
			// Remove oldest entries
			delete cache[id];
		});

	localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(cache));
}

export function getItems(search) {
	return Object.keys(cache)
		.sort((a, b) => cache[b].datetime - cache[a].datetime)
		.map((id) => cache[id].item)
		.filter((item) => (item.name || item.title).toLowerCase().includes(search));
}

export default {
	addItem,
	getItems,
};
