import React, { useEffect, useContext, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Point from 'ol/src/geom/Point';
import { fromLonLat } from 'ol/src/proj';

import { createMarker, distanceToString } from '../utils';
import Context from './context';

const Y_AXIS_WIDTH = 60;

/**
 * generates ticks for y axis
 *
 * @param {number} max *
 * @return {number[]}
 */
function generateYTicks(max) {
	const maxElev = Math.round(max / 10) * 10 + 50;

	const length = Math.round(Math.log(maxElev)) + 1;
	const stepElev = Math.round(maxElev / length / 10) * 10;

	return Array.from({ length }, (_, index) => index * stepElev);
}

/**
 * generates ticks for x axis
 *
 * @param {number} max *
 * @return {number[]}
 */
function generateXTicks(max) {
	return [...Array.from({ length: Math.round(max / 500) }, (_, i) => i * 500), max];
}

const StyledElevationProfile = styled.div`
	-ms-grid-column: 2;
	-ms-grid-column-span: 1;
	grid-column: 2 / 3;
	margin-bottom: 1.5rem;
	overflow: auto;
	overflow-y: hidden;
	width: 100%;
	height: 260px;

	display: grid;
	grid-template-columns: min-content 1fr;
`;

const StyledSticky = styled.div`
	position: sticky;
	left: 0;
	background-color: #fff;
	z-index: 1;
	height: calc(100% - 24px);
	overflow: hidden;
`;

const StyledOffset = styled.div`
	margin-left: -60px;
`;

function useMarker() {
	const { map, bikeMarker, setBikeMarker } = useContext(Context);
	const [coord, setCoord] = useState(null);

	useEffect(() => {
		if (!map) return () => {};
		const newMarker = createMarker({ coords: [0, 0] }, { type: 'bike' });
		setBikeMarker(newMarker);
		return () => setBikeMarker(null);
	}, [map, setBikeMarker]);

	useEffect(() => {
		if (!bikeMarker) return;
		if (coord && coord.lon && coord.lat) {
			try {
				bikeMarker.set('type', 'bike');
				bikeMarker.setGeometry(new Point(fromLonLat([coord.lon, coord.lat])));
			} catch (e) {
				console.warn('CommonElevationProfile', e);
			}
		} else {
			bikeMarker.set('type', 'hidden');
		}
	}, [bikeMarker, map, coord]);

	return setCoord;
}

/**
 *
 * @param {object} props
 * @param {object[]} props.data the data to show
 * @param {number} props.width the width of the chart
 * @param {number} props.height the height of the chart
 *
 * @return {function}
 */
export default function CommonElevationProfile({
	data,
	width,
	height,
	yAxisDataLabel,
	xAxisDataLabel,
}) {
	const setCoord = useMarker();

	const yMax = Math.max(...data.map(v => v[yAxisDataLabel])) * 1.33;
	const xLength = data[data.length - 1][xAxisDataLabel];

	const usedWidth = width === 0 ? Math.max(500, Math.round(height * xLength * 0.001)) : width;

	const yTicks = generateYTicks(yMax);
	const xTicks = generateXTicks(xLength);

	return (
		<StyledElevationProfile>
			<StyledSticky>
				<AreaChart
					width={Y_AXIS_WIDTH}
					height={height}
					data={data}
					margin={{
						top: 10,
						right: 0,
						left: 0,
						bottom: 30,
					}}
				>
					<YAxis
						dataKey={yAxisDataLabel}
						tickFormatter={distanceToString}
						ticks={yTicks}
						type="number"
						domain={[0, 'dataMax']}
					/>
				</AreaChart>
			</StyledSticky>

			<StyledOffset>
				<AreaChart
					width={usedWidth - Y_AXIS_WIDTH}
					height={height}
					data={data}
					margin={{
						top: 10,
						right: 0,
						left: 0,
						bottom: 0,
					}}
					onMouseMove={e => setCoord(e?.activePayload?.[0]?.payload)}
					onMouseLeave={() => setCoord(null)}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<YAxis
						dataKey={yAxisDataLabel}
						tickFormatter={distanceToString}
						ticks={yTicks}
						type="number"
						domain={[0, 'dataMax']}
					/>

					<XAxis
						dataKey={xAxisDataLabel}
						type="number"
						ticks={xTicks}
						tickFormatter={distanceToString}
						domain={['dataMin', 'dataMax']}
					/>
					<Tooltip
						labelFormatter={distance => `Strecke: ${distanceToString(distance)}`}
						formatter={value => [distanceToString(value), 'Höhe']}
						separator=": "
					/>
					<Area
						type="monotone"
						dataKey={yAxisDataLabel}
						stroke="#158e8e"
						fill="#158e8e"
					/>
				</AreaChart>
			</StyledOffset>
		</StyledElevationProfile>
	);
}

CommonElevationProfile.propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
	yAxisDataLabel: PropTypes.string.isRequired,
	xAxisDataLabel: PropTypes.string.isRequired,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			lat: PropTypes.number.isRequired,
			lon: PropTypes.number.isRequired,
			z: PropTypes.number.isRequired,
			distance: PropTypes.number.isRequired,
			distanceSum: PropTypes.number.isRequired,
		}),
	),
};

CommonElevationProfile.defaultProps = {
	data: [],
	width: 0,
	height: 240,
};
