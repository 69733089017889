import styled, { keyframes } from 'styled-components';

const gradientMove = keyframes`
  0% {
    transform: translateX(-100%);
  }

  75% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
`;

const Gradient = styled.div`
	// scss
	animation: ${gradientMove} 1.5s ease-in-out -0.2s infinite;
	background: linear-gradient(
		115deg,
		rgba(255, 255, 255, 0) 25%,
		rgba(255, 255, 255, 0.5) 50%,
		rgba(255, 255, 255, 0) 75%
	);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 200%;
	z-index: 1;
`;

export default Gradient;
