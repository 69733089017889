import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Switch, colors, SidebarHeader, breakpoints as media } from 'shared';
import Context from '../context';

import { Bus, SBahn, Stadtbahn, RBahn, Zacke } from '../../assets/icons';

const Content = styled.div`
	padding: 1rem;
`;

const SubHeadline = styled.h3`
	margin: 0;
	font-size: 16px;
	margin-top: 2rem;
	margin-bottom: 1rem;

	${media.lg`
		font-size: 18px;
	`}
`;

const Row = styled.div`
	display: flex;
	:first-child {
		flex: 1 1 auto;
	}
	:last-child {
		flex: 0 0 auto;
	}
`;

const Text = styled.label`
	color: ${colors.black};
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	padding: 12px 0;
	height: 100%;

	> input {
		border: 0;
		width: 30px;
	}

	&:hover {
		cursor: pointer;
	}
`;

const InputWrapper = styled.label`
	color: ${colors.black};
	display: flex;
	flex: 0 0 auto;
	align-items: center;
	opacity: 0.5;
	padding: 12px 0;
	position: relative;
	height: 100%;
`;

const Input = styled.input`
	border: 0;
	line-height: 1.4;
	text-align: right;
	width: 6rem;

	${({ hasSuffix }) =>
		hasSuffix &&
		css`
			padding-right: 2.5rem;
		`}
`;

const Suffix = styled.div`
	position: absolute;
	right: 0;
	width: 2.5rem;
`;

export function NumberInput({ id, defaultValue = '', suffix = '', step = 1, max = 999, onChange }) {
	return (
		<InputWrapper htmlFor={id}>
			<Input
				id={id}
				type="number"
				defaultValue={defaultValue}
				min={0}
				max={max}
				step={step}
				hasSuffix={suffix !== ''}
				onChange={e => {
					onChange(e.target.value);
				}}
			/>
			<Suffix>{suffix}</Suffix>
		</InputWrapper>
	);
}

NumberInput.propTypes = {
	id: PropTypes.string.isRequired,
	defaultValue: PropTypes.string,
	suffix: PropTypes.string,
	step: PropTypes.number,
	max: PropTypes.number,
	onChange: PropTypes.func.isRequired,
};

function PublicTransportSettings({ onBack }) {
	const { publicTransportSettings, setPublicTransportSettings } = useContext(Context);
	const { t } = useTranslation();

	return (
		<>
			<SidebarHeader
				backgroundColor={colors.orange}
				title={t('publicTransportSettings.title')}
				onBack={onBack}
				isSmall
			/>
			<Content>
				<SubHeadline>{t('publicTransportSettings.transport')}</SubHeadline>
				<Switch
					smallIcon
					id="cityTrain"
					value="cityTrain"
					icon={SBahn}
					iconFill="#339A31"
					label={t('publicTransportSettings.cityTrain')}
					defaultChecked={publicTransportSettings.cityTrain}
					onChange={value => {
						setPublicTransportSettings({ setting: 'cityTrain', value });
					}}
				/>
				<Switch
					smallIcon
					id="regionalTrain"
					icon={RBahn}
					iconFill="#868789"
					value="regionalTrain"
					label={t('publicTransportSettings.regionalTrain')}
					defaultChecked={publicTransportSettings.regionalTrain}
					onChange={value =>
						setPublicTransportSettings({ setting: 'regionalTrain', value })
					}
				/>
				<Switch
					smallIcon
					id="cityRailway"
					icon={Stadtbahn}
					value="cityRailway"
					label={t('publicTransportSettings.cityRailway')}
					defaultChecked={publicTransportSettings.cityRailway}
					onChange={value =>
						setPublicTransportSettings({ setting: 'cityRailway', value })
					}
				/>
				<Switch
					smallIcon
					id="cogRailway"
					value="cogRailway"
					icon={Zacke}
					label={t('publicTransportSettings.cogRailway')}
					defaultChecked={publicTransportSettings.cogRailway}
					onChange={value => setPublicTransportSettings({ setting: 'cogRailway', value })}
				/>
				<Switch
					smallIcon
					id="bus"
					value="bus"
					icon={Bus}
					iconFill="#339A31"
					label={t('publicTransportSettings.bus')}
					defaultChecked={publicTransportSettings.bus}
					onChange={value => {
						setPublicTransportSettings({ setting: 'bus', value });
					}}
				/>

				<SubHeadline>{t('publicTransportSettings.options')}</SubHeadline>
				<Switch
					id="shortedPossible"
					value="shortedPossible"
					label={t('publicTransportSettings.shortedPossible')}
					defaultChecked={publicTransportSettings.shortedPossible}
					onChange={value =>
						setPublicTransportSettings({ setting: 'shortedPossible', value })
					}
				/>

				<Row>
					<Text htmlFor="publicTransport-maxMinStart">
						{t('publicTransportSettings.maxTimeAccess')}
					</Text>
					<NumberInput
						id="publicTransport-maxMinStart"
						defaultValue={publicTransportSettings.maxTimeAccess}
						suffix="min"
						step={5}
						max={60}
						onChange={value =>
							setPublicTransportSettings({ setting: 'maxTimeAccess', value })
						}
					/>
				</Row>
				<Row>
					<Text htmlFor="publicTransport-maxMinEnd">
						{t('publicTransportSettings.maxTimeExit')}
					</Text>
					<NumberInput
						id="publicTransport-maxMinEnd"
						defaultValue={publicTransportSettings.maxTimeExit}
						suffix="min"
						step={5}
						max={60}
						onChange={value =>
							setPublicTransportSettings({ setting: 'maxTimeExit', value })
						}
					/>
				</Row>
			</Content>
		</>
	);
}

PublicTransportSettings.propTypes = {
	onBack: PropTypes.func.isRequired,
};

export default PublicTransportSettings;
