export default function getCoordOfPoint(point) {
	let coords = [];
	if (point.type === 'tip') {
		coords = [parseFloat(point.address.coords[0]), parseFloat(point.address.coords[1])];
	}
	if (point.coord) {
		if (Array.isArray(point.coord)) {
			coords = point.coord;
		} else {
			coords = point.coord.split(',');
		}
	} else if (point.coords) {
		if (Array.isArray(point.coords)) {
			coords = point.coords;
		} else {
			coords = point.coords.split(',');
		}
	}
	if (!Array.isArray(coords) || coords.length !== 2) {
		console.error('point has no coordinates', point);
		return [];
	}
	coords.forEach((coord, index) => {
		coords[index] = parseFloat(coord);
	});
	let roundedLongitude = Math.round(coords[0]);
	//check if lang and lat are swapped
	if (roundedLongitude < 30) {
		let lang = coords[1];
		let lat = coords[0];
		coords[0] = lang;
		coords[1] = lat;
	}
	// return point.coord || point.coords;
	return coords;
}
