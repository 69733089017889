import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from 'shared';

const StyledContainerImg = styled.div`
	width: 100%;
	align-self: center;

	padding-bottom: 66%;
	background-image: url(${({ src }) => src});
	background-size: cover;
	background-position: center;

	@media (min-width: 640px) {
		height: 100%;
		padding-bottom: initial;
	}
`;

export const StyledContainerText = styled.div`
	display: grid;
	grid-row-gap: 24px;
	padding: 2rem 0;
	width: 100%;
	align-content: center;

	@media (min-width: 640px) {
		height: 100%;
		grid-row-gap: 28px;
	}
`;

export const StyledContainer = styled.div`
	grid-column: 1 / -1;
	display: grid;

	padding: 1rem;

	* {
		white-space: pre-line;
	}

	h1 {
		font-size: 32px;
		line-height: 40px;
	}

	h2 {
		font-size: 24px;
		line-height: 32px;
	}

	p {
		line-height: 24px;
		font-size: 16px;
	}

	@media (min-width: 640px) {
		h1 {
			font-size: 40px;
			line-height: 51px;
		}

		h2 {
			font-size: 28px;
			line-height: 32px;
		}

		p {
			line-height: 24px;
			font-size: 18px;
		}
	}

	@media (min-width: 1024px) {
		padding: 0;
	}

	${StyledContainerImg} {
		grid-row: 1 / 2;
	}

	${StyledContainerText} {
		grid-row: 3 / 4;
	}

	@media (min-width: 640px) {
		${StyledContainerImg},
		${StyledContainerText} {
			grid-row: 1 / 2;
		}
	}
`;

const StyledContainerCenter = styled(StyledContainer)`
	${StyledContainerText} {
		max-width: 100%;
		grid-row-gap: 28px;
	}

	@media (min-width: 640px) {
		grid-column: 3 / -3;
		grid-row-gap: 46px;
		padding: 0;
	}

	@media (min-width: 1024px) {
		grid-column: 5 / -5;
	}
`;

const StyledContainerLeft = styled(StyledContainer)`
	flex-direction: column;

	@media (min-width: 640px) {
		grid-template-columns: repeat(26, 1fr);
		flex-direction: row;

		grid-column: 1 / -1;

		${StyledContainerImg} {
			grid-column: 1 / 14;
		}

		${StyledContainerText} {
			grid-column: 16 / -1;
		}
	}

	@media (min-width: 1024px) {
		flex-direction: row;
		grid-column: 2 / -3;
	}
`;

export const StyledContainerRight = styled(StyledContainer)`
	flex-direction: column-reverse;

	@media (min-width: 640px) {
		grid-template-columns: repeat(26, 1fr);
		grid-column: 1 / -1;

		${StyledContainerImg} {
			grid-column: 14 / -1;
		}

		${StyledContainerText} {
			grid-column: 1 / 11;
		}
	}

	@media (min-width: 1024px) {
		flex-direction: row;
		grid-column: 3 / -2;
	}
`;

const StyledButton = styled(Button)`
	border-radius: 0;
`;

export function CenterPageSection({ headline, text, button, link, children }) {
	return (
		<StyledContainerCenter>
			<StyledContainerText>
				<h1>{headline}</h1>
				<p>{text}</p>
				<a href={link}>
					<StyledButton color="blue">{button}</StyledButton>
				</a>
				{children}
			</StyledContainerText>
		</StyledContainerCenter>
	);
}

CenterPageSection.propTypes = {
	headline: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	button: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export function LeftPageSection({ headline, text, button, link, img }) {
	return (
		<StyledContainerLeft>
			<StyledContainerImg src={img} />
			<StyledContainerText>
				<h2>{headline}</h2>
				<p>{text}</p>
				<a href={link}>
					<StyledButton color="blue">{button}</StyledButton>
				</a>
			</StyledContainerText>
		</StyledContainerLeft>
	);
}

LeftPageSection.propTypes = {
	headline: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	button: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	img: PropTypes.string.isRequired,
};

export function RightPageSection({ headline, text, button, link, img }) {
	return (
		<StyledContainerRight>
			<StyledContainerText>
				<h2>{headline}</h2>
				<p>{text}</p>
				<a href={link}>
					<StyledButton color="blue">{button}</StyledButton>
				</a>
			</StyledContainerText>
			<StyledContainerImg src={img} />
		</StyledContainerRight>
	);
}

RightPageSection.propTypes = {
	headline: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	button: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	img: PropTypes.string.isRequired,
};
