import getSearchLabelFromResult from '../../utils/getSearchLabelFromResult';

export const ROUTE_CACHE_KEY = 'SEARCH_ROUTE_CACHE';
let routeCache = JSON.parse(sessionStorage.getItem(ROUTE_CACHE_KEY));

export function setRoute(points) {
	const filtered = points.filter(Boolean);

	if (filtered.length < 2) return;

	const route = filtered.map(point => getSearchLabelFromResult(point)).join(' - ');

	routeCache = {
		id: ROUTE_CACHE_KEY,
		name: `${route}`,
		points: filtered,
		type: 'routeCache',
	};

	sessionStorage.setItem(ROUTE_CACHE_KEY, JSON.stringify(routeCache));
}

export function getRoute() {
	return routeCache;
}

export default {
	ROUTE_CACHE_KEY,
	routeCache,
	getRoute,
	setRoute,
};
