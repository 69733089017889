import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { MapTypes, Sidebar, breakpoints as media } from 'shared';

import Context from '../context';

import Header from './Header';
import List from './List';
import SubHeadline from './Subheadline';

const Content = styled.div`
	height: 95%;
	height: calc(100% - 64px);
	overflow-y: auto;
	padding: 32px 0 0 0;

	${media.lg`
		height: calc(100% - 96px);
	`}
`;

function SettingsSidebar({ onUpdate }) {
	const {
		toggleSettingsSidebar,
		isSettingsSidebarOpen,
		setSettings,
		settings,
		mapType,
		setMapType,
	} = useContext(Context);
	const { t } = useTranslation();

	return (
		<Sidebar open={isSettingsSidebarOpen} right>
			<Header onClose={() => toggleSettingsSidebar(false)}>
				{t('settings.sidebar.title')}
			</Header>
			<Content>
				<SubHeadline>{t('settings.mapType.title')}</SubHeadline>
				<MapTypes activeItem={mapType} onChange={newMapType => setMapType(newMapType)} />

				<SubHeadline>{t('settings.publicTransport.title')}</SubHeadline>
				<List
					itemCategory="publicTransport"
					items={settings.publicTransport}
					onUpdate={value => {
						setSettings({ setting: 'publicTransport', value });
						onUpdate();
					}}
				/>

				<SubHeadline>{t('settings.sharing.title')}</SubHeadline>
				<List
					itemCategory="sharing"
					items={settings.sharing}
					onUpdate={value => {
						setSettings({ setting: 'sharing', value });
						onUpdate();
					}}
				/>

				<SubHeadline>{t('settings.poi.title')}</SubHeadline>
				<List
					itemCategory="poi"
					items={settings.poi}
					onUpdate={value => {
						setSettings({ setting: 'poi', value });
						onUpdate();
					}}
				/>
			</Content>
		</Sidebar>
	);
}

SettingsSidebar.propTypes = {
	onUpdate: PropTypes.func,
};

SettingsSidebar.defaultProps = {
	onUpdate: () => {},
};

export default SettingsSidebar;
