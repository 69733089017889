import Feature from 'ol/src/Feature';
import LineString from 'ol/src/geom/LineString';
import { fromLonLat } from 'ol/src/proj';
import VectorSource from 'ol/src/source/Vector';

import { getOrCreateLinesLayer } from './getOrCreateLinesLayer';

export default function drawBikePathOnMap(map, coords, routeType = 'themenroute') {
	// Get Layer for lines on map. If it is missing, create it.
	const lineLayer = getOrCreateLinesLayer(map, 'lines');

	const linePoints = [];
	coords.forEach(coord => {
		const lonLat = fromLonLat([coord[1], coord[0]]);
		linePoints.push(lonLat);
	});

	const line = new Feature({
		geometry: new LineString(linePoints),
		productName: 'Fahrrad',
		routeType,
		alwaysActive: true,
	});

	/**
	 * Override / Remove old features / lines from the lineLayer
	 * => This will remove all routes already added to the map.
	 *    Maybe they need to be re-added again later.
	 */
	lineLayer.setSource(
		new VectorSource({
			features: [line],
		}),
	);
}
