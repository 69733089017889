import { createGlobalStyle } from 'styled-components';

import { colors, fonts } from '../utils/config';

export default createGlobalStyle`
	@font-face {
		font-family: 'Lineto circular book';
		src: url('fonts/5c110535ea9a852270104ace_lineto-circular-book.woff2') format('woff2'), url('fonts/5c11053597d06e79c58f26db_lineto-circular-book.eot') format('embedded-opentype'), url('fonts/5c11053527f39a5069ae8daf_lineto-circular-book.woff') format('woff');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Lineto circular bold';
		src: url('fonts/5c11053527f39a601bae8dae_lineto-circular-bold.woff2') format('woff2'), url('fonts/5c11053527f39a0b2bae8dad_lineto-circular-bold.eot') format('embedded-opentype'), url('fonts/5c1105351e1ac78918c7ca30_lineto-circular-bold.woff') format('woff');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Lineto circular black';
		src: url('fonts/5c1105341e1ac70ad8c7ca2c_lineto-circular-black.woff2') format('woff2'), url('fonts/5c11053497d06e5c878f26d9_lineto-circular-black.eot') format('embedded-opentype'), url('fonts/5c11053597d06e4c798f26dc_lineto-circular-black.woff') format('woff');
		font-weight: 400;
		font-style: normal;
	}

	body {
		font-family: ${fonts.default};
		font-weight: ${fonts.weightdefault};
		color: ${colors.black};

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		-webkit-tap-highlight-color: transparent;
		-webkit-overflow-scrolling: touch;
	}

	*,
	:after,
	:before {
		box-sizing: border-box;
	}

	p {
		margin: 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: ${fonts.bold};
		font-weight: normal;
		margin: 0;
		padding: 0;
	}

	button,
	input,
	textarea,
	select {
		margin: 0;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
		outline: none;
	}

	strong {
		font-family: ${fonts.bold};
		font-weight: normal;
	}
`;

export const GlobalMapStyles = createGlobalStyle`
	html.prevent-scroll {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;

		body,
		#root,
		.App {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;
		}

		body {
			margin: 0;
			overflow-y: auto;
			overflow-x: hidden;
		}

		.App {
			overflow: auto;
		}
	}

	* {
		box-sizing: border-box;
	}

	.hidden {
		display: none;
	}

	.lines-layer {
		pointer-events: none;

		polyline {
			pointer-events: all;
		}
	}

	#date-picker-portal .react-datepicker__tab-loop {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 100;
	}
`;
