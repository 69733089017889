import React, { Children } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../utils/Breakpoints';

const StyledMenu = styled.div`
	padding: 8px;
	position: absolute;
	left: 0;
	bottom: 24px;
	display: flex;
	align-items: flex-start;
	width: auto;
	height: 166px;
	max-width: 100%;
	overflow-y: hidden;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;

	visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

	${media.sm`
		bottom: 8px;
	`}

	${media.md`
		bottom: 0px;
	`}

	${media.lg`
		left: 8px;
		height: 224px;
		overflow: hidden;
	`}

	${media.xl`
		left: 24px;
		bottom: 24px;
	`}

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		transform: scale(.752);
		transform-origin: bottom left;
	}

	&::after {
		display: block;
		content: '';
		flex-shrink: 0;
		width: 8px;
		height: 100%;
	}
`;

export default function Menu({ isVisible, children }) {
	return <StyledMenu isVisible={isVisible}>{Children.toArray(children)}</StyledMenu>;
}

Menu.propTypes = {
	isVisible: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

export { default as MenuOpener } from './menuopener';
export { default as MenuTile } from './menutile';
