import React from 'react';
import checkObjectTree from '../../utils/checkObjectTree';

export const sharingMappings = {
	stella: 'Stella',
	STADTMOBIL: 'Stadtmobil',
	FLINKSTER: 'Flinkster',
	car2go: 'ShareNow',
	CALL_A_BIKE: 'RegioRad',
	CallABike: 'RegioRad',
};

const carClasses = {
	SMALLFAMILYCAR: 'Kleines Familienauto',
	CITYCAR: 'Stadtauto',
	MULTIPURPOSECARS: 'Mehrzweckfahrzeug',
	LARGEFAMILYCAR: 'Großes Familienauto',
	SUPERMINI: 'Kleinwagen',
	LUXURYCAR: 'Luxus',
	MOTORCYCLE: 'Motorrad / Motorroller',
	SCOOTER: 'Motorroller',
};

function buildAddress(addressString) {
	if (!addressString) return [''];
	const addressData = addressString.split(', ').filter((data) => data.trim());
	return addressData;
}

function getUsedClasses(vehicles) {
	const usedClasses = [];
	if (Array.isArray(vehicles)) {
		vehicles.forEach((vehicle) => {
			if (usedClasses.indexOf(carClasses[vehicle.carClass]) < 0) {
				usedClasses.push(carClasses[vehicle.carClass]);
			}
		});
	} else {
		usedClasses.push(carClasses[vehicles.carClass]);
	}
	return usedClasses;
}

export function buildStadtmobilFlinksterItem(item, operators, linkname = 'Buchen') {
	let usedClasses = [];
	let freeVehicles = 0;
	let firstVehicleDetails = false;
	const address = buildAddress(item.coordInfoDetails.station.addr);

	if (checkObjectTree(item, ['coordInfoDetails', 'station', 'vehDetails'])) {
		usedClasses = getUsedClasses(item.coordInfoDetails.station.vehDetails);

		if (Array.isArray(item.coordInfoDetails.station.vehDetails)) {
			freeVehicles = item.coordInfoDetails.station.vehDetails.length;
			[firstVehicleDetails] = item.coordInfoDetails.station.vehDetails;
		} else {
			freeVehicles = 1;
			firstVehicleDetails = item.coordInfoDetails.station.vehDetails;
		}
	}

	let vehicletypes;
	if (usedClasses.length === 0) {
		vehicletypes = '-';
	} else {
		vehicletypes = usedClasses.map((clazz, i) => {
			if (i === usedClasses.length - 1) {
				return <span key={`clazz-${clazz}`}>{clazz}</span>;
			}
			return (
				<span key={`clazz-${clazz}-br`}>
					{clazz}
					<br />
				</span>
			);
		});
	}

	const type = sharingMappings[item.type] || item.type;
	return {
		id: item.id,
		title: item.desc,
		subtitle: `${freeVehicles}`,
		address,
		vehicletypesLength: usedClasses.length,
		vehicletypes,
		iosDeeplink: firstVehicleDetails && firstVehicleDetails.dLIOS,
		iosStore: operators[type][0].ios,
		androidDeeplink: firstVehicleDetails && firstVehicleDetails.dLAndroid,
		androidStore: operators[type][0].android,
		windowsDeeplink: firstVehicleDetails && firstVehicleDetails.dLWindows,
		windowsStore: operators[type][0].windows,
		uri: operators[type][0].URL,
		linkname: linkname,
		type: 'sharing',
		icon: type.toLowerCase(),
	};
}

export function buildStellaItem(item, operator, linkname = 'Buchen') {
	const address = buildAddress(item.coordInfoDetails.addr);

	let carType = carClasses[item.coordInfoDetails.vehType];
	if (item.coordInfoDetails.vehType === 'MOTORCYCLE') {
		carType = 'E-Roller';
	}

	const vehicletypes = { carType };
	const type = sharingMappings[item.type] || item.type;

	return {
		id: item.id,
		title: item.desc,
		subtitle: `Ladezustand ${item.coordInfoDetails.fuelLevel} %`,
		address,
		vehicletypes,
		// iosDeeplink: sth.dLIOS,
		iosStore: operator.ios,
		// androidDeeplink: sth.dLAndroid,
		androidStore: operator.android,
		// windowsDeeplink: stg.dLWindows,
		windowsStore: operator.windows,
		uri: operator.URL,
		linkname: linkname,
		type: 'sharing',
		icon: type.toLowerCase(),
	};
}

export function buildCar2GoItem(item, operator, linkname = 'Buchen') {
	const address = buildAddress(item.coordInfoDetails.addr);

	const vehicletypes = carClasses[item.coordInfoDetails.carClass];

	return {
		id: item.id,
		title: item.desc,
		subtitle: `Ladezustand ${item.coordInfoDetails.fuelLevel} %`,
		address,
		vehicletypes,
		iosDeeplink: item.coordInfoDetails.dLIOS,
		iosStore: operator.ios,
		androidDeeplink: item.coordInfoDetails.dLAndroid,
		androidStore: operator.android,
		windowsDeeplink: item.coordInfoDetails.dLWindows || item.coordInfoDetails.dLAndroid,
		windowsStore: operator.windows,
		uri: operator.URL,
		linkname: linkname,
		type: 'sharing',
		icon: 'car2go',
	};
}

export function buildCallABikeItem(item, operator, linkname = 'Buchen') {
	const usedClasses = {
		Fahrrad: 0,
		Pedelec: 0,
	};
	let firstVehicleDetails = false;
	const address = buildAddress(item.coordInfoDetails.station.addr);

	if (checkObjectTree(item, ['coordInfoDetails', 'station', 'vehDetails'])) {
		if (Array.isArray(item.coordInfoDetails.station.vehDetails)) {
			item.coordInfoDetails.station.vehDetails.forEach((vehicle) => {
				usedClasses[vehicle.catName] += 1;
			});
			[firstVehicleDetails] = item.coordInfoDetails.station.vehDetails;
		} else {
			usedClasses[item.coordInfoDetails.station.vehDetails.catName] += 1;
			firstVehicleDetails = item.coordInfoDetails.station.vehDetails;
		}
	}

	const vehicletypes = { usedClasses };

	return {
		id: item.id,
		title: item.desc,
		address,
		vehicletypes,
		iosDeeplink: firstVehicleDetails && firstVehicleDetails.dLIOS,
		iosStore: operator.ios,
		androidDeeplink: firstVehicleDetails && firstVehicleDetails.dLAndroid,
		androidStore: operator.android,
		windowsDeeplink: firstVehicleDetails && firstVehicleDetails.dLWindows,
		windowsStore: operator.windows,
		uri: operator.URL,
		linkname: linkname,
		type: 'sharing',
		icon: 'regiorad',
	};
}
