import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const CheckListTitle = styled.h4`
	margin-top: 2rem;
	margin-bottom: 1rem;
`;

const CheckListItemTitle = styled.label`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 1.5rem;
	height: 1rem;

	span {
		cursor: pointer;
		&.title {
			text-align: start;
		}
	}

	input {
		display: none;
	}

	svg {
		height: 1rem;
		width: auto;
	}
`;

function CheckListItem({ title, checked, onChange }) {
	return (
		<CheckListItemTitle>
			<span className="title">{title}</span>
			<input type="radio" checked={checked} onChange={onChange} />
			<span hidden={!checked} role="img" aria-label="checked">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
					<polyline
						points="1,4 3,7 7,1"
						fill="none"
						stroke="#0F7FAC"
						strokeLinecap="round"
					/>
				</svg>
			</span>
		</CheckListItemTitle>
	);
}

CheckListItem.propTypes = {
	title: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
};

function CheckList({ title, checked, items, onChange }) {
	return (
		<Container>
			<CheckListTitle>{title}</CheckListTitle>

			{items.map(({ title: innerTitle, value }) => (
				<CheckListItem
					key={value}
					title={innerTitle}
					checked={checked === value}
					onChange={() => onChange(value)}
				/>
			))}
		</Container>
	);
}

CheckList.propTypes = {
	title: PropTypes.string.isRequired,
	checked: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		}),
	).isRequired,
	onChange: PropTypes.func,
};

CheckList.defaultProps = {
	checked: '',
	onChange: () => {},
};

export default CheckList;
