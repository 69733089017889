import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'shared';

import bike from '../../assets/images/bike.svg';

export const ResultContainer = styled.div``;

export const ErrorContainer = styled.div`
	color: ${colors.bus};
	padding: 40px;
`;

const ResultPreviewContainer = styled.div`
	padding: 80px 40px 2rem;
	position: absolute;
	top: 50%;
	left: 0;
	bottom: 0;
	text-align: center;
	width: 100%;

	&::before {
		background-color: #eee;
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
`;

const ResultPreviewImage = styled.img`
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, -75%);
	max-width: 200px;
	max-height: 200px;
`;

export const ResultTitle = styled.h3`
	margin-bottom: 0.2rem;
`;

export const ResultTime = styled.p`
	color: ${colors.graydark};
	-ms-grid-row: 2;
	-ms-grid-row-span: 1;
	grid-row: 2 / 3;
	-ms-grid-column: 1;
	-ms-grid-column-span: 1;
	grid-column: 1 / 2;
`;

export const ResultItem = styled.div`
	border-bottom: 1px solid #ccc;
	padding: 1.4rem 1rem;
	position: relative;

	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr -webkit-min-content;
	-ms-grid-columns: 1fr min-content;
	grid-template-columns: 1fr -webkit-min-content;
	grid-template-columns: 1fr min-content;
	-ms-grid-rows: (-webkit-min-content) [3];
	-ms-grid-rows: (min-content) [3];
	grid-template-rows: repeat(3, -webkit-min-content);
	grid-template-rows: repeat(3, min-content);

	&:last-child {
		border-bottom: 0;
	}

	.next {
		-ms-grid-row: 1;
		-ms-grid-row-span: 3;
		grid-row: 1 / 4;
		-ms-grid-column: 2;
		-ms-grid-column-span: 1;
		grid-column: 2 / 3;
		-ms-grid-row-align: center;
		-ms-grid-column-align: center;
		place-self: center;
		z-index: 1;

		transition: transform 0.3s ease;

		use {
			transition: fill 0.3s ease;
		}
	}

	${({ isHovered }) =>
		isHovered &&
		css`
			&:hover {
				cursor: pointer;
			}

			&::before {
				background-color: ${colors.orange};
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 5px;
				z-index: 1;
			}

			.next {
				transform: translateX(5px);
			}
			.next use {
				fill: ${colors.orange};
			}
			/*
			.product {
				border-bottom: 2px solid #999;
			}

			.product--Fahrrad,
			.product--RegioRad {
				border-bottom-color: ${colors.bike};
			}
			.product--footpath {
				border-bottom-color: ${colors.footpath};
			}
			.product--R-Bahn {
				border-bottom-color: ${colors.rBahn};
			}
			.product--S-Bahn {
				border-bottom-color: ${colors.sBahn};
			}
			.product--Bus {
				border-bottom-color: ${colors.bus};
			}
			.product--SEV-Bus {
				border-bottom: 2px dashed ${colors.bus};
			}
			*/
		`}
`;

export function ResultPreview() {
	return (
		<ResultPreviewContainer>
			<ResultPreviewImage
				src={bike}
				alt="Illustration eines Fahrrads unter bewölktem Himmel"
			/>
		</ResultPreviewContainer>
	);
}
