import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import 'ol/ol.css';
import Map from 'ol/src/Map';
import View from 'ol/src/View';
import TileLayer from 'ol/src/layer/Tile';
import XYZ from 'ol/src/source/XYZ';
import VectorLayer from 'ol/src/layer/VectorImage';
import VectorSource from 'ol/src/source/Vector';
import { fromLonLat } from 'ol/src/proj';

import Icon from 'ol/src/style/Icon';
import Style from 'ol/src/style/Style';
import Text from 'ol/src/style/Text';

import { breakpoints as media, cloneObject, colors, escapeRegExp } from '../../utils';

// import CustomWMTSLayer from './OSM/CustomWMTSLayer';
// import { epsg3857, toEpsg3857 } from '../../utils/projection';

import { mapConfig } from '../../utils/config';
// import navigationControl from './OSM/NavigationControl';

import markerStyles, { labelStyles, zIndex } from './markerStyles';

const MapWrapper = styled.div`
		background-color: #f8f8f3;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;

		/*
		&::before {
			background-color: #fff;
			display: block;
			content: attr(data-zoom);
			padding: 6px 8px;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 9999;
		}
		*/

		div.ol-zoom {
			display: none;

			${media.sm`
				display: block;
				background: transparent;
				top: auto;
				bottom: 36px;
				left: auto;
				right: 6px;
				z-index: 1004 !important;

				> a {
					width: 27px;
					height: 27px;
					line-height: 25px;
					background-color: ${colors.cyandark};
					border-radius: 5px;
					margin: 0;
				}

				> a:first-child {
					margin-bottom: 2px;
				}
			`}
		}

		.ol-attribution {
			bottom: 6px;
			right: 6px;
			align-items: center;

			> ul {
				background-color: rgba(255,255,255,0.5);
				padding: 3px 6px;
				font-size: 9px;
			}
		}
	}
`;

const footerLinks =
	'<br class="footer-break" /><a class="footer-link" href="https://www.vvs.de/impressum/" target="_blank" rel="noopener noreferrer">Impressum</a> · <a class="footer-link" href="https://www.vvs.de/datenschutz/" target="_blank" rel="noopener noreferrer">Datenschutz</a>';

export const topMapLayer = new TileLayer({
	minZoom: 10,
	maxZoom: 18,
	source: new XYZ({
		attributions: `©  <a href="http://www.openstreetmap.org/copyright" target="_blank" class="footer-link">OpenStreetMap</a> · ${footerLinks}`,
		tileUrlFunction: (tileCoord) => {
			// tileCoord = [z, x, y]
			const [z, x, y] = tileCoord;
			const xMin = x - (x % 100);
			const yMin = y - (y % 100);
			/* eslint-disable prettier/prettier */
			return `https://map.vvs.de/maps/VVS/Map/tiles_MRCV/zoomlevel${z}/columns${xMin}-${
				xMin + 99
			}/column${x}/rows${yMin}-${yMin + 99}/${y}_${x}_${z}.png`;
			/* eslint-enable prettier/prettier */
		},
	}),
});
topMapLayer.set('name', 'GIS');

export const cityMapLayer = new TileLayer({
	source: new XYZ({
		attributions: `© LGL BW; Landeshauptstadt Stuttgart, Stadtmessungsamt · ${footerLinks}`,
		tileUrlFunction: (tileCoord) => {
			// tileCoord = [z, x, y]
			const [z, x, y] = tileCoord;
			const xMin = x - (x % 100);
			const yMin = y - (y % 100);
			/* eslint-disable prettier/prettier */
			return `https://map.vvs.de/maps/VVS/Stadtkarte_MRCV/zoomlevel${z}/columns${xMin}-${
				xMin + 99
			}/column${x}/rows${yMin}-${yMin + 99}/${y}_${x}_${z}.png`;
			/* eslint-enable prettier/prettier */
		},
	}),
	visible: false,
});
cityMapLayer.set('name', 'topkarteMRCV');

export const airMapLayer = new TileLayer({
	source: new XYZ({
		attributions: `© LGL BW, OpenStreetMap · ${footerLinks}`,
		tileUrlFunction: (tileCoord) => {
			// tileCoord = [z, x, y]
			const [z, x, y] = tileCoord;
			const xMin = x - (x % 100);
			const yMin = y - (y % 100);
			/* eslint-disable prettier/prettier */
			return `https://map.vvs.de/maps/VVS/Luftbilder_MRCV/Bild/zoomlevel${z}/columns${xMin}-${
				xMin + 99
			}/column${x}/rows${yMin}-${yMin + 99}/${y}_${x}_${z}.jpg`;
			/* eslint-enable prettier/prettier */
		},
	}),
	visible: false,
});
airMapLayer.set('name', 'orthoMRCV');

export const airMapTextLayer = new TileLayer({
	source: new XYZ({
		attributions: `© LGL BW, OpenStreetMap · ${footerLinks}`,
		tileUrlFunction: (tileCoord) => {
			// tileCoord = [z, x, y]
			const [z, x, y] = tileCoord;
			const xMin = x - (x % 100);
			const yMin = y - (y % 100);
			/* eslint-disable prettier/prettier */
			return `https://map.vvs.de/maps/VVS/Luftbilder_MRCV/Text/zoomlevel${z}/columns${xMin}-${
				xMin + 99
			}/column${x}/rows${yMin}-${yMin + 99}/${y}_${x}_${z}.png`;
			/* eslint-enable prettier/prettier */
		},
	}),
	visible: false,
});
airMapTextLayer.set('name', 'orthoMRCV');

function MapComponent({
	markers,
	transportations,
	features,
	updateMap,
	onPositionChanged,
	onFeatureClicked,
	allMaps,
}) {
	const [map, setMap] = useState();
	const [markerLayer, setMarkerLayer] = useState();
	const [transportationLayer, setTransportationLayer] = useState();
	const [vectorLayer, setVectorLayer] = useState();

	const mapElement = useRef();

	// create state ref that can be accessed in OpenLayers onclick callback function
	//  https://stackoverflow.com/a/60643670
	const mapRef = useRef();
	mapRef.current = map;

	// Initialize map on first render
	useEffect(() => {
		// Create layer for markers
		const initialMarkerLayer = new VectorLayer({
			source: new VectorSource(),
			style(feature) {
				const type = feature.get('type') || 'default';

				if (type === 'hidden') {
					return null;
				}
				const label = feature.get('label');

				const iconStyle = cloneObject(markerStyles[type]);
				if(!iconStyle){
					return;
				}
				let markerZIndex = zIndex[type];

				const scaleDown =
					window.innerWidth >= 1366 &&
					window.innerHeight <= 768 &&
					window.innerWidth > window.innerHeight;

				if (scaleDown) {
					iconStyle.scale = 0.752;
				}

				const isActive = feature.get('state') === 3 || feature.get('hovered') === true;

				if (isActive) {
					iconStyle.offset[0] = 96;
				} else if (feature.get('state') === 2) {
					iconStyle.offset[0] = 50;
				}

				if (feature.get('hovered')) {
					markerZIndex = 70;
				} else if (feature.get('state') === 3) {
					markerZIndex = 60;
				} else if (label) {
					markerZIndex += label.charCodeAt(0) - 65;
				}

				if (feature.get('src')) {
					iconStyle.src = feature.get('src');
				}

				const markerStyle = new Style({
					image: new Icon(iconStyle),
					zIndex: markerZIndex,
				});

				if (label) {
					const labelStyleOptions = { ...labelStyles };

					if (scaleDown) {
						labelStyleOptions.scale = 0.752;
					}

					if (isActive) {
						labelStyleOptions.offsetY -= 3;
					}

					labelStyleOptions.text = feature.get('label');

					const labelStyle = new Style({
						text: new Text(labelStyleOptions),
						zIndex: markerZIndex,
					});

					return [markerStyle, labelStyle];
				}
				return markerStyle;
			},
			zIndex: 50,
		});
		initialMarkerLayer.set('name', 'markers');

		// Create transportation layer
		const initialTransportationLayer = new VectorLayer({
			source: new VectorSource(),
			zIndex: 30,
		});
		initialTransportationLayer.set('name', 'transportation');

		// Create layer for other vector data
		const initialVectorLayer = new VectorLayer({
			source: new VectorSource(),
			zIndex: 20,
		});
		initialVectorLayer.set('name', 'vector');

		const layers = [topMapLayer];
		if (allMaps) {
			layers.push(cityMapLayer, airMapLayer, airMapTextLayer);
		}
		layers.push(initialVectorLayer, initialTransportationLayer, initialMarkerLayer);

		let [lat, lng] = mapConfig.center;
		let { zoom } = mapConfig;

		const pathname = window.location.href
			.replace(new RegExp(`^${escapeRegExp(document.baseURI)}`), '')
			.replace(new RegExp(`${escapeRegExp(window.location.search)}$`), '');

		if (pathname.search('@') > -1) {
			const pathnameParams = pathname.split('@');
			const mapPosition = pathnameParams.pop().split(',');
			if (mapPosition.length === 3) {
				lat = parseFloat(mapPosition[0]);
				lng = parseFloat(mapPosition[1]);
				zoom = parseInt(mapPosition[2].replace(/^z/, ''), 10);
				zoom = Math.min(mapConfig.maxZoom, Math.max(mapConfig.minZoom, zoom));
			}
		}

		const topLeft = fromLonLat([mapConfig.maxExtent[0], mapConfig.maxExtent[1]]);
		const bottomRight = fromLonLat([mapConfig.maxExtent[2], mapConfig.maxExtent[3]]);
		const extent = [topLeft[0], topLeft[1], bottomRight[0], bottomRight[1]];
		const initialMap = new Map({
			target: mapElement.current,
			layers,
			view: new View({
				center: fromLonLat([lng, lat]),
				extent,
				projection: 'EPSG:3857',
				smoothResolutionConstraint: false,
				zoom,
				minZoom: mapConfig.minZoom,
				maxZoom: mapConfig.maxZoom,
			}),
		});

		if (onPositionChanged) {
			const eventFn = () => onPositionChanged(initialMap);
			initialMap.on('updateSize', eventFn);
			initialMap.on('moveend', eventFn);
			initialMap.on('zoomend', eventFn);

			initialMap.once('postrender', eventFn);
		}

		let selectedMarker = null;
		let clickedFeature = null;
		initialMap.on(
			'pointermove',
			(e) => {
				let lastSelected = selectedMarker;
				if (selectedMarker !== null) {
					selectedMarker = null;
				}
				// this code only fires if a feature is visible
				// at that pixel of the move event
				initialMap.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
					if (feature === selectedMarker) return true;
					if (feature === clickedFeature) return true;

					if (feature.get('canHover') !== false) {
						feature.set('hovered', true);
						feature.changed();
						if (feature.get('onHover')) {
							feature.get('onHover')();
						}
						selectedMarker = feature;
						const layerName = layer.get('name');
						const hoveredZIndex = 100;
						const notHoveredZIndex = 60;
						// set z-index of hovered layer higher than other layers
						// otherwise markers don't show up over others
						switch (layerName) {
							case 'markers':
								initialMarkerLayer.setZIndex(hoveredZIndex);
								initialTransportationLayer.setZIndex(notHoveredZIndex);
								initialVectorLayer.setZIndex(notHoveredZIndex);
								break;

							case 'transportation':
								initialMarkerLayer.setZIndex(notHoveredZIndex);
								initialTransportationLayer.setZIndex(hoveredZIndex);
								initialVectorLayer.setZIndex(notHoveredZIndex);
								break;

							case 'vector':
								initialMarkerLayer.setZIndex(notHoveredZIndex);
								initialTransportationLayer.setZIndex(notHoveredZIndex);
								initialVectorLayer.setZIndex(hoveredZIndex);
								break;
							default:
								initialMarkerLayer.setZIndex(notHoveredZIndex);
								initialTransportationLayer.setZIndex(notHoveredZIndex);
								initialVectorLayer.setZIndex(notHoveredZIndex);
								break;
						}
						return true;
					}

					return false;
				});
				// check if a marker was hovered
				// otherwise check if the last hovered marker was set and remove hover state
				if (
					(selectedMarker === null && lastSelected !== null) ||
					(lastSelected !== selectedMarker && lastSelected !== null)
				) {
					lastSelected.set('hovered', false);
					lastSelected.changed();
					if (selectedMarker === null)
						document.getElementById('tooltip').style.display = 'none';
					lastSelected = null;
				}

				// change the cursor to pointer on hover
				const canvasRefs = initialMap
					.getViewport()
					.querySelectorAll('.ol-layer canvas, canvas.ol-layer');
				canvasRefs.forEach((canvas) => {
					// eslint-disable-next-line no-param-reassign
					canvas.style.cursor = selectedMarker !== null ? 'pointer' : '';
				});
			},
			{ layerFilter: (layer) => layer === initialMarkerLayer },
		);
		initialMap.on('click', (e) => {
			let selectedMarkerClicked = false;
			let lastZIndex = -1;
			initialMap.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
				const currentZIndex = layer.get('zIndex');
				// if multiple features are click only click the one with the
				// highest z-index
				if (lastZIndex > currentZIndex) {
					return;
				}
				lastZIndex = currentZIndex;
				if (feature.get('canClick') !== false) {
					if (feature.get('onClick')) {
						feature.get('onClick')(e);
					}
				}
				selectedMarkerClicked = true;
				if (feature === clickedFeature) {
					return;
				}
				if (clickedFeature !== null) {
					clickedFeature.set('hovered', false);
					if (clickedFeature.get('state')) clickedFeature.set('state', 2);
					clickedFeature.changed();
					clickedFeature = null;
				}
				if (feature.get('canHover') !== false) {
					clickedFeature = feature;
					feature.set('hovered', true);
					feature.changed();
					if (feature.get('onHover')) {
						feature.get('onHover')();
					}
					selectedMarker = feature;
					const layerName = layer.get('name');
					const hoveredZIndex = 100;
					const notHoveredZIndex = 60;
					// set z-index of hovered layer higher than other layers
					// otherwise markers don't show up over others
					switch (layerName) {
						case 'markers':
							initialMarkerLayer.setZIndex(hoveredZIndex);
							initialTransportationLayer.setZIndex(notHoveredZIndex);
							initialVectorLayer.setZIndex(notHoveredZIndex);
							break;

						case 'transportation':
							initialMarkerLayer.setZIndex(notHoveredZIndex);
							initialTransportationLayer.setZIndex(hoveredZIndex);
							initialVectorLayer.setZIndex(notHoveredZIndex);
							break;

						case 'vector':
							initialMarkerLayer.setZIndex(notHoveredZIndex);
							initialTransportationLayer.setZIndex(notHoveredZIndex);
							initialVectorLayer.setZIndex(hoveredZIndex);
							break;
						default:
							initialMarkerLayer.setZIndex(notHoveredZIndex);
							initialTransportationLayer.setZIndex(notHoveredZIndex);
							initialVectorLayer.setZIndex(notHoveredZIndex);
							break;
					}
				}
			});
			if (!selectedMarkerClicked) {
				document.getElementById('tooltip').style.display = 'none';
				const selectedTooltip = document.getElementById('tooltip-selected');
				// remove inner html to not mess with tooltip ids
				selectedTooltip.innerHTML = '';
				selectedTooltip.style.display = 'none';

				if (selectedMarker !== null) {
					selectedMarker.set('hovered', false);
					if (selectedMarker.get('state')) selectedMarker.set('state', 2);
					selectedMarker.changed();
					selectedMarker = null;
				}
				if (clickedFeature !== null) {
					clickedFeature.set('hovered', false);
					if (clickedFeature.get('state')) clickedFeature.set('state', 2);
					clickedFeature.changed();
					clickedFeature = null;
				}
			}
			if (onFeatureClicked) {
				onFeatureClicked(initialMap, selectedMarkerClicked);
			}
		});

		// Save map and vector layer references to state
		setMap(initialMap);
		setMarkerLayer(initialMarkerLayer);
		setTransportationLayer(initialTransportationLayer);
		setVectorLayer(initialVectorLayer);

		if (updateMap) {
			updateMap(initialMap);
		}
	}, []);

	useEffect(() => {
		if (!markerLayer) return;
		if (Array.isArray(markers)) {
			markerLayer.setSource(
				new VectorSource({
					features: markers,
				}),
			);
		}
	}, [markerLayer, markers]);

	useEffect(() => {
		if (!transportationLayer) return;
		if (Array.isArray(transportations)) {
			transportationLayer.setSource(
				new VectorSource({
					features: transportations,
				}),
			);
		}
	}, [transportationLayer, transportations]);

	useEffect(() => {
		if (!vectorLayer) return;
		if (Array.isArray(features)) {
			vectorLayer.setSource(
				new VectorSource({
					features,
				}),
			);
		}
	}, [vectorLayer, features]);
	// do not change the tooltip ids -> needed for overlay tooltips
	// tooltip selected is second tooltip for active markers
	return (
		<MapWrapper id="map" className="map" data-zoom={mapConfig.zoom} ref={mapElement}>
			<div id="tooltip" style={{ display: 'none' }}></div>
			<div id="tooltip-selected" style={{ display: 'none' }}></div>
		</MapWrapper>
	);
}

MapComponent.propTypes = {
	markers: PropTypes.array,
	transportations: PropTypes.array,
	features: PropTypes.array,
	onPositionChanged: PropTypes.func,
	onFeatureClicked: PropTypes.func,
	updateMap: PropTypes.func,
	allMaps: PropTypes.bool,
};

MapComponent.defaultProps = {
	markers: [],
	transportations: [],
	features: [],
};

export default MapComponent;
