import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Switch, colors, breakpoints as media } from 'shared';
import icons from '../../assets/icons';

const StyledList = styled.ul`
	margin: 0 0 48px 0;
	padding: 0;
`;

const Item = styled.li`
	border-bottom: 1px solid ${colors.graylight};
	list-style-type: none;
	padding: 0 32px;

	${media.lg`
		padding: 0 48px;
	`}
`;

const Icons = Object.fromEntries(
	Object.entries(icons).map(([key, icon]) => [`${key[0].toLowerCase()}${key.slice(1)}`, icon]),
);
// some love for the the little grey bus
Icons.bus = Icons.busGrey;

// car2go is now sharenow, let's change it
Icons.car2go = Icons.shareNow;

function List({ items, itemCategory, onUpdate }) {
	const { t } = useTranslation();
	const itemsAsArray = Object.entries(items);
	const allChecked = !itemsAsArray.some(([, value]) => value === false);

	return (
		<StyledList>
			{itemsAsArray.length > 1 && (
				<Item>
					<Switch
						id={itemCategory}
						value="1"
						label={t('settings.sidebar.toggleAll')}
						defaultChecked={allChecked}
						onChange={checked => {
							onUpdate(
								Object.fromEntries(itemsAsArray.map(([key]) => [key, checked])),
							);
						}}
					/>
				</Item>
			)}
			{itemsAsArray.map(([key, value]) => (
				<Item key={key}>
					<Switch
						id={`${itemCategory}-${key}`}
						value="1"
						label={t(`settings.${itemCategory}.${key}`)}
						icon={Icons[key]}
						defaultChecked={value}
						onChange={checked => {
							onUpdate(
								Object.fromEntries(
									itemsAsArray.map(([k, v]) => [k, k === key ? checked : v]),
								),
							);
						}}
					/>
				</Item>
			))}
		</StyledList>
	);
}

List.propTypes = {
	itemCategory: PropTypes.string,
	items: PropTypes.object,
	onUpdate: PropTypes.func,
};

List.defaultProps = {
	itemCategory: 'list',
	onUpdate: () => {},
};

export default List;
