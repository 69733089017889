export default function replaceUmlauts(name) {
	return name
		.replace(/ä/g, 'ae')
		.replace(/ü/g, 'ue')
		.replace(/ö/g, 'oe')
		.replace(/Ä/g, 'Ae')
		.replace(/Ö/g, 'Oe')
		.replace(/Ü/g, 'Ue')
		.replace(/ß/g, 'ss')
		.replace(/[^a-z0-9_.-]/gi, '-')
		.replace(/---/g, '-');
}
