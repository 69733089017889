import { API_BASE } from './constants';

let poiCacheId = '';
let poiResponse;

function mapV2ToV1(poi) {
	return {
		...poi,
		poiHierarchy: poi.properties.POI_HIERARCHY_KEY,
		category: poi.properties.POI_HIERARCHY_0,
		distance: poi.properties.distance,
	};
}

/**
 * Fetch POIs around the given position
 *
 * @name fetchPOIsByArea
 * @function
 * @param {double|string} lat1 The latitude value of the bottom left corner.
 * @param {double|string} lng1 The longitude value of the bottom left corner.
 * @param {double|string} lat2 The latitude value of the top right corner.
 * @param {double|string} lng2 The longitude value of the top right corner.
 * @param {string[]|null} [categories=null] Fetch POIs for the given categories only.
 * @param {object} [fetchOptions={}] Options for the fetch request
 * @return {object[]} Array of POIs around the given position.
 */
export default (lat1, lng1, lat2, lng2, categories = null, fetchOptions = {}) => {
	if (categories !== null && categories.length === 0) {
		return [];
	}
	const cacheId = `${lat1.toFixed(5)}|${lng1.toFixed(5)}|${lat2.toFixed(5)}|${lng2.toFixed(5)}|${
		categories !== null ? categories.join(',') : ''
	}`;
	if (cacheId !== poiCacheId) {
		// eslint-disable-next-line no-param-reassign
		fetchOptions.cache = fetchOptions.cache || 'force-cache';

		poiCacheId = cacheId;
		poiResponse = fetch(
			`${API_BASE}pois-area-v2?lat1=${lat1}&lng1=${lng1}&lat2=${lat2}&lng2=${lng2}${
				categories !== null ? `&categories=${encodeURIComponent(categories.join(','))}` : ''
			}&${window.additionalApiParams}`,
			fetchOptions,
		)
			.then((response) => response.json())
			.then((json) => json.locations)
			.then((pois) => pois.map(mapV2ToV1));
	}
	return poiResponse;
};
// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
