import { API_BASE } from './constants';

let operatorResponse;

/**
 * Fetch available sharing operators.
 *
 * @name fetchSharingOperators
 * @function
 * @return {object[]}
 */
export default () => {
	if (!operatorResponse) {
		operatorResponse = fetch(
			`${API_BASE}sharing/operators?${window.additionalApiParams}`,
		).then(response => response.json());
	}
	return operatorResponse;
};
// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
