// Cookie-Helpers
export function setCookie(key, value) {
	const d = new Date();
	const days = 365;
	d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
	const expires = `expires=${d.toUTCString()}`;

	// VVS-Cookie
	document.cookie = `${key}=${value}; ${expires}; path=/; domain=.vvs.de; SameSite=Lax`;

	const cookieEvent = document.createEvent('CustomEvent');
	cookieEvent.initCustomEvent('cookie', false, false, {
		name: key,
		value: `${value}`,
	});
	document.dispatchEvent(cookieEvent);
}

export function getCookie(key) {
	const keyValue = document.cookie.match(`(^|;) ?${key}=([^;]*)(;|$)`);
	return keyValue ? keyValue[2] : null;
}

export function deleteCookie(key) {
	document.cookie = `${key}= ; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}

export function cleanUpCookies(selectedCookies) {
	const cookieNames = document.cookie.split(/=[^;]*(?:;\s*|$)/);

	const cookiesToDelete = [];
	const trackingCookies = ['_pk_id', '_pk_ref', '_pk_ses', 'MATOMO_SESSID', 'piwik_ignore'];
	const thirdPartyCookies = ['newSDE', 'GPS', 'VISITOR_INFO1_LIVE', 'YSC'];

	// eslint-disable-next-line no-bitwise
	if ((selectedCookies & 2) === 0) {
		// Keine trackingCookies => löschen
		cookiesToDelete.push(...trackingCookies);
	}
	// eslint-disable-next-line no-bitwise
	if ((selectedCookies & 4) === 0) {
		// Keine ThirdpartyCookies => löschen.
		cookiesToDelete.push(...thirdPartyCookies);
	}
	cookiesToDelete.forEach(cookieStartsWith => {
		cookieNames.forEach(cookieName => {
			if (cookieName.indexOf(cookieStartsWith) === 0) {
				deleteCookie(cookieName);
			}
		});
	});
}
