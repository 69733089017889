export function getMinMaxCoordsOfRoute(route) {
	if (
		route.legs.length === 0 ||
		!route.legs.every(leg => Array.isArray(leg.coords)) ||
		route.legs[0].coords.length === 0
	) {
		return false;
	}
	const firstCoords = route.legs[0].coords[0];
	const result = {
		minLat: firstCoords[0],
		minLon: firstCoords[1],
		maxLat: firstCoords[0],
		maxLon: firstCoords[1],
	};
	route.legs.forEach(leg => {
		leg.coords.forEach(coord => {
			if (coord[0] < result.minLat) {
				[result.minLat] = coord;
			} else if (coord[0] > result.maxLat) {
				[result.maxLat] = coord;
			}

			if (coord[1] < result.minLon) {
				[, result.minLon] = coord;
			} else if (coord[1] > result.maxLon) {
				[, result.maxLon] = coord;
			}
		});
	});

	return result;
}
