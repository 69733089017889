import { API_FREIZEITTIPPS_BASE } from './constants';

let tipCacheId = '';
let tipResponse;

/**
 * Fetch leisure time tips around the given position
 *
 * @name fetchTipps
 * @function
 * @param {double|string} lat1 The latitude value of the bottom left map extent.
 * @param {double|string} lng1 The longitude value of the bottom left map extent.
 * @param {double|string} lat2 The latitude value of the top right map extent.
 * @param {double|string} lng2 The longitude value of the top right map extent.
 * @param {object} [fetchOptions={}] Options for the fetch request.
 * @return {object[]} Array of leisure time tips in the current map view.
 */
export default async (lat1, lng1, lat2, lng2, fetchOptions = {}) => {
	const cacheId = `${lat1.toFixed(5)}|${lng1.toFixed(5)}|${lat2.toFixed(5)}|${lng2.toFixed(5)}`;
	if (cacheId !== tipCacheId) {
		// eslint-disable-next-line no-param-reassign
		fetchOptions.cache = fetchOptions.cache || 'force-cache';

		tipCacheId = cacheId;
		tipResponse = fetch(
			`${API_FREIZEITTIPPS_BASE}area.json?lat1=${lat1}&lng1=${lng1}&lat2=${lat2}&lng2=${lng2}&${window.additionalApiParams}`,
			fetchOptions,
		).then(response => response.json());
	}
	return tipResponse;
	// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
};
