import React from 'react';
import ReactDOM from 'react-dom';
import {
	SharingButton,
	buildCallABikeItem,
	buildCar2GoItem,
	buildStadtmobilFlinksterItem,
	buildStellaItem,
	sharingMappings,
} from 'shared';

const buildStationAddress = addressData =>
	addressData.map((data, i) => {
		if (i === addressData.length) {
			return (
				<span key={`address-${data}-br`}>
					{data}
					{i === addressData.length - 1 && <br />}
				</span>
			);
		}
		return <span key={`address-${data}`}>{data}</span>;
	});

export default function renderSharingTooltip(
	station,
	operators,
	tooltip,
	additionalContent,
	openPopup = () => {},
) {
	switch (station.type) {
		case 'STADTMOBIL':
		case 'FLINKSTER': {
			const adjustedItems = buildStadtmobilFlinksterItem(station, operators);
			const customUri =
				station.type === 'STADTMOBIL'
					? 'https://stuttgart.stadtmobil.de/privatkunden/'
					: 'https://www.flinkster.de/index.php';

			const buttonColor = station.type === 'STADTMOBIL' ? '#E2000F' : '#E20613';

			ReactDOM.render(
				<div
					className={`marker__tooltip marker__tooltip--sharing marker__tooltip--${station.type.toLowerCase()}`}
				>
					<h2 className="tooltipcontent__title">
						{sharingMappings[station.type] || station.type}
					</h2>
					<p>{adjustedItems.title}</p>
					<p>
						<strong>Verfügbare Fahrzeuge:</strong> {adjustedItems.subtitle}
					</p>
					<p>
						{adjustedItems.vehicletypesLength > 1 ? 'Fahrzeugtypen: ' : 'Fahrzeugtyp: '}
						{station.type === 'STADTMOBIL' && <br />}
						{adjustedItems.vehicletypes}
					</p>
					<p className="tooltipcontent__address">
						{buildStationAddress(adjustedItems.address)}
					</p>
					<SharingButton
						iosDeeplink={adjustedItems.iosDeeplink}
						iosStore={adjustedItems.iosStore}
						androidDeeplink={adjustedItems.androidDeeplink}
						androidStore={adjustedItems.androidStore}
						windowsDeeplink={adjustedItems.windowsDeeplink}
						windowsStore={adjustedItems.windowsStore}
						uri={adjustedItems.uri || customUri}
						openPopup={openPopup}
						color={buttonColor}
					>
						{adjustedItems.linkname}
					</SharingButton>
					{additionalContent}
				</div>,
				tooltip,
			);
			break;
		}
		case 'stella': {
			const adjustedItems = buildStellaItem(station, operators);
			ReactDOM.render(
				<div className="marker__tooltip marker__tooltip--sharing marker__tooltip--stella">
					<h2 className="tooltipcontent__title">
						{sharingMappings[station.type] || station.type}
					</h2>
					<p>{adjustedItems.title}</p>
					<p>{adjustedItems.subtitle}</p>
					<p>
						{adjustedItems.vehicletypesLength > 1 ? 'Fahrzeugtypen: ' : 'Fahrzeugtyp: '}
						{adjustedItems.vehicletypes.carType}
					</p>
					<p className="tooltipcontent__address">
						{buildStationAddress(adjustedItems.address)}
					</p>
					<SharingButton
						iosDeeplink={adjustedItems.iosDeeplink}
						iosStore={adjustedItems.iosStore}
						androidDeeplink={adjustedItems.androidDeeplink}
						androidStore={adjustedItems.androidStore}
						windowsDeeplink={adjustedItems.windowsDeeplink}
						windowsStore={adjustedItems.windowsStore}
						uri={adjustedItems.uri || 'https://www.stella-sharing.de/'}
						openPopup={openPopup}
						color="#18ACE9"
					>
						{adjustedItems.linkname}
					</SharingButton>
					{additionalContent}
				</div>,
				tooltip,
			);
			break;
		}
		case 'car2go': {
			const adjustedItems = buildCar2GoItem(station, operators);
			ReactDOM.render(
				<div className="marker__tooltip marker__tooltip--sharing marker__tooltip--car2go">
					<h2 className="tooltipcontent__title">
						{sharingMappings[station.type] || station.type}
					</h2>
					<p>{adjustedItems.title}</p>
					<p>{adjustedItems.subtitle}</p>
					<p>
						{adjustedItems.vehicletypesLength > 1 ? 'Fahrzeugtypen: ' : 'Fahrzeugtyp: '}
						{adjustedItems.vehicletypes}
					</p>
					<p className="tooltipcontent__address">
						{buildStationAddress(adjustedItems.address)}
					</p>
					<SharingButton
						iosDeeplink={adjustedItems.iosDeeplink}
						iosStore={adjustedItems.iosStore}
						androidDeeplink={adjustedItems.androidDeeplink}
						androidStore={adjustedItems.androidStore}
						windowsDeeplink={adjustedItems.windowsDeeplink}
						windowsStore={adjustedItems.windowsStore}
						uri={adjustedItems.uri || 'https://www.car2go.com/DE/de/stuttgart/'}
						openPopup={openPopup}
						color="#1498E3"
					>
						{adjustedItems.linkname}
					</SharingButton>
					{additionalContent}
				</div>,
				tooltip,
			);
			break;
		}
		case 'CALL_A_BIKE': {
			const adjustedItems = buildCallABikeItem(station, operators);
			ReactDOM.render(
				<div className="marker__tooltip marker__tooltip--sharing marker__tooltip--regiorad">
					<h2 className="tooltipcontent__title">
						{sharingMappings[station.type] || station.type}
					</h2>
					<p>{adjustedItems.title}</p>
					<p>
						<strong>Verfügbare Fahrräder:</strong>{' '}
						{adjustedItems.vehicletypes.usedClasses.Fahrrad}
						<br /> <strong>Verfügbare Pedelecs:</strong>{' '}
						{adjustedItems.vehicletypes.usedClasses.Pedelec}
					</p>
					<p className="tooltipcontent__address">
						{buildStationAddress(adjustedItems.address)}
					</p>
					<SharingButton
						iosDeeplink={adjustedItems.iosDeeplink}
						iosStore={adjustedItems.iosStore}
						androidDeeplink={adjustedItems.androidDeeplink}
						androidStore={adjustedItems.androidStore}
						windowsDeeplink={adjustedItems.windowsDeeplink}
						windowsStore={adjustedItems.windowsStore}
						uri="https://www.regioradstuttgart.de/de"
						openPopup={openPopup}
						color="#06507F"
					>
						{adjustedItems.linkname}
					</SharingButton>
					{additionalContent}
				</div>,
				tooltip,
			);
			break;
		}
		default:
			break;
	}
}
