import styled from 'styled-components';

export const StyledListItem = styled.li`
	padding: 0;
	padding: 0.4rem;

	display: flex;

	&:not(:last-child) {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}
`;
