import { API_BASE } from './constants';

const defaultOptions = {
	debugEnabled: false,
	usePseudoRouting: 1,
	datetimeType: 'dep',
	transportations: {
		cityTrain: true, // Straßenbahn / U-Bahn
		regionalTrain: true, // IRE, RE, RB
		cityRailway: true, // S-Bahn
		cogRailway: true, // Zahnradbahn
		bus: true,
	},
	routeType: 'commuter', // commuter (Pendler) or leisure (Freizeit)
	fitnessLevel: 'average', // untrained, average, sporty, electric
};

const sharingTypes = ['stella', 'STADTMOBIL', 'FLINKSTER', 'car2go', 'CALL_A_BIKE'];

const savedParams = {};

function addPositionParams(params, type, item) {
	if (item.type === 'tip') {
		// eslint-disable-next-line no-param-reassign
		params[`name_${type}`] = encodeURIComponent(
			`${item.address.coords[1]}:${item.address.coords[0]}:WGS84[dd.ddddd]`,
		);
		// eslint-disable-next-line no-param-reassign
		params[`type_${type}`] = 'coord';
	} else if (sharingTypes.indexOf(item.type) > -1) {
		// eslint-disable-next-line no-param-reassign
		params[`name_${type}`] = encodeURIComponent(item.stateless);
		// eslint-disable-next-line no-param-reassign
		params[`type_${type}`] = 'coord';
	} else {
		// eslint-disable-next-line no-param-reassign
		params[`name_${type}`] = encodeURIComponent(item.id);
		// eslint-disable-next-line no-param-reassign
		params[`type_${type}`] = 'any';
	}
}

export function getSavedParamsByType(type) {
	return savedParams[type];
}

function getRequestParams(_points, type, _options, settings) {
	let points;
	try {
		points = _points.filter(point => point !== null);
	} catch (e) {
		console.log('Handling points failed');
		return false;
	}

	if (points.length < 2) {
		console.log('Too few points.');
		return false;
	}

	const options = { ...defaultOptions, ..._options };
	// const fitnessOptions =
	// 	defaultFitnessOptions[options.fitnessLevel] || defaultFitnessOptions.average;
	const routeTypeOptions = {};
	// defaultRouteTypeOptions[options.routeType] || defaultRouteTypeOptions.leisure;

	/**
	 * Define default parameters
	 */
	const params = {
		// inclFilter: 1,
		max: 1,
		calcNumberOfTrips: 1, // Only request one route
		descWithElev: 1, // Output height data
		includedMeans: 'checkbox', // enable filter for used transportations
		coordOutputZ: 1,
		coordOutputFormat: encodeURIComponent('WGS84[DD.DDDDD]'),
		type_origin: 'any',
		type_destination: 'any',
		useUT: 1, // add ticket prices
		useRealtime: 1,
		calculateDistance: 1,
		// purposeSuffix: 'RRP',
	};

	switch (type) {
		case 'bikeTransport':
			params.RRPAPP_Macro_FahrtOepnvMitRad = 1;
			break;
		case 'bikeAndRide':
			params.RRPAPP_Macro_FahrtOepnvOhneRad = 1;
			break;
		case 'bikeSharing':
			params.RRPAPP_Macro_RegioRad = 1;
			break;
		case 'bikeOnly':
		default:
			params.RRPAPP_Macro_FahrtMitRad = 1;
	}

	switch (options.routeType) {
		// Pendler
		case 'commuter':
			params.RRPAPP_Macro_PendlerRoute = 1;
			break;
		// Freizeit
		case 'leisure':
			params.RRPAPP_Macro_FreizeitRoute = 1;
			break;
		// Individuell
		case 'individual':
		default:
			routeTypeOptions.mainRoutes = !!settings.preferMainRoutes;
			routeTypeOptions.themeRoutes = !!settings.preferThemeRoute;
			routeTypeOptions.greenTracks = !!settings.preferGreenTracks;
			routeTypeOptions.asphaltTracks = !!settings.preferAsphaltTracks;
			break;
	}

	switch (options.fitnessLevel) {
		case 'untrained':
			params.RRPAPP_Macro_Untrainiert = 1;
			break;
		case 'average':
			params.RRPAPP_Macro_Durchschnittlich = 1;
			break;
		case 'sporty':
			params.RRPAPP_Macro_Sportlich = 1;
			break;
		case 'electric':
			params.RRPAPP_Macro_Elektro = 1;
			break;
		case 'individual':
		default:
			params.cycleSpeed = settings.speed || 15;
			params.elevFac = settings.pitch || 10;
	}

	if (options.debugEnabled) {
		routeTypeOptions.lessTrafficRoutes = options.usePseudoRouting;
	}

	const start = points.shift();
	const end = points.pop();

	addPositionParams(params, 'origin', start);
	addPositionParams(params, 'destination', end);

	if (routeTypeOptions.mainRoutes) {
		params.useSignedRoute = 1;
	}
	if (routeTypeOptions.themeRoutes) {
		params.prefHikePath = 1;
		params.prefHikePathTracks = 1;
	}
	if (routeTypeOptions.lessTrafficRoutes) {
		// Always on, because usePseudoRouting=1 is required to enable bike routing
		// Can be disabled by query params using ?debug=1&usePseudoRouting=0
		params.usePseudoRouting = 1;
	}

	// if (routeTypeOptions.greenTracks) {
	// 	params.preferGreenTracks = 1;
	// }

	if (routeTypeOptions.asphaltTracks) {
		params.preferAsphaltTracks = 1;
	}

	if (options.transportations.cityRailway) {
		params.inclMOT_3 = 1; // Stadtbahn
		params.inclMOT_4 = 1; // Straßenbahn
	}
	if (options.transportations.regionalTrain) {
		params.inclMOT_0 = 1; // Zug
		params.inclMOT_13 = 1; // Regionalzug (z.B. IRE, RE und RB)
	}
	if (options.transportations.cityTrain) {
		params.inclMOT_0 = 1; // Zug
		params.inclMOT_1 = 1; // S-Bahn
	}
	if (options.transportations.cogRailway) {
		params.inclMOT_8 = 1; // Seil-/Zahnradbahn
	}
	if (options.transportations.bus) {
		params.inclMOT_5 = 1; // Stadtbus
		params.inclMOT_6 = 1; // Regionalbus
		params.inclMOT_7 = 1; // Schnellbus
		params.inclMOT_10 = 1; // AST/Rufbus
		params.inclMOT_17 = 1; // Schienenersatzverkehr
		params.inclMOT_18 = 1; // Schuttlezug
		params.inclMOT_19 = 1; // Bürgerbus
	}

	if (typeof options.datetime !== 'undefined') {
		const match = /^(\d{4})-(\d{2})-(\d{2})[T ](\d{2}):(\d{2})/.exec(options.datetime);
		if (match !== null) {
			const [, year, month, day, hour, minute] = match;
			params.itdDate = `${year}${month}${day}`;
			params.itdTime = `${hour}${minute}`;
			params.itdTripDateTimeDepArr = options.datetimeType;
		}
	}

	if (options.date instanceof Date) {
		const { date } = options;

		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date
			.getDate()
			.toString()
			.padStart(2, '0');
		const hours = date
			.getHours()
			.toString()
			.padStart(2, '0');
		const minutes = date
			.getMinutes()
			.toString()
			.padStart(2, '0');

		params.itdDate = `${date.getFullYear()}${month}${day}`;
		params.itdTime = `${hours}${minutes}`;
		params.itdTripDateTimeDepArr = options.datetimeType;
	}

	if (type !== 'bikeSharing') {
		if (typeof options.maxTimeAccess !== 'undefined') {
			params.trITDepMOTvalue101 = options.maxTimeAccess;
			params.trITDepMOTvalue102 = options.maxTimeAccess;
		}

		if (typeof options.maxTimeExit !== 'undefined') {
			params.trITArrMOTvalue101 = options.maxTimeExit;
			params.trITArrMOTvalue102 = options.maxTimeExit;
		}
	}

	if (points.length > 0) {
		params.computationType = 'SEQUENCEEX';
		params.addViaPos = 1;
		params.via_index = points.length;

		points.forEach((point, i) => {
			addPositionParams(params, `via${i + 1}`, point);
		});
	}

	savedParams[type] = params;

	return params;
}

/**
 * Fetch POIs around the given position
 *
 * @name fetchBikeRoute
 * @function
 * @param {array}  points  The passed points of the route.
 * @param {string} type    The type used to get a route. One of: bikeOnly, bikeTransport, bikeAndRide, bikeSharing.
 * @param {object} options Options passed to the fetch.
 *        Available options:
 *        - datetime: DateTime string for the request (YYYY-MM-DDTHH:ii:ss)
 *        - datetimeType: dep (departure) or arr (arrival)
 * @param {object} settings Setttings from context
 *
 * @return {object} The returned route.
 */
export default async (points, type, _options = {}, settings = {}, fetchOptions = {}) => {
	if (points.length > 3 && type !== 'bikeOnly') {
		return false;
	}

	if (points.length > 2 && type === 'bikeSharing') {
		return false;
	}

	const params = getRequestParams(points, type, _options, settings);

	if (params === false) {
		return false;
	}

	const query = Object.keys(params)
		.map(key => `${key}=${encodeURIComponent(params[key])}`)
		.join('&');

	// eslint-disable-next-line no-param-reassign
	fetchOptions.cache = fetchOptions.cache || 'force-cache';

	return fetch(
		`${API_BASE}XSLT_TRIP_REQUEST2?directory=rrp&${query}&${window.additionalApiParams}`,
		fetchOptions,
	)
		.then(response => response.json())
		.then(json => {
			if (json.journeys && json.journeys.length > 0) {
				return json.journeys[0];
			}
			return false;
		});
};
// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
