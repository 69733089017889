import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../utils/config';

const Wrapper = styled.div`
	color: #fff;
	font-size: 0.875rem;
	font-weight: 700;
	margin-bottom: 1rem;
	outline: 0;
	position: absolute;
	right: 0;
	top: 6px;
	user-select: none;
	height: 1.5rem;

	.switch-paddle {
		background: #b4b4b4;
		border-radius: 1.5rem;
		display: inline-block;
		font-weight: inherit;
		position: relative;
		transition: all 0.25s ease-out;
		width: 3rem;
		height: 1.5rem;

		&:hover {
			cursor: pointer;
		}

		&::after {
			background: #fff;
			border-radius: 1.5rem;
			content: '';
			display: block;
			position: absolute;
			top: 0.125rem;
			left: 0.125rem;
			transform: translateZ(0);
			transition: all 0.25s ease-out;
			width: 1.25rem;
			height: 1.25rem;
		}
	}

	> input {
		opacity: 0;
		visibility: hidden;
		width: 0;
		height: 0;

		&:disabled + .switch-paddle {
			cursor: not-allowed;
			opacity: 0.5;
		}

		&:checked + .switch-paddle {
			background: ${colors.cyandark};

			&::after {
				left: 1.625rem;
			}
		}
	}

	.sr-only {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		border: 0;
	}
`;

export default function Switch({ id, title, value, checked, disabled, onChange }) {
	const input = useRef();

	return (
		<Wrapper>
			<input
				type="checkbox"
				value={value}
				id={id}
				checked={checked}
				disabled={disabled}
				ref={input}
				onChange={() => {
					onChange(input.current.checked);
				}}
			/>
			<label className="switch-paddle" htmlFor={id}>
				<span className="sr-only">{title}</span>
			</label>
		</Wrapper>
	);
}

Switch.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
};

Switch.defaultProps = {
	onChange: () => {},
};
