import { COORD_RADIUS, COORD_LIMIT } from './constants';
import searchLocations from './searchLocations';
import fetchCoords from './fetchCoords';

class NoLocationException {
	constructor(message) {
		this.name = 'NoLocationException';
		this.message = message;
	}
}

/**
 * Fetch all data for a given location id.
 *
 * @name fetchLocationData
 * @function
 * @param {string} input The location id.
 * @param {object} [fetchOptions={}] Options for the fetch request
 * @return {object} The location found for the id.
 */
export default async (locationId, fetchOptions = {}) => {
	// Fetch locations found for the given input / location id
	const locations = await searchLocations(locationId, fetchOptions);

	if (locations.length) {
		const newLocation = locations[0];

		// Fetch stops around the found location.
		const stops = await fetchCoords(
			newLocation.coord[0], // Lat
			newLocation.coord[1], // Lon
			COORD_RADIUS, // Radius
			COORD_LIMIT, // Limit
			true, // Add distance to output
			fetchOptions,
		);
		if (stops.success) {
			newLocation.assignedStops = stops.pins;
		} else {
			newLocation.assignedStops = [];
		}
		return newLocation;
	}
	throw new NoLocationException('No locations found. Redirect to Home.');
};
