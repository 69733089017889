/**
 * @name getSearchLabelFromResult
 * @param {object} result The result from which the label should be generated.
 */
export default function(result) {
	if (!result) {
		return '';
	}
	switch (result.type) {
		case 'stop':
			if (result.disassembledName && result.parent.name !== result.disassembledName) {
				return `${result.disassembledName}, ${result.parent.name}`;
			}
			return result.disassembledName || result.name;
		case 'poi':
			return result.name;
		case 'tip':
			return `${result.title}, ${result.address.city}`;
		case 'suburb':
		case 'street':
		case 'singlehouse': {
			if (result.disassembledName) {
				return `${result.disassembledName}, ${result.parent.name}`;
			}
			return result.name;
		}

		// Marker types
		case 'STOP':
			return `${result.desc}, ${result.locality}`;
		default:
			return result.shownLabel || result.name || result.disassembledName;
	}
}
