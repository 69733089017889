import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { colors, SidebarHeader } from 'shared';
import RouteList from '../RouteList';
import Context from '../context';

const Content = styled.div`
	padding: 1rem;
`;

export default function MyRoutes({ onBack }) {
	const { t } = useTranslation();
	const { myRoutes } = useContext(Context);

	return (
		<>
			<SidebarHeader
				backgroundColor={colors.orange}
				title={t('myRoutes.headline')}
				onBack={onBack}
			/>
			<Content>
				<RouteList routes={myRoutes} />
			</Content>
		</>
	);
}

MyRoutes.propTypes = {
	onBack: PropTypes.func,
};

MyRoutes.defaultProps = {
	onBack: () => {},
};

export { MyRoutes };
export { default as MyRouteDetails } from './details';
