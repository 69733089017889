import getCoordOfPoint from './getCoordOfPoint';
import getSearchLabelFromResult from './getSearchLabelFromResult';

export default function createGpx(
	points,
	legs,
	creator = 'VVS Radroutenplaner',
	savePoints = true,
) {
	let gpx = `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
<gpx version="1.1" creator="${creator}">`;

	// Generate metadata
	gpx += '<metadata>';
	gpx += `<name>Route von ${getSearchLabelFromResult(points[0])} nach ${getSearchLabelFromResult(
		points[points.length - 1],
	)}</name>`;
	gpx += '</metadata>';

	if (savePoints) {
		points.forEach(point => {
			const coord = getCoordOfPoint(point);
			gpx += `<wpt lat="${coord[0]}" lon="${coord[1]}">`;
			gpx += `<name>${getSearchLabelFromResult(point)}</name>`;
			gpx += `<type>Waypoint</type>`;
			gpx += `</wpt>`;
		});
	}

	let fullRoute = '';

	legs.forEach(leg => {
		const originName = getSearchLabelFromResult(leg.origin);
		const destinationName = getSearchLabelFromResult(leg.destination);
		let transportationProduct = 'Fußweg';
		if (leg.transportation.product.name !== 'footpath') {
			transportationProduct = leg.transportation.product.name;
		}
		gpx += '<trk>';
		if (originName === destinationName) {
			gpx += `<name>${transportationProduct} ${originName}</name>`;
		} else {
			gpx += `<name>${transportationProduct} von ${originName} nach ${destinationName}</name>`;
		}
		gpx += `<type>${transportationProduct}</type>`;
		gpx += '<trkseg>';
		let legRoute = '';
		leg.coords.forEach(coord => {
			legRoute += `<trkpt lat="${coord[0]}" lon="${coord[1]}">`;
			if (typeof coord[2] !== 'undefined') {
				legRoute += `<ele>${coord[2].z}</ele>`;
			}
			legRoute += '</trkpt>';
		});
		fullRoute += legRoute;
		gpx += legRoute;
		gpx += '</trkseg>';
		gpx += '</trk>';
	});

	if (legs.length > 1) {
		gpx += '<trk>';
		gpx += `<name>Komplette Route von ${getSearchLabelFromResult(
			legs[0].origin,
		)} nach ${getSearchLabelFromResult(legs[legs.length - 1].destination)}</name>`;
		gpx += '<trkseg>';
		gpx += fullRoute;
		gpx += '</trkseg>';
		gpx += '</trk>';
	}

	gpx += '</gpx>';

	return gpx;
}
