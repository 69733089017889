import { API_BASE } from './constants';

const locationInfoCache = {};

/**
 * Fetch POIs around the given position
 *
 * @name fetchPOIsByArea
 * @function
 * @param {string} poiId The id of the poi.
 * @param {object} [fetchOptions={}] Options for the fetch request
 * @return {object} POI for the given id.
 */
export default async (lat, lng, fetchOptions = {}) => {
	const cacheId = `${lat.toFixed(5)}|${lng.toFixed(5)}`;
	if (typeof locationInfoCache[cacheId] === 'undefined') {
		// eslint-disable-next-line no-param-reassign
		fetchOptions.cache = fetchOptions.cache || 'force-cache';

		locationInfoCache[cacheId] = fetch(
			`${API_BASE}XML_STOPFINDER_REQUEST?SpEncId=0&coordOutputFormat=EPSG:4326&name_sf=coord:${lng.toFixed(
				5,
			)}:${lat.toFixed(5)}:WGS84%5Bdd.ddddd%5D&outputFormat=rapidJSON&type_sf=any&${
				window.additionalApiParams
			}`,
			fetchOptions,
		).then(response => response.json());
	}
	return locationInfoCache[cacheId];
};
// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
