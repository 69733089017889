export default function createGpx(route, creator = 'VVS Radroutenplaner') {
	let gpx = `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
<gpx version="1.1" creator="${creator}">`;

	// Generate metadata
	gpx += `<metadata><name>${route.name}</name></metadata>`;

	// Open route and add name and type
	gpx += `<rte><name>${route.name}</name><type>${
		route.name.match(/(Wanderung|Spaziergang|Wanderweg)/) ? 'Fußweg' : 'Fahrrad'
	}</type>`;

	route.pathCoord.forEach(coords => {
		gpx += `<rtept lat="${coords[0]}" lon="${coords[1]}">`;
		if (typeof coords[2] !== 'undefined') {
			gpx += `<ele>${coords[2]}</ele>`;
		}
		gpx += `</rtept>`;
	});

	// Close route
	gpx += '</rte>';

	// Clouse gpx string
	gpx += '</gpx>';

	return gpx;
}
