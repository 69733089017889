export default function getRouteSummary(route) {
	const result = {
		footpath: {
			distance: 0,
			duration: 0,
			distanceUp: 0,
			distanceDown: 0,
		},
		bike: {
			distance: 0,
			duration: 0,
			distanceUp: 0,
			distanceDown: 0,
		},
	};
	route.legs.forEach(leg => {
		if (['footpath', 'Fahrrad', 'RegioRad'].indexOf(leg.transportation.product.name) > -1) {
			if (typeof leg.pathDescriptions === 'undefined') {
				return;
			}
			const type = leg.transportation.product.name === 'footpath' ? 'footpath' : 'bike';

			leg.pathDescriptions.forEach(item => {
				result[type].distance += item.distance;
				result[type].duration += item.duration;

				if (item.distanceUp) {
					result[type].distanceUp += item.distanceUp;
				}
				if (item.distanceDown) {
					result[type].distanceDown += item.distanceDown;
				}
			});
		}
	});

	return result;
}
