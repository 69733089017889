import styled from 'styled-components';

const LoadingBar = styled.div`
	background-color: ${props => (props.headline ? '#ccc' : '#eee')};
	margin: ${props => (props.headline ? '.8em 0 .6em' : '.4em 0')};
	height: ${props => (props.headline ? '1.5em' : '1em')};
	width: ${props => props.width || (props.headline ? '75%' : '100%')};

	.full-width & {
		width: ${props => (props.headline ? '90%' : '100%')};
	}
`;

export default LoadingBar;
