import React from 'react';
import styled from 'styled-components';
import { ImageLoadingIndicator, TextBlockLoadingIndicator } from 'shared';

import { ImageWrapper, StyledListItem } from './styles';

const LoadingWrapper = styled.div`
	font-size: 0.7rem;
	width: 100%;
`;

export default function LoadingRoute() {
	return (
		<StyledListItem>
			<ImageWrapper>
				<ImageLoadingIndicator height="3rem" />
			</ImageWrapper>
			<LoadingWrapper>
				<TextBlockLoadingIndicator rows={1} />
			</LoadingWrapper>
		</StyledListItem>
	);
}
