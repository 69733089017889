import { mapRoot } from '../../utils';

function getItem(key, fallback) {
	return JSON.parse(window.localStorage.getItem(key)) || fallback;
}

export default {
	isLeftSidebarOpen: new RegExp(
		`^${mapRoot}/((oepnv|rad)-einstellungen|meine-routen|radrouten|routenplanung|r/.)(.*)(@.+)?$`,
	).test(window.location.pathname),
	isSettingsSidebarOpen: false,
	points: process.env.NODE_ENV === 'development' ? getItem('POINTS', []) : [],
	bikeSettings: getItem('BIKE_SETTINGS', {
		route: 'commuter',
		preferThemeRoute: true,
		preferMainTracks: true,
		preferAsphaltTracks: true,
		preferGreenTracks: false,
		fitness: 'average',
		speed: 15,
		pitch: 10,
	}),
	publicTransportSettings: getItem('PUBLIC_TRANSPORT_SETTINGS', {
		cityTrain: true,
		regionalTrain: true,
		cityRailway: true,
		cogRailway: true,
		bus: false,
		shortedPossible: false,
		maxTimeAccess: 15,
		maxTimeExit: 15,
	}),
	myRoutes: getItem('MY_ROUTES', []),
	settings: getItem('SETTINGS', {
		publicTransport: {
			stops: process.env.NODE_ENV === 'production',
			// regionalTrain: false,
			// cityTrain: false,
			// cityRailway: false,
			// bus: false,
		},
		sharing: {
			regioRad: process.env.NODE_ENV === 'production',
			stella: false,
			car2go: false,
			flinkster: false,
			stadtMobil: false,
		},
		poi: {
			recreation: false,
			foodAndDrink: false,
			culture: false,
			landmarks: false,
			aroundBike: process.env.NODE_ENV === 'production',
			educationalInstitutions: false,
			hospitals: false,
			clubs: false,
		},
	}),
	mapType: 'GIS',
	date: new Date(),
	routes: process.env.NODE_ENV === 'development' ? getItem('ROUTES', []) : [],
	hoverRoute: null,
};
// if (process.env.NODE_ENV === 'development') {
// 	window.localStorage('POINTS', JSON.stringify(payload));
// }
