import {
	API_BASE,
	API_FREIZEITTIPPS_BASE,
	API_STOPFINDER_PARAMS,
	API_DEPARTURE_PARAMS,
	COORD_RADIUS,
	COORD_LIMIT,
	SHARING_API_BASE,
} from './fetchers/constants';

export const CONSTANTS = {
	API_BASE,
	API_FREIZEITTIPPS_BASE,
	API_STOPFINDER_PARAMS,
	API_DEPARTURE_PARAMS,
	COORD_RADIUS,
	COORD_LIMIT,
	SHARING_API_BASE,
};

export { default as fetchers } from './fetchers/fetchers';

export { default as attrsArrayToObject } from './attrsArrayToObject';
export { calculateDistance } from './calculateDistance';
export { default as checkObjectTree } from './checkObjectTree';
export { default as createGpx } from './createGpx';
export * from './dateFunctions';
export { default as download } from './download';
export { cloneObject } from './cloneObject';
export { escapeRegExp } from './escapeRegExp';
export { default as getCoordOfPoint } from './getCoordOfPoint';
export { getMinMaxCoordsOfRoute } from './getMinMaxCoordsOfRoute';
export { default as getObjectSubtree } from './getObjectSubtree';
export { default as getSearchLabelFromResult } from './getSearchLabelFromResult';
export { default as nameToIcon } from './nameToIcon';
export { default as replaceUmlauts } from './replaceUmlauts';

export { epsg4326, epsg3857, toEpsg4326, toEpsg3857 } from './projection';

export { default as setDataAttributes } from './setDataAttributes';

export { sizes, default as breakpoints } from './Breakpoints';

export { default as removeDuplicateStrings } from './removeDuplicateStrings';

export { mapConfig, colors, fonts, boxshadow } from './config';

export { default as createMarker } from './createMarker';

export { default as getLayerByName } from './getLayerByName';
