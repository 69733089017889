export default function download(
	content,
	type = 'application/javascript',
	filename = 'download.js',
) {
	const el = document.createElement('a');
	el.href = `data:${type};charset=utf-8,${encodeURIComponent(content)}`;
	el.rel = 'noopener';
	el.download = filename;

	try {
		el.dispatchEvent(new MouseEvent('click'));
	} catch (e) {
		const evt = document.createEvent('MouseEvents');
		evt.initMouseEvent(
			'click',
			true,
			true,
			window,
			0,
			0,
			0,
			80,
			20,
			false,
			false,
			false,
			false,
			0,
			null,
		);
		el.dispatchEvent(evt);
	}
}
