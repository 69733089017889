import React from 'react';
import PropTypes from 'prop-types';

export default function HighLight({ haystack, needle }) {
	return haystack.split(needle).reduce((reduction, split, index) => {
		if (!split) return reduction;

		// eslint-disable-next-line react/no-array-index-key
		return [...reduction, needle.test(split) ? <strong key={index}>{split}</strong> : split];
	}, []);
}

HighLight.prototype = {
	haystack: PropTypes.string.isRequired,
	needle: PropTypes.instanceOf(RegExp).isRequired,
};
