import { API_BASE } from './constants';

let sharingCacheId = '';
let sharingResponse;

/**
 * Fetch sharing stations in the given area
 *
 * @name fetchSharingStations
 * @function
 * @param {double|string} lat1 The latitude value of the bottom left corner.
 * @param {double|string} lng1 The longitude value of the bottom left corner.
 * @param {double|string} lat2 The latitude value of the top right corner.
 * @param {double|string} lng2 The longitude value of the top right corner.
 * @param {object[]} operators A list of operators to fetch stations from.
 * @param {object} [fetchOptions={}] Options for the fetch request
 * @return {object} Object with stopId as key and an array with the keys "accessible" (boolean) and "map" (string/url).
 */
export default async (
	lat1,
	lng1,
	lat2,
	lng2,
	operators = [],
	distance = true,
	fetchOptions = {},
) => {
	const operatorsParam = operators
		.map(operator => `operators[]=${operator.pId}-${operator.oAId}`)
		.join('&');
	const cacheId = `${lat1.toFixed(5)}|${lng1.toFixed(5)}|${lat2.toFixed(5)}|${lng2.toFixed(
		5,
	)}|${operatorsParam}|${distance ? '1' : '0'}`;

	if (cacheId !== sharingCacheId) {
		sharingCacheId = cacheId;
		sharingResponse = [];

		// eslint-disable-next-line no-param-reassign
		fetchOptions.cache = fetchOptions.cache || 'force-cache';

		const response = await fetch(
			`${API_BASE}sharing/stations-area?lat1=${lat1}&lng1=${lng1}&lat2=${lat2}&lng2=${lng2}&${operatorsParam}&distance=${
				distance ? '1' : '0'
			}&${window.additionalApiParams}`,
			fetchOptions,
		);
		sharingResponse = await response.json();
	}
	return sharingResponse;
	// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
};
