import { API_BASE } from './constants';

let cachedTime = 0;
let positionResponse;
let lastJson = [];
let lastLength = 0;

/**
 * Fetch bus and train positions in a given area
 *
 * @name fetchPositions
 * @function
 * @param {object} extent The area/extend used for the search of positions.
 * @param {object} positionsFilter Search for the given types only.
 * @param {object} [fetchOptions={}] Options for the fetch request
 * @return {object[]} Returns an array of objects with data to the positions etc.
 */
export default (extent, positionsFilter, fetchOptions = {}) => {
	let modCodes = '';
	if (positionsFilter['R-Bahn'] > 0 || positionsFilter['R-Bahn'] > 0) {
		modCodes += 'modCodes[]=0&';
	}
	if (positionsFilter['S-Bahn'] > 0 || positionsFilter['S-Bahn'] > 0) {
		modCodes += 'modCodes[]=1&';
	}
	if (positionsFilter.Stadtbahn > 0 || positionsFilter['Stadtbahn'] > 0) {
		modCodes += 'modCodes[]=3&';
	}
	if (positionsFilter.Bus > 0 || positionsFilter['Bus'] > 0) {
		modCodes += 'modCodes[]=5&';
	}
	if (modCodes === '') {
		return [];
	}
	const ts = Date.now();
	const cacheTime = Math.round(ts / 10) * 10;

	if (cacheTime !== cachedTime) {
		// eslint-disable-next-line no-param-reassign
		fetchOptions.cache = fetchOptions.cache || 'force-cache';

		cachedTime = cacheTime;
		positionResponse = fetch(
			`${API_BASE}livepositions?latMin=${extent.bottom}&lonMin=${extent.left}&latMax=${extent.top}&lonMax=${extent.right}&${modCodes}ts=${ts}&${window.additionalApiParams}`,
			fetchOptions,
		)
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.length > 0) {
					lastJson = json;
					let newLength = json.length;
					lastLength = newLength;
					return json;
				} else {
					return lastJson;
				}
			})
			.catch(function (error) {
				console.log('error fetching positions', error);
				return lastJson;
			});
	}
	return positionResponse;
};
