import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import Feature from 'ol/src/Feature';
import Point from 'ol/src/geom/Point';
import VectorSource from 'ol/src/source/Vector';
import { fromLonLat } from 'ol/src/proj';

import {
	SidebarHeader,
	colors,
	fetchers,
	download,
	replaceUmlauts,
	// minutesToDurationString,
	OutlineButton,
} from 'shared';

import { Start, Destination, Bike, Next } from '../../../assets/icons';
import Context from '../../context';
import {
	createMarker,
	distanceToString,
	drawBikePathOnMap,
	getOrCreateLinesLayer,
	getLayerByName,
	mapRoot,
	updateUri,
} from '../../../utils';
import ElevationProfile from './ElevationProfile';
// import RoutePath from './RoutePath';
import createGpx from './createGpx';

const Content = styled.div`
	padding: 1rem;

	button ~ button {
		margin-top: 0.5rem;
	}
`;

const RouteContent = styled.div`
	position: relative;
	display: grid;
	-ms-grid-columns: 24px 1fr;
	grid-template-columns: 24px 1fr;

	&::before {
		content: '';
		z-index: -1;
		display: block;
		position: absolute;
		top: 12px;
		left: 10px;
		bottom: 12px;

		border-left-width: 0.25rem;

		border-left-color: #e6e6e6;
		border-left-style: dotted;
	}
`;

const RouteTypeHeadline = styled.h3`
	margin-bottom: 0.2rem;
`;

const RouteTypeError = styled.h3`
	margin-bottom: 0.2rem;
	padding-left: 20px;
	overflow: hidden;
	position: relative;

	&::before {
		content: '×';
		color: #f00;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
	}
`;

const RouteDetails = styled.div`
	color: ${colors.graydark};
	display: flex;
	font-size: 0.9rem;
	flex-direction: row;
	/* margin-top: 0.5rem; */
	* ~ * {
		margin-left: 0.66rem;
	}
`;

const Line = styled.hr`
	border: 0px solid #dfdfdf;
	border-bottom-width: 1px;
	margin: 1.5rem 0;
`;

const StopWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;
	margin: 0 0 1.5rem 0.5rem;
	height: 24px;
`;

const StopName = styled.span`
	font-size: 1.1rem;
	align-self: center;
`;

const SvgWrapper = styled.div`
	svg {
		width: 24px;
		height: 24px;
		transform: scale(${({ scale }) => scale || 1});
	}
`;

const RoutesNav = styled.nav`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
`;

const StyledButton = styled.div`
	svg {
		transform: rotate(${({ isPrev }) => (isPrev ? -180 : 0)}deg);
	}

	${({ disabled }) =>
		disabled
			? css`
				opacity: 0.5;
				pointer-events: none;
			  `
			: css`
				&:hover {
					cursor: pointer;
				}
			  `}
`;

function drawStartAndEnd(pathCoords, setPointMarkers) {
	const start = pathCoords[0].slice(0, 2);
	const end = pathCoords[pathCoords.length - 1].slice(0, 2);

	const pointMarkers = [];

	if (start[0] !== end[0] && start[1] !== end[1]) {
		const endMarker = createMarker(
			{ coord: end },
			{
				type: 'routepoint',
				state: 3,
				label: 'B',
			},
		);

		pointMarkers.push(endMarker);
	}

	const startMarker = createMarker(
		{ coord: start },
		{
			type: 'routepoint',
			state: 3,
			label: 'A',
		},
	);

	pointMarkers.unshift(startMarker);

	setPointMarkers(pointMarkers);
}

export default function BikeRouteDetails({ id, details, meta, setActiveId, onBack }) {
	const { t } = useTranslation();
	const { map, setPointMarkers } = useContext(Context);
	const [route, setRoute] = useState(null);
	const [error, setError] = useState(false);
	// const [showUrl, setShowUrl] = useState(false);

	useEffect(() => {
		if (map && id) {
			setRoute(false);
			setError(false);
			fetchers
				.fetchThemeRoutes(id)
				.then(newRoute => {
					if (newRoute.pathCoord && newRoute.pathCoord.path) {
						// eslint-disable-next-line no-param-reassign
						newRoute.pathCoord = newRoute.pathCoord.path.split(' ').map(coords => {
							const rCoords = coords.split(',');
							const orderedCoords = [parseFloat(rCoords[1]), parseFloat(rCoords[0])];
							if (typeof rCoords[2] !== 'undefined') {
								orderedCoords.push(parseInt(rCoords[2], 10));
							}
							return orderedCoords;
						});
					}

					setRoute(newRoute);
					setError(false);

					const boundSources = new VectorSource();
					let hasEntries = false;
					if (Array.isArray(newRoute.pathCoord)) {
						newRoute.pathCoord.forEach(coord => {
							hasEntries = true;
							boundSources.addFeature(
								new Feature(new Point(fromLonLat([coord[1], coord[0]]))),
							);
						});
					}

					if (map && hasEntries) {
						drawBikePathOnMap(map, newRoute.pathCoord);

						drawStartAndEnd(newRoute.pathCoord, setPointMarkers);

						map.getView().fit(boundSources.getExtent(), {
							duration: 800,
							size: map.getSize(),
							padding: [50, 50, 50, 50],
						});
					}
				})
				.catch(e => {
					if (e instanceof Error && e.message === 'No route found.') {
						setError('error.routeNotFound');
					} else {
						setError('error.fetching');
					}
				});
			return () => {
				if (map) {
					const lineLayer = getOrCreateLinesLayer(map, 'lines');
					lineLayer.setSource(new VectorSource());

					const markerLayer = getLayerByName(map, 'markers');
					markerLayer.setSource(new VectorSource());

					setPointMarkers([]);
				}
			};
		}

		return () => {};
	}, [id, map, setPointMarkers]);

	// useEffect(() => {
	// 	if (route !== null && route.attrs.Internetlink) {
	// 		fetchers
	// 			.checkUrl(route.attrs.Internetlink)
	// 			.then(json => {
	// 				if (json.success && [404, 410].indexOf(json.data.statusCode) < 0) {
	// 					setShowUrl(true);
	// 				}
	// 			})
	// 			// Ignore errors
	// 			.catch(() => {});
	// 	}
	// });

	return (
		<>
			<SidebarHeader
				backgroundColor={colors.white}
				backButtonColor={colors.cyandark}
				onBack={onBack}
				isSmall
			/>

			<Content>
				{!error && !route && <RouteTypeHeadline>{t('common.fetching')}</RouteTypeHeadline>}
				{error && (
					<>
						<RouteTypeError>{t('error.headline')}</RouteTypeError>
						<p>{t(error)}</p>
					</>
				)}
				{route && details && (
					<>
						<RouteTypeHeadline>
							{route?.name || t('myRoutes.headline')}
						</RouteTypeHeadline>
						{details && (
							<RouteDetails>
								<span>{details.distance} km</span>
								<span>⬆ {distanceToString(details.up, false)}</span>
								<span>⬇ {distanceToString(details.down, false)}</span>
							</RouteDetails>
						)}
						<Line />

						<RouteContent>
							{route?.attrs?.Startort && (
								<>
									<SvgWrapper scale="1.75">
										<Start />
									</SvgWrapper>
									<StopName>{route.attrs.Startort}</StopName>
								</>
							)}

							{details && (
								<>
									<SvgWrapper>
										<Bike />
									</SvgWrapper>

									<StopWrapper>
										<StopName>
											{t('routePlanning.bike', {
												distance: distanceToString(
													parseInt(details.distance, 10) * 1000,
												),
											})}
										</StopName>
									</StopWrapper>
								</>
							)}

							{typeof route.pathDesc.turnInst !== 'undefined' && (
								<ElevationProfile data={route.pathCoord} />
							)}
							{route?.attrs?.Zielort && (
								<>
									<SvgWrapper scale="1.75">
										<Destination />
									</SvgWrapper>
									<StopName>{route.attrs.Zielort}</StopName>
								</>
							)}
						</RouteContent>
					</>
				)}
				<Line />
			</Content>
			{route && (route.attrs.pdf || typeof route.pathCoord !== 'undefined') && (
				<Content>
					{route.attrs.pdf && (
						<OutlineButton
							type="button"
							onClick={() => {
								window.open(
									`http://www.vvs.de/download/Themenrouten/${route.attrs.pdf}`,
									'_blank',
								);
							}}
						>
							{t('bikeRoutes.downloadPdf')}
						</OutlineButton>
					)}

					{typeof route.pathCoord !== 'undefined' && (
						<OutlineButton
							type="button"
							onClick={() => {
								download(
									createGpx(route, 'VVS Radroutenplaner'),
									'application/javascript',
									`Themenroute-${replaceUmlauts(route.name)}.gpx`,
								);
							}}
						>
							{t('routePlanning.downloadGpx')}
						</OutlineButton>
					)}
					<Line />

					{meta && (
						<RoutesNav>
							<StyledButton
								onClick={() => {
									if (meta.prevRoute) {
										setActiveId(meta.prevRoute);
										updateUri(false, `${mapRoot}/radrouten/${meta.prevRoute}`);
									}
								}}
								disabled={meta.prevRoute === null}
								isPrev
							>
								<Next />
							</StyledButton>
							{meta.page} / {meta.routesCount}
							<StyledButton
								onClick={() => {
									if (meta.nextRoute) {
										setActiveId(meta.nextRoute);
										updateUri(false, `${mapRoot}/radrouten/${meta.nextRoute}`);
									}
								}}
								disabled={meta.nextRoute === null}
							>
								<Next />
							</StyledButton>
						</RoutesNav>
					)}
				</Content>
			)}
		</>
	);
}

BikeRouteDetails.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	details: PropTypes.object,
	meta: PropTypes.object,
	setActiveId: PropTypes.func,
	onBack: PropTypes.func,
};

BikeRouteDetails.defaultProps = {
	setActiveId: () => {},
	onBack: () => {},
};
