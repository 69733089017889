import React from 'react';
import PropTypes from 'prop-types';

import { calculateDistance } from 'shared';
import CommonElevationProfile from '../../CommonElevationProfile';

/**
 *
 * @param {[]}} coords
 * @returns {[]}
 */
function transform(coords) {
	let lastZ = coords.find(coord => coord.length === 3);
	if (!lastZ) return false;
	lastZ = lastZ.pop();
	if (Number.isNaN(lastZ)) {
		lastZ = 0;
	}
	let distanceSum = 0;

	return coords.map((coord, index) => {
		const [lat, lon, elevation] = coord;
		const z = elevation || lastZ;

		if (index === 0) {
			return { lat, lon, z, distance: 0, distanceSum: 0 };
		}

		lastZ = z;
		const [lat2, lon2] = coords[index - 1];

		const distance = calculateDistance(lat, lon, lat2, lon2) * 1000;
		distanceSum += distance;
		return {
			lat,
			lon,
			z,
			distance: Math.round(distance),
			distanceSum: Math.round(distanceSum),
		};
	});
}

/**
 *
 * @param {object} props
 * @param {object[]} props.data the data to show
 * @param {number} props.width the width of the chart
 * @param {number} props.height the height of the chart
 *
 * @return {function}
 */
export default function ElevationProfile({ data, width, height }) {
	if (!data) return null;
	const generatedData = transform(data);
	if (generatedData === false) return null;

	return (
		<CommonElevationProfile
			data={generatedData}
			width={width}
			height={height}
			yAxisDataLabel="z"
			xAxisDataLabel="distanceSum"
		/>
	);
}

ElevationProfile.propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
	data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
};

ElevationProfile.defaultProps = {
	data: {},
	width: 0,
	height: 240,
};
