import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import defaultMap from '../../assets/maptypes/default.png';
import topologyMap from '../../assets/maptypes/topology.png';
import satelliteMap from '../../assets/maptypes/satellite.jpg';
import { colors } from '../../utils';
import media from '../../utils/Breakpoints';

const Wrapper = styled.div`
	border-bottom: 1px solid ${colors.graylight};
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: 16px 0 48px 0;
	padding: 0 32px 16px;

	${media.lg`
		padding: 0 48px 24px;
	`}
`;

const Title = styled.label`
	color: ${colors.graydark};
	display: block;
	padding: 2px 5px 5px;
`;

const Item = styled.div`
	border: 1px solid ${colors.graylight};
	border-radius: 6px;
	flex: 0 1 30%;
	overflow: hidden;

	&:hover {
		cursor: pointer;
	}

	> img {
		max-width: 100%;
	}

	${({ active }) =>
		active &&
		css`
			border-color: ${colors.cyandark};
			background-color: ${colors.cyandark};

			${Title} {
				color: ${colors.white};
			}
		`}
`;

export default function MapTypes({ activeItem, onChange }) {
	return (
		<Wrapper>
			<Item onClick={() => onChange('GIS')} active={activeItem === 'GIS' || !activeItem}>
				<img src={defaultMap} alt="Standard-Karte" />
				<Title>Standard</Title>
			</Item>
			<Item onClick={() => onChange('topkarteMRCV')} active={activeItem === 'topkarteMRCV'}>
				<img src={topologyMap} alt="Geländekarte" />
				<Title>Gelände</Title>
			</Item>
			<Item onClick={() => onChange('orthoMRCV')} active={activeItem === 'orthoMRCV'}>
				<img src={satelliteMap} alt="Satellitenkarte" />
				<Title>Satellit</Title>
			</Item>
		</Wrapper>
	);
}

MapTypes.propTypes = {
	activeItem: PropTypes.string,
	onChange: PropTypes.func,
};

MapTypes.defaultProps = {
	onChange: () => {},
};
