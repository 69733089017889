import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IconTaxi } from 'shared';

import {
	Footpath,
	Bike,
	Bus,
	Stadtbahn,
	SBahn,
	RegioRadLeg,
	RBahn,
	Zacke,
} from '../../assets/icons';
import next from '../../assets/icons/next-grey.svg';

export { default as TransportationWrapper } from './TransportationWrapper';

const Icons = {
	footpath: Footpath,
	Fahrrad: Bike,
	Bus,
	'SEV-Bus': Bus,
	Linientaxi: IconTaxi,
	Stadtbahn,
	'S-Bahn': SBahn,
	'R-Bahn': RBahn,
	RegioRad: RegioRadLeg,
	Zahnradbahn: Zacke,
};

const TransportationItem = styled.div`
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	flex-shrink: 0;
	padding-bottom: 0.2rem;

	> svg,
	&::after {
		width: 1.6rem;
		height: 1.6rem;
	}

	> svg {
		margin-right: 5px;
	}

	&::after {
		background: url(${next}) center center;
		content: '';
		display: block;
	}

	&:last-child::after {
		display: none;
	}
`;

const hiddenLegTypes = ['nicht einsteigen', 'gesicherter Anschluss'];

export default function Transportation({ leg }) {
	const productName = leg.transportation.product.name;
	const Icon = Icons[productName] || false;
	let suffix = '';

	if (hiddenLegTypes.indexOf(productName) > -1) {
		return null;
	}

	if (['Fahrrad', 'RegioRad', 'footpath'].indexOf(productName) > -1) {
		if (leg.distance < 1000) {
			suffix = `${leg.distance} m`;
		} else {
			suffix = `${(Math.round(leg.distance / 100) / 10).toLocaleString()} km`;
		}
	} else if (productName === 'footpath') {
		const duration = Math.ceil((leg.duration || leg.footPathInfo?.[0]?.duration || 0) / 60);
		if (duration > 60) {
			suffix = `${Math.floor(duration / 60)} Std `;
		}
		suffix += `${duration % 60} Min`;
	} else {
		suffix = leg.transportation.disassembledName;
	}

	return (
		<TransportationItem className={`product product--${productName}`}>
			{Icon ? <Icon /> : <span>{productName}</span>} {suffix}
		</TransportationItem>
	);
}

Transportation.propTypes = {
	leg: PropTypes.object.isRequired,
};
