import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Sidebar } from 'shared';

import MyRoutes, { MyRouteDetails } from './my-routes';
import BikeRoutes from './bike-routes';
import RoutePlanning from './route-planning';
import BikeSettings from './BikeSettings';
import PublicTransportSettings from './PublicTransportSettings';

import Context from './context';
import updateUri from '../utils/updateUri';
import { setMyTimeout } from '../utils/timeouts';
import ApiRoute from './ApiRoute';
import { mapRoot } from '../utils';
import ScrollContextProvider, { ScrollContext } from './ScrollContextProvider';

function LeftSidebar() {
	const { toggleLeftSidebar, isLeftSidebarOpen, setRoutePlanningActive } = useContext(Context);
	const { ref } = useContext(ScrollContext);

	const goBack = () => {
		toggleLeftSidebar(false);
		// wait for the animation, else the content be gone before the sidebar is hidden
		setMyTimeout(
			'leftSidebar',
			() => {
				updateUri(false, mapRoot);
				setRoutePlanningActive(true);
			},
			500,
		);
	};

	return (
		<Sidebar open={isLeftSidebarOpen} ref={ref} pushDown>
			<Switch>
				<Route
					path={`${mapRoot}/meine-routen/:id(@.+)?`}
					render={() => (
						<MyRouteDetails
							onBack={() => {
								updateUri(false, `${mapRoot}/meine-routen`);
								setRoutePlanningActive(false);
							}}
						/>
					)}
				/>

				<Route
					path={`${mapRoot}/meine-routen(@.+)?`}
					render={() => <MyRoutes onBack={goBack} />}
				/>

				<Route
					path={`${mapRoot}/radrouten(/:id)?(@.+)?`}
					render={() => <BikeRoutes onBack={goBack} />}
				/>

				<Route
					path={`${mapRoot}/rad-einstellungen(@.+)?`}
					render={() => (
						<BikeSettings
							onBack={() => {
								updateUri(false, `${mapRoot}/routenplanung`);
								setRoutePlanningActive(true);
							}}
						/>
					)}
				/>

				<Route
					path={`${mapRoot}/oepnv-einstellungen(@.+)?`}
					render={() => (
						<PublicTransportSettings
							onBack={() => {
								updateUri(false, `${mapRoot}/routenplanung`);
								setRoutePlanningActive(true);
							}}
						/>
					)}
				/>

				<Route
					path={`${mapRoot}/routenplanung(@.+)?`}
					render={() => <RoutePlanning onBack={goBack} />}
				/>

				<Route path={`${mapRoot}/r/:hash`} render={() => <ApiRoute />} />
			</Switch>
		</Sidebar>
	);
}

export default function LeftSidebarWithScrollContext() {
	return (
		<ScrollContextProvider>
			<LeftSidebar />
		</ScrollContextProvider>
	);
}
