import { useState, useEffect, useContext } from 'react';

import Context from '../components/context';

import { createMarker } from '../utils';

// const ol = window.OpenLayers;
// const size = new ol.Size(24, 24);
// const offset = new ol.Pixel(-(size.w / 2), 6 - size.h);

export default function useDrawLegsTransportationMarkers() {
	const { map, setMobilityIcons } = useContext(Context);
	const [legs, setLegs] = useState([]);

	useEffect(() => {
		if (map && legs) {
			const features = [];

			legs.forEach((leg, i) => {
				const { origin, destination, transportation } = leg;

				if (
					i > 0 &&
					transportation.product.name === legs[i - 1].transportation.product.name
				) {
					return;
				}

				features.push(
					createMarker({ coord: origin.coord }, { type: transportation.product.name }),
				);

				if (transportation.product.name !== 'footpath') {
					features.push(
						createMarker(
							{ coord: destination.coord },
							{ type: transportation.product.name },
						),
					);
				}
			});

			setMobilityIcons(features);
		}
		return () => {
			if (!map) return;
			setMobilityIcons([]);
		};
	}, [map, legs, setMobilityIcons]);

	return setLegs;
}
