import React from 'react';
import ReactDOM from 'react-dom';

import { toLonLat } from 'ol/src/proj';
import { getBottomRight, getTopLeft } from 'ol/src/extent';

import { fetchers, mapConfig, positionIcons, positionIconsRT } from 'shared';
import { createMarker } from '../../utils';

let positionsTimeout;
let positionFilters;

export const clearPositionsTimeout = () => {
	clearTimeout(positionsTimeout);
};

export const setPositionsTimeout = (map, filters, setPositionMarkers) => {
	clearPositionsTimeout();
	positionsTimeout = setTimeout(() => {
		updatePositionMarkers(map, filters, setPositionMarkers);
	}, 30000);
};

async function updatePositionMarkers(map, filters = {}, setPositionMarkers) {
	clearPositionsTimeout();

	positionFilters = {
		'R-Bahn': filters.regionalTrain ? 1 : 0,
		'S-Bahn': filters.cityTrain ? 1 : 0,
		Stadtbahn: filters.cityRailway ? 1 : 0,
		Bus: filters.bus ? 1 : 0,
	};

	if (map.getView().getZoom() < mapConfig.minZoomLevel.positions) {
		setPositionMarkers([]);
		return;
	}

	const extent = map.getView().calculateExtent();
	let positions;

	if (Object.keys(positionFilters).filter(entry => positionFilters[entry] > 0).length) {
		const topLeft = toLonLat(getTopLeft(extent));
		const bottomRight = toLonLat(getBottomRight(extent));
		positions = await fetchers.fetchPositions(
			{ top: topLeft[1], left: topLeft[0], bottom: bottomRight[1], right: bottomRight[0] },
			positionFilters,
		);

		setPositionsTimeout(map, filters, setPositionMarkers);
	} else {
		// Stop further processing, if filters are all disabled
		setPositionMarkers([]);
		return;
	}

	const newPositionMarkers = [];

	positions.forEach(position => {
		// if (typeof positionMarkers[position.id] !== 'undefined') {
		// 	const newLonLat = toEpsg3857(position.latitude, position.longitude);
		// 	const newPx = map.getLayerPxFromLonLat(newLonLat);
		// 	positionMarkers[position.id].moveTo(newPx);
		// 	const { imageDiv } = positionMarkers[position.id].icon;
		// 	[...imageDiv.querySelectorAll('[data-delay]')].forEach(delay => {
		// 		// eslint-disable-next-line no-param-reassign
		// 		delay.innerText = `+${position.delay}`;
		// 		delay.setAttribute('data-delay', position.delay);
		// 	});
		// 	imageDiv.setAttribute('data-delay', position.delay);
		// 	return;
		// }

		const iconName = position.type.replace(/-/g, '').toLowerCase();
		let iconSrc = null;
		if (position.realtime) {
			if (positionIconsRT[iconName] !== undefined) {
				iconSrc = positionIconsRT[iconName];
			}
		} else if (positionIcons[iconName] !== undefined) {
			iconSrc = positionIcons[iconName];
		}

		const marker = createMarker(
			{ coord: [position.latitude, position.longitude] },
			{
				type: 'position',
				src: iconSrc,
				canHover: false,
				title: `${position.line} - ${position.direction}`,
				locationtype: 'position',
				positiontype: position.type,
				delay: position.delay,
				state: 2,
				vvsId: `position-${position.id}`,
				onHover: () => {
					const tooltip = document.getElementById('tooltip');

					ReactDOM.render(
						<div className="marker__tooltip marker__tooltip--position">
							<div className="position__info">
								{position.line} - {position.direction}
							</div>
							<div className="orange" data-delay={position.delay}>
								+{position.delay}
							</div>
						</div>,
						tooltip,
					);

					map.getOverlayById('tooltip').setPosition(
						marker.getGeometry().getCoordinates(),
					);
					tooltip.style.display = '';
				},
			},
		);

		// if (position.delay > 0) {
		// 	const delay = document.createDocumentFragment();
		// 	ReactDOM.render(
		// 		<div className="marker__delay" data-delay={position.delay}>
		// 			+{position.delay}
		// 		</div>,
		// 		delay,
		// 	);

		// 	document.body.appendChild(delay);
		// }


		newPositionMarkers.push(marker);

		// const tooltip = document.createDocumentFragment();

		// ReactDOM.render(
		// 	<div className="marker__tooltip">
		// 		<div className="position__info">
		// 			{position.line} - {position.direction}
		// 		</div>
		// 		<div className="orange" data-delay={position.delay}>
		// 			+{position.delay}
		// 		</div>
		// 	</div>,
		// 	tooltip,
		// );
		// ReactDOM.render(
		// 	<div className="marker__delay" data-delay={position.delay}>
		// 		+{position.delay}
		// 	</div>,
		// 	delay,
		// );

		// const { imageDiv } = marker.icon;
		// marker.vvsType = 'position';
		// marker.vvsPositionType = position.type;
		// marker.vvsId = `position-${position.id}`;

		// imageDiv.classList.add('marker');
		// imageDiv.classList.add('marker--position');

		// setDataAttributes(imageDiv, {
		// 	title: `${position.line} - ${position.direction}`,
		// 	locationtype: 'position',
		// 	type: position.type,
		// 	delay: position.delay,
		// 	state: 2,
		// 	vvsid: `position-${position.id}`,
		// });
		// imageDiv.style.zIndex = 12;
		// imageDiv.appendChild(tooltip);
		// imageDiv.appendChild(delay);

		// markerLayer.addMarker(marker);
		// positionMarkers[position.id] = marker;
	});

	setPositionMarkers(newPositionMarkers);
}

export default updatePositionMarkers;
