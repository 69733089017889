import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import VectorSource from 'ol/src/source/Vector';

import { colors, CONSTANTS, SidebarHeader, TextBlockLoadingIndicator } from 'shared';

import Context from '../context';

import {
	drawRouteOnMap,
	getOrCreateLinesLayer,
	getParamsByHash,
	mapRoot,
	updateUri,
} from '../../utils';
import RouteDetails from '../route-planning/route-details';

const HeaderContent = styled.div`
	padding: 1rem 1rem 1rem 4rem;
	width: 100%;
`;

const Content = styled.div`
	padding: 1rem;
	button ~ button {
		margin-top: 0.5rem;
	}
`;

export default function ApiRoute() {
	const { location } = useHistory();
	const { map, setPoints, setRoutes, toggleLeftSidebar } = useContext(Context);
	const [route, setRoute] = useState(false);

	const hash = location.pathname
		.split('/')
		.pop()
		.split('@')
		.shift();

	const storedHash = useRef();

	useEffect(() => {
		if (map && hash && storedHash.current !== hash) {
			storedHash.current = hash;
			getParamsByHash(hash).then(store => {
				if (store.success) {
					// origin, desination
					const points = [store.data.options?.origin];
					if (store.data.options?.vias?.length > 0) {
						store.data.options.vias.forEach(via => {
							points.push(via);
						});
					}
					points.push(store.data.options?.destination);

					setPoints(points, false);

					const query = Object.keys(store.data.params)
						.map(key => `${key}=${encodeURIComponent(store.data.params[key])}`)
						.join('&');

					const lineLayer = getOrCreateLinesLayer(map, 'lines');
					lineLayer.setSource(new VectorSource());

					fetch(`${CONSTANTS.API_BASE}XSLT_TRIP_REQUEST2?${query}`)
						.then(response => response.json())
						.then(json => {
							if (json.journeys && json.journeys.length > 0) {
								return json.journeys[0];
							}
							throw new Error('No route found for the given hash.');
						})
						.then(newRoute => {
							// eslint-disable-next-line no-param-reassign
							newRoute.routeType = store.data.options?.routeType || 'hash';
							drawRouteOnMap(map, newRoute, newRoute.routeType);
							setRoute(newRoute);
						})
						.catch(() => {
							updateUri(false, mapRoot);
							toggleLeftSidebar(false);
						});
				} else {
					updateUri(false, mapRoot);
					toggleLeftSidebar(false);
				}
			});
		}
	}, [map, hash, setPoints, toggleLeftSidebar]);

	if (route !== false) {
		return (
			<RouteDetails
				route={route}
				// customPoints={[route.legs[0].origin, route.legs[route.legs.length - 1].destination]}
				onBack={() => {
					setRoutes([]);
					setPoints([]);
					updateUri(false, mapRoot);
					toggleLeftSidebar(false);
				}}
				changeUri={false}
			/>
		);
	}

	return (
		<>
			<SidebarHeader
				backgroundColor={colors.white}
				onBack={() => {
					updateUri(false, mapRoot);
				}}
				backButtonColor={colors.cyandark}
				isSmall
			>
				<HeaderContent></HeaderContent>
			</SidebarHeader>
			<Content>
				<TextBlockLoadingIndicator rows={6} />
				<TextBlockLoadingIndicator rows={2} />
				<TextBlockLoadingIndicator rows={2} />
				<TextBlockLoadingIndicator rows={2} />
				<TextBlockLoadingIndicator rows={2} />
				<TextBlockLoadingIndicator rows={2} />
				<TextBlockLoadingIndicator rows={2} />
			</Content>
		</>
	);
}
