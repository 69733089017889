/* eslint-disable no-unused-expressions */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { fetchers } from '../../utils';

import { SearchBar, Input, SearchBarIcon, SearchLoading } from './SearchBar';
import SearchResultList from './SearchResultList';

import escapeRegExp from '../../utils/escapeRegExp';
// import { searchCategories, nameToIcon } from '../../utils/config';

// import MarkerContext from '../../contexts/MarkerContext';

import { ReactComponent as VvsIcon } from '../../assets/icons/vvs-logo.svg';
import { ReactComponent as Loupe } from '../../assets/icons/loupe.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { addItem, getItems } from './cache';
import { getRoute, ROUTE_CACHE_KEY } from './route-cache';

// import { CategoryListWrapper } from '../CategoryList';

const StyledSearch = styled.div`
	flex-grow: 1;
	opacity: 1;
	position: relative;
	max-width: 100%;
	z-index: ${({ zIndex }) => zIndex};

	transition: opacity 0.5s ease;

	${({ isHidden }) =>
		isHidden &&
		css`
			opacity: 0;
			pointer-events: none;
		`}

	.vvs-logo {
		height: 32px;
		width: auto;
		margin: 0 0.5rem;
	}
`;
// &.active ~ ${CategoryListWrapper} {
// 	display: none;
// }

class Search extends Component {
	constructor(props) {
		super(props);

		this.value = props.value;

		this.state = {
			active: 0,
			loading: false,
			results: [],
			scrollToActiveItem: false,
		};

		this.searchWrapperRef = createRef();
		this.searchInputRef = createRef();

		this.blurTimeout = null;
	}

	componentDidMount() {
		if (this.props.focusPull) this.searchInputRef.current.focus();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.value !== prevProps.value) {
			this.value = this.props.value;
			this.searchInputRef.current.value = this.props.value;
		}
		if (prevState.active !== this.state.active && this.state.scrollToActiveItem) {
			const resultList = this.searchWrapperRef.current.querySelector('.result-list');
			if (resultList === null) return;
			const activeItem = resultList.querySelector('.active');
			if (activeItem === null) return;
			const { offsetTop } = activeItem;
			resultList.scrollTo({
				top: offsetTop - this.props.scrollOffset,
				behavior: 'smooth',
			});
		}
	}

	componentWillUnmount() {
		clearTimeout(this.blurTimeout);
	}

	handleSearchChange = event => {
		const { value } = event.target;
		this.value = value;

		if (this.abortController) {
			this.abortController.abort();
		}
		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			if (value === '') {
				this.setState({ results: [], loading: false });
				return;
			}
			let signal;
			if ('AbortController' in window) {
				this.abortController = new window.AbortController();
				signal = this.abortController.signal;
			}
			let results = [];
			// const searchRegex = new RegExp(escapeRegExp(value), 'i');

			// if (value.length > 2) {
			// 	Object.keys(searchCategories).forEach(searchCategory => {
			// 		const labels = [searchCategories[searchCategory].label]
			// 			.concat(searchCategories[searchCategory].customLabels || [])
			// 			.filter(label => !!label.match(searchRegex));
			// 		if (labels.length > 0) {
			// 			const result = {
			// 				...searchCategories[searchCategory],
			// 				shownLabel: labels[0],
			// 				type: 'category',
			// 			};
			// 			results.push(result);
			// 		}
			// 	});
			// }
			this.setState({ results, loading: true });
			fetchers
				.searchLocationsAndTipps(value, { signal })
				.then(response => {
					const { resultsFilter } = this.props;
					results = results.concat(response.locations).concat(response.tipps || []);
					if (resultsFilter) {
						results = results.filter(resultsFilter);
					}
					this.setState({ results, loading: false });
					return results;
				})
				.catch(error => {
					if (error.name === 'AbortError') {
						// Fetch aborted
					} else {
						// eslint-disable-next-line no-console
						console.error('Uh oh, an error!', error);
						this.setState({ results, loading: false });
					}
				});
		}, 300);
	};

	handleIconKeyDown = e => {
		if (this.value) {
			if (e.key === 'Enter' || e.key === ' ' || e.keyCode === 13 || e.keyCode === 32) {
				this.handleDeleteInput();
				e.target.blur();
			}
		}
	};

	handleDeleteInput = () => {
		if (this.abortController) {
			this.abortController.abort();
		}
		clearTimeout(this.searchTimeout);

		this.setState({ results: [], loading: false, active: 0 });
		this.value = '';
		// this.context.setContext({ filters: null, marker: null });
		this.searchInputRef.current.value = '';
		this.searchInputRef.current.focus();

		this.props.onCategorySelect(null);
		this.props.onClear();
	};

	handleKeyDown = e => {
		let handle = false;
		if (e.key !== undefined) {
			if (e.key === 'Enter') {
				handle = true;
				this.selectActiveResult();
			} else if (e.key === 'ArrowUp') {
				handle = true;
				this.highlightPreviousResult();
			} else if (e.key === 'ArrowDown') {
				handle = true;
				this.highlightNextResult();
			} else if (e.key === 'Escape') {
				handle = true;
				this.handleDeleteInput();
			}
		} else if (e.keyCode !== undefined) {
			if (e.keyCode === 13) {
				handle = true;
				this.selectActiveResult();
			} else if (e.keyCode === 38) {
				handle = true;
				this.highlightPreviousResult();
			} else if (e.keyCode === 40) {
				handle = true;
				this.highlightNextResult();
			} else if (e.keyCode === 27) {
				handle = true;
				this.handleDeleteInput();
			}
		}

		if (handle) {
			e.preventDefault();
		}
	};

	selectActiveResult = () => {
		const { results, active } = this.state;
		if (results.length > 0) {
			this.selectResult(results[active]);
			this.searchInputRef.current.blur();
		}
	};

	highlightPreviousResult = () => {
		const { results, active } = this.state;
		const { length } = results;
		this.setState({
			active: (active + length - 1) % length,
			scrollToActiveItem: true,
		});
	};

	highlightNextResult = () => {
		const { results, active } = this.state;
		const { length } = results;
		this.setState({
			active: (active + length + 1) % length,
			scrollToActiveItem: true,
		});
	};

	highlightResult = active => {
		this.setState({ active, scrollToActiveItem: false });
	};

	selectResult = selectedResult => {
		const { onSelect, getInputValueFromResult /* , onCategorySelect */ } = this.props;

		if (this.abortController) {
			this.abortController.abort();
		}
		clearTimeout(this.searchTimeout);
		this.setState({ loading: false, results: [], active: 0 });

		const value = getInputValueFromResult(selectedResult);
		this.searchInputRef.current.value = value;
		this.value = value;

		if (selectedResult.id !== ROUTE_CACHE_KEY) addItem(selectedResult);

		// if (selectedResult.type === 'category') {
		// 	this.searchInputRef.current.value = selectedResult.shownLabel;
		// 	this.value = selectedResult.shownLabel;
		// 	this.setState({
		// 		results: [],
		// 		active: 0,
		// 	});
		// 	this.context.setContext({ marker: null, filters: selectedResult.filter });
		// 	onCategorySelect(selectedResult);
		// 	return;
		// }

		// if (selectedResult.type === 'tip') {
		// 	this.searchInputRef.current.value = searchCategories.tipps.label;
		// 	this.value = searchCategories.tipps.label;
		// 	this.setState({ results: [], active: 0 });
		// 	const resultId = nameToIcon(
		// 		`${selectedResult.id || 0}-${selectedResult.title || 'notitle'}`,
		// 		true,
		// 	);
		// 	// eslint-disable-next-line no-param-reassign
		// 	selectedResult.id = resultId;
		// 	this.context.setContext({
		// 		marker: selectedResult,
		// 		filters: searchCategories.tipps.filter,
		// 	});
		// } else if (selectedResult.type === 'stop') {
		// 	this.searchInputRef.current.value = searchCategories.stops.label;
		// 	this.value = searchCategories.stops.label;
		// 	this.setState({ results: [], active: 0 });
		// 	this.context.setContext({
		// 		marker: selectedResult,
		// 		filters: searchCategories.stops.filter,
		// 	});
		// } else if (
		// 	selectedResult.type === 'suburb' ||
		// 	selectedResult.type === 'street' ||
		// 	selectedResult.type === 'singlehouse'
		// ) {
		// 	const value = selectedResult.disassembledName
		// 		? `${selectedResult.disassembledName}, ${selectedResult.parent.name}`
		// 		: selectedResult.name;
		// 	this.searchInputRef.current.value = value;
		// 	this.value = value;
		// 	this.setState({ results: [], active: 0 });
		// 	this.context.setContext({ marker: selectedResult, filters: null });
		// } else if (selectedResult.type === 'poi') {
		// 	this.searchInputRef.current.value = searchCategories.pois.label;
		// 	this.value = searchCategories.pois.label;
		// 	const marker = {
		// 		...selectedResult,
		// 		id: selectedResult.id
		// 			.split(':')
		// 			.slice(0, 3)
		// 			.join(':')
		// 			.concat(':-1'),
		// 	};
		// 	this.setState({
		// 		results: [],
		// 		active: 0,
		// 	});
		// 	this.context.setContext({
		// 		marker,
		// 		filters: searchCategories.pois.filter,
		// 	});
		// 	fetchers.fetchPOIById(marker.id).then(poi => {
		// 		if (poi !== null) {
		// 			const poiCategory = nameToIcon(poi.category);
		// 			if (searchCategories[`pois-${poiCategory}`]) {
		// 				this.context.setFilters(searchCategories[`pois-${poiCategory}`].filter);
		// 			}
		// 		}
		// 	});
		// } else {
		// 	return;
		// }

		this.searchInputRef.current.blur();

		if (onSelect) {
			onSelect(selectedResult);
		}
	};

	/* eslint-disable lines-between-class-members */
	abortController = null;
	searchTimeout = null;
	searchWrapperRef = null;
	value = '';
	/* eslint-enable lines-between-class-members */

	getSearchResultItems() {
		const { hasFocus, results } = this.state;
		const { showRoute } = this.props;

		if (!hasFocus) return [];

		let route = null;
		if (showRoute) {
			route = getRoute();
		}

		if (!this.value) {
			if (route) {
				return [route];
			}
			return [];
		}

		const items = getItems(this.value.toLowerCase());
		const itemIds = items.map(({ id }) => id);

		const filteredResults = results.filter(({ id }) => !itemIds.includes(id));

		if (route) {
			return [route, ...items, ...filteredResults];
		}
		return [...items, ...filteredResults];
	}

	render() {
		const { active, loading, hasFocus } = this.state;
		const { value } = this;
		const {
			id,
			autoFocus,
			placeholder,
			positionrelative,
			searchResultsRelative,
			slimLayout,
			categoryMenuVisible,
			isHidden,
			action: Action,
			onBlur,
			zIndex,
			subtleBoxShadow,
			boxShadowOnlyOnInteraction,
		} = this.props;
		const searchRegex = new RegExp(`(${escapeRegExp(value)})`, 'gi');

		const icon = value ? <Delete /> : <Loupe />;

		return (
			<StyledSearch
				className={autoFocus ? 'active' : ''}
				isHidden={isHidden}
				zIndex={zIndex}
				ref={this.searchWrapperRef}
			>
				<SearchBar
					positionrelative={positionrelative}
					categoryMenuVisible={categoryMenuVisible}
					searchResultListVisible={hasFocus && this.getSearchResultItems().length > 0}
					slimLayout={slimLayout}
					subtleBoxShadow={subtleBoxShadow}
					boxShadowOnlyOnInteraction={boxShadowOnlyOnInteraction}
				>
					{!slimLayout && <VvsIcon className="vvs-logo" />}
					<Input
						type="text"
						// id={id}
						placeholder={placeholder}
						defaultValue={value}
						onFocus={() => {
							clearTimeout(this.blurTimeout);
							this?.searchWrapperRef?.current?.classList?.add('active');
							this.setState({ hasFocus: true });
						}}
						onBlur={() => {
							this.blurTimeout = setTimeout(() => {
								this?.searchWrapperRef?.current?.classList?.remove('active');
								this.setState({ hasFocus: false });
								onBlur(this.value);
							}, 250);
						}}
						onKeyDown={this.handleKeyDown}
						onChange={this.handleSearchChange}
						ref={this.searchInputRef}
						autoComplete="off"
						autoFocus={autoFocus}
						slimLayout={slimLayout}
					/>
					{loading && <SearchLoading slimLayout={slimLayout} />}
					{(!slimLayout || value) && hasFocus && (
						<SearchBarIcon
							tabIndex={0}
							onKeyDown={this.handleIconKeyDown}
							onClick={this.handleDeleteInput}
							slimLayout={slimLayout}
						>
							{icon}
						</SearchBarIcon>
					)}
					{!hasFocus && Action && (
						<SearchBarIcon tabIndex={0} slimLayout={slimLayout}>
							<Action />
						</SearchBarIcon>
					)}
				</SearchBar>
				<SearchResultList
					keyPrefix={id}
					className="result-list"
					items={this.getSearchResultItems()}
					active={active}
					searchRegex={searchRegex}
					onSelect={this.selectResult}
					setActive={newActive => this.highlightResult(newActive)}
					positionrelative={positionrelative}
					searchResultsRelative={searchResultsRelative}
					slimLayout={slimLayout}
				/>
			</StyledSearch>
		);
	}
}

// Search.contextType = MarkerContext;

Search.propTypes = {
	id: PropTypes.string,
	autoFocus: PropTypes.bool,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	scrollOffset: PropTypes.number,
	positionrelative: PropTypes.bool,
	searchResultsRelative: PropTypes.bool,
	showRoute: PropTypes.bool,
	action: PropTypes.func,
	slimLayout: PropTypes.bool,
	isHidden: PropTypes.bool,
	// searchResultListVisible: PropTypes.bool,
	categoryMenuVisible: PropTypes.bool,
	onCategorySelect: PropTypes.func,
	onSelect: PropTypes.func,
	onClear: PropTypes.func,
	onBlur: PropTypes.func,
	getInputValueFromResult: PropTypes.func,
	resultsFilter: PropTypes.func,
	zIndex: PropTypes.number,
	subtleBoxShadow: PropTypes.bool,
	boxShadowOnlyOnInteraction: PropTypes.bool,
	focusPull: PropTypes.bool,
};

Search.defaultProps = {
	id: 'searchinput',
	value: '',
	placeholder: 'Ort, Haltestelle, Adresse oder POI',
	scrollOffset: 150,
	showRoute: false,
	slimLayout: false,
	isHidden: false,
	zIndex: 1,
	onCategorySelect: () => {},
	onSelect: () => {},
	onClear: () => {},
	onBlur: () => {},
	getInputValueFromResult: result => result.shownLabel || result.name || result.disassembledName,
	subtleBoxShadow: false,
	boxShadowOnlyOnInteraction: false,
	focusPull: false,
};

export default Search;
