import Projection from 'ol/src/proj/Projection';
import { fromLonLat, toLonLat } from 'ol/src/proj';

export const epsg4326 = new Projection('EPSG:4326');
export const epsg3857 = new Projection('EPSG:3857');

export const toEpsg4326 = (lat, lon) => {
	const lonLat = toLonLat([lon, lat]);
	return {
		lon: lonLat[0],
		lat: lonLat[1],
	};
};

export const toEpsg3857 = (lat, lon) => {
	const lonLat = fromLonLat([lon, lat]);
	return {
		lon: lonLat[0],
		lat: lonLat[1],
	};
};

export default toEpsg3857;
