/* eslint-disable react/no-array-index-key */
import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Search, breakpoints, colors, getSearchLabelFromResult, routeCache } from 'shared';

import Context from '../context';
import DatePicker from './DatePicker';

import { Remove as Loeschen, Plus } from '../../assets/icons';

const reorder = (list, startIndex, endIndex) => {
	const result = [...list];
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const msRepeat = (length, value) => {
	let output = '';
	while (length > 0) {
		output += ` ${value}`;
		// eslint-disable-next-line no-param-reassign
		length -= 1;
	}
	return output;
};

const HeaderContent = styled.div`
	padding: 24px 8px 8px 56px;
	width: 100%;
`;

export const SearchWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const SpaceKeeper = styled.div`
	flex-shrink: 0;
	width: 40px;
`;

const SearchAction = styled.div`
	flex-shrink: 0;
	position: relative;
	width: 40px;
	height: 40px;

	&:hover {
		cursor: pointer;
	}

	svg {
		width: auto;
		height: 36px;
		margin: 2px;
	}
`;

const RouteList = styled.div`
	display: -ms-grid;
	display: grid;

	-ms-grid-columns: 16px 1fr 40px;
	grid-template-columns: 16px 1fr 40px;
`;

const BulletList = styled.div`
	grid-column: 1;
	-ms-grid-column: 1;

	display: -ms-grid;
	display: grid;

	-ms-grid-columns: 8px;
	grid-template-columns: 8px;

	-ms-grid-rows: ${({ length }) => msRepeat(length, '48px')};
	grid-template-rows: repeat(${({ length }) => length}, 48px);

	${breakpoints.lg`
		-ms-grid-rows: ${({ length }) => msRepeat(length, '60px')};
		grid-template-rows: repeat(${({ length }) => length}, 60px);
	`}

	align-items: center;
	-ms-flex-align: center;
`;

const BulletWrapper = styled.div`
	height: 100%;

	svg {
		height: 100%;
		width: auto;
	}

	circle,
	path {
		fill: #fff;
	}
`;

const SearchList = styled.div`
	-ms-grid-column: 2;
	grid-column: 2;

	display: -ms-grid;
	display: grid;

	-ms-grid-columns: 1fr;
	grid-template-columns: 1fr;

	-ms-grid-rows: ${({ length }) => msRepeat(length, 'auto')};
	grid-template-rows: repeat(${({ length }) => length}, auto);

	align-items: center;
	-ms-flex-align: center;
`;

const StyledActionList = styled.div`
	grid-column: 3;
	-ms-grid-column: 3;

	display: -ms-grid;
	display: grid;

	-ms-grid-columns: 40px;
	grid-template-columns: 40px;

	-ms-grid-rows: ${({ length }) => msRepeat(length, '48px')};
	grid-template-rows: repeat(${({ length }) => length}, 48px);

	${breakpoints.lg`
		-ms-grid-rows: ${({ length }) => msRepeat(length, '60px')};
		grid-template-rows: repeat(${({ length }) => length}, 60px);
	`}

	align-items: center;
	-ms-flex-align: center;
`;

const AddAction = styled(SearchAction)`
	align-self: center;
`;

function Bullet({ index, length }) {
	return (
		<BulletWrapper>
			<svg viewBox="0 0 8 72" width="8" height="72">
				{index > 0 && (
					<>
						<circle cx="4" cy="0" r="2" />
						<circle cx="4" cy="12" r="2" />
						<circle cx="4" cy="24" r="2" />
					</>
				)}
				{index === length - 1 ? (
					<path d="M 8 36 A 1 1 0 0 0 0 36 C 0 38 2 40 4 43 C 6 40 8 38 8 36"></path>
				) : (
					<circle cx="4" cy="36" r="4" />
				)}

				{index < length - 1 && (
					<>
						<circle cx="4" cy="48" r="2" />
						<circle cx="4" cy="60" r="2" />
						<circle cx="4" cy="72" r="2" />
					</>
				)}
			</svg>
		</BulletWrapper>
	);
}

Bullet.propTypes = {
	index: PropTypes.number.isRequired,
	length: PropTypes.number.isRequired,
};

const DragHandle = styled.span`
	z-index: 1;
	color: ${colors.cyandark};
	font-weight: bold;
`;

function ActionsList({ points, setPoints }) {
	return points.map((point, index) => (
		<SearchAction
			key={`${point?.id || index}${point?.ts || ''}`}
			onClick={() => setPoints(points.filter((p, i) => i !== index))}
		>
			<Loeschen />
		</SearchAction>
	));
}

export default function SearchHeader({ newPoints, onRequestRoutes }) {
	const ref = useRef();
	const { date, setDate, setPoints } = useContext(Context);
	const { t } = useTranslation();

	return (
		<HeaderContent>
			<RouteList ref={ref}>
				<BulletList length={newPoints.length}>
					{newPoints.map((point, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<Bullet
							key={(point?.id || 0) + index}
							index={index}
							length={newPoints.length}
						/>
					))}
				</BulletList>
				<DragDropContext
					onDragEnd={({ source, destination }) => {
						if (source && destination) {
							setPoints(reorder(newPoints, source.index, destination.index));
						} else {
							setPoints(newPoints);
						}
					}}
				>
					<Droppable droppableId="droppable">
						{droppable => (
							<SearchList
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...droppable.droppableProps}
								ref={droppable.innerRef}
								length={newPoints.length}
							>
								{newPoints.map((point, index) => (
									<Draggable
										index={index}
										draggableId={index.toString()}
										key={index}
									>
										{draggable => (
											<div
												// eslint-disable-next-line react/jsx-props-no-spreading
												{...draggable.draggableProps}
												ref={draggable.innerRef}
											>
												<Search
													focusPull={index === 0 && !point}
													id={`route-search-${index}`}
													value={
														point ? getSearchLabelFromResult(point) : ''
													}
													placeholder={(() => {
														if (index === 0)
															return t(
																'common.searchPlaceholderStart',
															);
														if (index === newPoints.length - 1)
															return t('common.searchPlaceholderEnd');
														return t('common.searchPlaceholderBetween');
													})()}
													onSelect={result => {
														if (
															result.id === routeCache.ROUTE_CACHE_KEY
														) {
															setPoints(result.points);
														} else {
															setPoints(
																newPoints.map((p, i) => {
																	if (index === i) {
																		return result;
																	}
																	return p;
																}),
															);

															if (typeof _paq !== 'undefined') {
																// eslint-disable-next-line no-underscore-dangle
																window._paq.push([
																	'trackEvent',
																	'Suche - Routenplanung', // Category
																	'Suchergebnis', // Action
																	`${getSearchLabelFromResult(
																		result,
																	)} (${result.id})`, // Name
																]);
															}
														}
													}}
													getInputValueFromResult={
														getSearchLabelFromResult
													}
													onClear={() => {
														if (newPoints.length > 2) {
															setPoints(
																newPoints.filter(
																	(p, i) => i !== index,
																),
															);
															return;
														}
														setPoints(
															newPoints.map((p, i) => {
																if (index === i) {
																	return null;
																}
																return p;
															}),
														);
													}}
													onBlur={value => {
														if (newPoints.length > 2 && !value) {
															setPoints(
																newPoints.filter(
																	(p, i) => i !== index,
																),
															);
														}
													}}
													action={() => (
														// eslint-disable-next-line react/jsx-props-no-spreading
														<DragHandle {...draggable.dragHandleProps}>
															☰
														</DragHandle>
													)}
													resultsFilter={result =>
														result.coord ||
														result.coords ||
														(result.type === 'tip' &&
															result.address.coords)
													}
													zIndex={999 - index}
													positionrelative
													// searchResultsRelative
													slimLayout
													subtleBoxShadow
													boxShadowOnlyOnInteraction
												/>
											</div>
										)}
									</Draggable>
								))}
								{droppable.placeholder}
							</SearchList>
						)}
					</Droppable>
				</DragDropContext>

				<StyledActionList length={newPoints.length}>
					{newPoints.length <= 2 ? null : (
						<ActionsList points={newPoints} setPoints={setPoints} />
					)}
				</StyledActionList>
				<DatePicker
					date={date}
					onChange={newDate => {
						setDate(newDate);
						onRequestRoutes();
					}}
				/>

				{newPoints[newPoints.length - 1] !== null && (
					<AddAction
						onClick={() => {
							if (newPoints[newPoints.length - 1] !== null) {
								setPoints([...newPoints, null]);
							}
						}}
					>
						<Plus />
					</AddAction>
				)}
			</RouteList>
		</HeaderContent>
	);
}

SearchHeader.propTypes = {
	newPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
	setRoutes: PropTypes.func.isRequired,
	onRequestRoutes: PropTypes.func.isRequired,
};
