/**
 * Calculate distance between too points.
 *
 * @name calculateDistance
 * @function
 * @param {number} lat1 The latitude value of the first point
 * @param {number} lon1 The longitude value of the first point
 * @param {number} lat2 The latitude value of the second point
 * @param {number} lon2 The longitude value of the first point
 * @param {string} unit The unit used for calculation. Possible values: K (kilometers), M (miles), N (nautic miles)
 * @return {number} The distance between the two points.
 */

export function calculateDistance(lat1, lon1, lat2, lon2, unit = 'K') {
	if (lat1 === lat2 && lon1 === lon2) {
		return 0;
	}
	const radlat1 = (Math.PI * lat1) / 180;
	const radlat2 = (Math.PI * lat2) / 180;
	const theta = lon1 - lon2;
	const radtheta = (Math.PI * theta) / 180;
	let dist =
		Math.sin(radlat1) * Math.sin(radlat2) +
		Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
	if (dist > 1) {
		dist = 1;
	}
	dist = Math.acos(dist);
	dist = (dist * 180) / Math.PI;
	dist = dist * 60 * 1.1515;
	if (unit === 'K') {
		dist *= 1.609344;
	}
	if (unit === 'N') {
		dist *= 0.8684;
	}
	return dist;
}
