import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InnerStyledButton = styled.button`
	color: inherit;

	&,
	&:focus,
	&:hover,
	&:visited,
	&:link,
	&:active {
		text-decoration: none;
	}
`;

function StyledButton({ clickEvent, children }) {
	return (
		<div
			role="button"
			className="details"
			onClick={clickEvent}
			onKeyPress={e => {
				if (e.key === 'Enter') {
					clickEvent();
				}
			}}
			tabIndex="0"
		>
			<InnerStyledButton type="button">{children}</InnerStyledButton>
		</div>
	);
}

StyledButton.propTypes = {
	clickEvent: PropTypes.func.isRequired,
	children: PropTypes.element,
};

export default StyledButton;
