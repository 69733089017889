import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from 'shared';
import { distanceToString } from '../../utils';

import { ThemeRoutePropTypes } from './propTypes';
import { ImageWrapper, StyledListItem } from './styles';

import { Bike } from '../../assets/icons';
import { ScrollContext } from '../ScrollContextProvider';

const PLACEHOLDER = `data:image/svg+xml;utf8,<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
	<rect width="16" height="16" fill="none" stroke="rgb(128,128,128)" />
	<line x1="0" y1="0" x2="16" y2="16" stroke="rgb(128,128,128)" stroke-width="0.5"/>
	<line x1="0" y1="16" x2="16" y2="0" stroke="rgb(128,128,128)" stroke-width="0.5"/>
</svg>`;

const StyledLink = styled(Link)`
	display: flex;
	color: inherit;
	text-decoration: none;

	&:focus,
	&:hover,
	&:visited,
	&:link,
	&:active {
		text-decoration: none;
	}
`;

const Route = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
`;

const RouteDetails = styled.div`
	color: ${colors.graydark};
	display: flex;
	font-size: 0.9rem;
	flex-direction: row;
	* ~ * {
		margin-left: 0.66rem;
	}
`;

function ThemeRoute({ route, to }) {
	const { saveScrollPosition } = useContext(ScrollContext);
	const { id, name, distance, up, down, image = PLACEHOLDER } = route;

	if (!name) {
		return <></>;
	}

	return (
		<StyledListItem onClick={saveScrollPosition}>
			<StyledLink key={`${id}-${name}`} to={to}>
				<ImageWrapper>
					{image ? <img src={image} alt={name} loading="lazy" /> : <Bike />}
				</ImageWrapper>

				<Route>
					<h4>{name}</h4>
					{(distance || up || down) && (
						<RouteDetails>
							<span>{distance} km</span>
							<span>⬆ {distanceToString(up, false)}</span>
							<span>⬇ {distanceToString(down, false)}</span>
						</RouteDetails>
					)}
				</Route>
			</StyledLink>
		</StyledListItem>
	);
}

ThemeRoute.propTypes = {
	route: ThemeRoutePropTypes.isRequired,
	to: PropTypes.string.isRequired,
};

export default ThemeRoute;
