import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
	padding-top: 0.8rem;
	position: relative;
`;

const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	overflow: auto;
	overflow-y: hidden;
`;

export default function TransportationWrapper({ children, noFade }) {
	return (
		<Wrapper noFade={noFade}>
			<Content noFade={noFade}>{children}</Content>
		</Wrapper>
	);
}

TransportationWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	noFade: PropTypes.bool,
};

TransportationWrapper.defaultProps = {
	noFade: false,
};
