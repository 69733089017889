import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
	colors,
	dateToTimestring,
	stringToDate,
	minutesToDurationString,
	RealTime,
	IconTaxi,
} from 'shared';
import {
	Destination,
	Footpath,
	Bike,
	Bus,
	Stadtbahn,
	SBahn,
	RBahn,
	RegioRadLeg,
	Stop,
	Zacke,
	// Info,
} from '../../../assets/icons';

import ElevationProfile from './ElevationProfile';
import { distanceToString } from '../../../utils';
import Context from '../../context';

const Icons = {
	footpath: Footpath,
	Fahrrad: Bike,
	Bus,
	'SEV-Bus': Bus,
	Linientaxi: IconTaxi,
	Stadtbahn,
	'S-Bahn': SBahn,
	'R-Bahn': RBahn,
	RegioRad: RegioRadLeg,
	Stop,
	Zahnradbahn: Zacke,
};

/**
 * @description
 *  footpath: 	#999
 *  bike: 		#333
 *  rBahn: 		#8F908F
 *  sBahn: 		#52B331
 *  bus: 		#C31924
 *  taxi:		#183F8A
 *  zacke: 		#FDCC00
 *
 * @param {*} param
 * @param {string} productType
 * @return {string}
 */
function getColorForProductType({ productType }) {
	switch (productType) {
		case 'Bus':
		case 'SEV-Bus':
			return colors.bus;
		case 'S-Bahn':
			return colors.sBahn;
		case 'R-Bahn':
			return colors.rBahn;
		case 'Zahnradbahn':
			return colors.zacke;
		case 'Stadtbahn':
			return colors.stadtbahn;
		case 'Linientaxi':
			return colors.taxi;
		default:
			// return colors.footpath;
			return '#E6E6E6';
	}
}

function isFootOrBike(type) {
	return ['footpath', 'Fahrrad'].includes(type);
}

function getTransportationName(transportation, distance, t) {
	if (transportation.product.name === 'footpath') {
		return t('routePlanning.footpath', { distance: distanceToString(distance) });
	}
	if (['Fahrrad', 'RegioRad'].indexOf(transportation.product.name) > -1) {
		return t('routePlanning.bike', { distance: distanceToString(distance) });
	}

	return `${transportation.disassembledName || transportation.product.name} ${transportation
		?.destination?.name || ''}`;
}

const formatTime = string => dateToTimestring(stringToDate(string));

const getName = place => place.disassembledName || place.name;

const Dot = styled.div`
	background-color: ${colors.cyandark};
	border-radius: 50%;
	width: 100%;
	height: 100%;
`;

const StyledRouteLeg = styled.div`
	display: grid;
	-ms-grid-columns: 24px 1fr;
	grid-template-columns: 24px 1fr;

	/* padding-left: 2rem; */
	position: relative;

	&::before {
		content: '';
		z-index: -1;
		display: block;
		position: absolute;
		top: 0;
		left: 0.7rem;
		bottom: 0;

		border-left-width: 0.25rem;

		border-left-color: ${getColorForProductType};
		border-left-style: ${({ productType }) => (isFootOrBike(productType) ? 'dotted' : 'solid')};
	}
`;

const StopIconWrapper = styled.div`
	${({ position, productType }) => {
		if (isFootOrBike(productType))
			return 'background: radial-gradient(circle, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 60%);';
		if (position === 'first')
			return 'background: linear-gradient(180deg, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 60%);';
		if (position === 'last')
			return 'background: linear-gradient(0deg, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 60%);';
		return '';
	}}

	width: 24px;
	height: 24px;
	line-height: 0;

	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	align-items: center;
	justify-content: center;
	* {
		position: relative;
		grid-row: 1 / -1;
		grid-column: 1 / -1;
		text-align: center;
		color: #fff;
	}
`;

const ColumnWrapper = styled.div`
	margin-left: 0.5rem;
	-ms-grid-column: 2;
	-ms-grid-column-span: 1;
	grid-column: 2 / 3;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: ${({ column }) => (column ? 'column' : 'row')};
	${({ padded }) => padded && `padding: 1.5rem 0`};
`;

const StopName = styled.span`
	font-size: 1.1rem;
`;
const StopDuration = styled.div`
	font-size: 0.9rem;
	color: ${colors.graydark};
	white-space: nowrap;
`;
const StopTrack = styled.h3`
	font-size: 0.9rem;
`;

const StopTimeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	text-align: end;
`;

const StopTime = styled.time`
	font-size: 1.1rem;
	color: ${({ delayed }) => (delayed ? '#FC631E' : 'initial')};

	> svg {
		margin-bottom: -2px;
	}
`;

const StopTransportation = styled.span`
	font-size: 1.1rem;
`;

// const StopContent = styled.div`
// 	-ms-grid-column: 2;
// 	-ms-grid-column-span: 1;
// 	grid-column: 2 / 3;
// 	margin-bottom: 2rem;
// `;

// const LegInfo = styled.span`
// 	-ms-grid-column: 2;
// 	-ms-grid-column-span: 1;
// 	grid-column: 2 / 3;

// 	display: grid;
// 	grid-template-columns: 24px 1fr;
// 	text-decoration: none;
// 	color: #999;
// 	font-size: 0.9rem;
// 	margin-bottom: 1rem;

// 	svg {
// 		width: 24px;
// 		height: 24px;
// 	}
// `;

const InfoText = styled.span`
	color: ${colors.graydark};
`;

const StopInfo = styled.p`
	-ms-grid-column: 2;
	-ms-grid-column-span: 1;
	grid-column: 2 / 3;

	color: ${colors.graydark};
`;

const StopTransportationIcon = styled.div`
	${({ productType }) => {
		if (isFootOrBike(productType))
			return 'background: radial-gradient(circle, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 100%);';
		return '';
	}}
	line-height: 0;
	-ms-grid-row-align: center;
	align-self: center;
`;

function idToStopId(id) {
	return id
		.split(':')
		.slice(0, 3)
		.join(':');
}

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export default function RouteLeg({ route, leg, index, legs }) {
	const { t } = useTranslation();
	const { points } = useContext(Context);

	const { origin, destination, transportation, duration, pathDescriptions } = leg;

	if (
		origin.id.substr(0, 3) === 'de:' &&
		destination.id.substr(0, 3) === 'de:' &&
		idToStopId(origin.id) === idToStopId(destination.id)
	) {
		return false;
	}

	const isBike =
		transportation.product.name === 'Fahrrad' || transportation.product.name === 'RegioRad';

	let transportationIcon = null;
	if (Icons[transportation?.product?.name]) {
		const TransportationIcon = Icons[transportation.product.name];
		transportationIcon = <TransportationIcon width="26px" height="26px" />;
	}
	const Icon = ['stop', 'platform'].includes(origin?.type) ? Icons.Stop : Dot;
	const legType = transportation?.product?.name;

	let footPathDuration = false;
	if (leg?.footPathInfo) {
		leg.footPathInfo.some(info => {
			if (info.position === 'BEFORE') {
				footPathDuration = info.duration;
				return true;
			}
			return false;
		});
	}
	if (footPathDuration === false && index > 0 && legs[index - 1]?.footPathInfo) {
		legs[index - 1].footPathInfo.some(info => {
			if (info.position === 'AFTER') {
				footPathDuration = info.duration;
				return true;
			}
			return false;
		});
	}

	const cumDistance =
		pathDescriptions?.[pathDescriptions.length - 1]?.cumDistance || leg.distance || 0;

	const lastPointIndex = points.length - 1;
	let letterStart = ALPHABET.charAt(index);
	let letterEnd = ALPHABET.charAt(lastPointIndex);
	if (isFootOrBike(legType)) {
		const legId = decodeURIComponent(leg.origin.id);
		points.some((point, i) => {
			if (index <= i && point !== null && point.id === legId) {
				letterStart = ALPHABET.charAt(i);
				return true;
			}
			return false;
		});
		points.some((point, i) => {
			if (points[lastPointIndex - i] !== null) {
				letterEnd = ALPHABET.charAt(lastPointIndex - i);
				return true;
			}
			return false;
		});
	}

	return (
		<StyledRouteLeg productType={legType}>
			<StopIconWrapper position={index === 0 ? 'first' : ''} productType={legType}>
				<Icon width="26px" height="26px" />
				{!['stop', 'platform'].includes(origin?.type) && <span>{letterStart}</span>}
			</StopIconWrapper>

			<ColumnWrapper>
				<StopName>
					{decodeURIComponent(origin.name).replace(
						/\$UNKNOWN_POINT\$/g,
						'Punkt auf Karte',
					)}
				</StopName>
				{footPathDuration !== false && (
					<StopDuration>
						{t('routePlanning.duration', {
							duration: Math.ceil(footPathDuration / 60),
						})}
					</StopDuration>
				)}
			</ColumnWrapper>

			<ColumnWrapper>
				{isBike && route.routeType !== 'bikeOnly' && (
					<>
						<span />
						<StopTimeWrapper>
							<StopTime>
								{index === 0
									? t('routePlanning.bikeStart', {
										time: formatTime(origin.departureTimePlanned),
									  })
									: t('routePlanning.departurePreposition', {
										time: formatTime(origin.departureTimePlanned),
									  })}
							</StopTime>
						</StopTimeWrapper>
					</>
				)}
				{!isBike && (
					<>
						{['stop', 'platform'].includes(origin.type) && (
							<StopTrack>
								{getName(origin) !== origin.name &&
									decodeURIComponent(getName(origin)).replace(
										/\$UNKNOWN_POINT\$/g,
										'Punkt auf Karte',
									)}
							</StopTrack>
						)}
						<StopTimeWrapper>
							<StopTime datetime={origin.departureTimePlanned}>
								{leg.isRealtimeControlled && <RealTime />}
								{leg.isRealtimeControlled && ' '}
								{t('routePlanning.departurePreposition', {
									time: formatTime(origin.departureTimePlanned),
								})}
							</StopTime>
							{origin.departureTimePlanned !== origin.departureTimeEstimated && (
								<StopTime delayed datetime={origin.departureTimeEstimated}>
									{formatTime(origin.departureTimeEstimated)}
								</StopTime>
							)}
						</StopTimeWrapper>
					</>
				)}
			</ColumnWrapper>

			{route.routeType === 'bikeSharing' && transportation?.product?.name === 'RegioRad' && (
				<StopInfo>{t('routeDetails.rentBike')}</StopInfo>
			)}

			<StopTransportationIcon productType={legType}>
				{transportationIcon}
			</StopTransportationIcon>

			<ColumnWrapper padded>
				<StopTransportation>
					{getTransportationName(transportation, cumDistance, t)}
				</StopTransportation>
				<StopDuration>{minutesToDurationString(duration / 60)}</StopDuration>
			</ColumnWrapper>

			{/*
			{(leg?.infos?.length > 0 || leg?.hints?.length > 0) && (
				<ColumnWrapper column>
					{leg?.infos?.map(({ id, url, content }) => (
						<LegInfo
							key={id}
							as="a"
							href={url}
							title={content}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Info />
							{t('routePlanning.info', { content: content.substring(0, 196) })}
						</LegInfo>
					))}
					{leg?.hints?.map(({ id, content }) => (
						<LegInfo key={`info-${id || content}`}>
							<Info />
							{content}
						</LegInfo>
					))}
				</ColumnWrapper>
			)}
			*/}

			{index !== legs.length - 1 && (
				<ColumnWrapper>
					<StopTrack>
						{getName(destination) !== legs[index + 1].origin.name &&
							decodeURIComponent(getName(destination)).replace(
								/\$UNKNOWN_POINT\$/g,
								'Punkt auf Karte',
							)}
					</StopTrack>
					<StopTime datetime={destination.arrivalTimeEstimated}>
						{t('routePlanning.arrivalPreposition', {
							time: formatTime(destination.arrivalTimeEstimated),
						})}
					</StopTime>
				</ColumnWrapper>
			)}

			{/* <StopContent>Inhalt hier einfügen</StopContent> */}

			{isBike && <ElevationProfile data={leg.coords} />}

			{isBike && route.routeType !== 'bikeOnly' && (
				<ColumnWrapper>
					{(route.routeType === 'bikeAndRide' || index === legs.length - 1) && (
						<InfoText>{t('routePlanning.bikeEnd')}</InfoText>
					)}
					{index !== legs.length - 1 && (
						<StopTimeWrapper>
							<StopTime>
								{t('routePlanning.arrivalPreposition', {
									time: formatTime(destination.arrivalTimeEstimated),
								})}
							</StopTime>
						</StopTimeWrapper>
					)}
				</ColumnWrapper>
			)}

			{index === legs.length - 1 && (
				<>
					<StopIconWrapper
						position={index === legs.length - 1 ? 'last' : ''}
						productType={legType}
					>
						<Destination
							width="26px"
							height="26px"
							style={{ transform: 'scale(2.25) translateY(1px)' }}
						/>
						<span>{letterEnd}</span>
					</StopIconWrapper>
					<ColumnWrapper>
						<StopName>
							{decodeURIComponent(getName(destination)).replace(
								/\$UNKNOWN_POINT\$/g,
								'Punkt auf Karte',
							)}
						</StopName>
						{route.routeType !== 'bikeOnly' && (
							<StopTime datetime={destination.arrivalTimeEstimated}>
								{t('routePlanning.arrivalDestination', {
									time: formatTime(destination.arrivalTimeEstimated),
								})}
							</StopTime>
						)}
					</ColumnWrapper>
				</>
			)}
		</StyledRouteLeg>
	);
}

const leg = PropTypes.shape({
	transportation: PropTypes.shape({
		product: PropTypes.shape({
			name: PropTypes.string,
		}),
	}),
	origin: PropTypes.shape({
		name: PropTypes.string,
		disassembledName: PropTypes.string,
		type: PropTypes.string,
		departureTimeEstimated: PropTypes.string,
		departureTimePlanned: PropTypes.string,
	}),
	destination: PropTypes.shape({
		name: PropTypes.string,
		disassembledName: PropTypes.string,
		type: PropTypes.string,
		arrivalTimeEstimated: PropTypes.string,
	}),
	footPathInfo: PropTypes.arrayOf(
		PropTypes.shape({
			duration: PropTypes.number,
		}),
	),
});

const route = PropTypes.shape({
	routeType: PropTypes.string,
});

RouteLeg.propTypes = {
	route,
	leg,
	index: PropTypes.number.isRequired,
	legs: PropTypes.arrayOf(leg),
};
