import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { breakpoints as media } from '../../utils';

const BOX_SHADOW = css`
	box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 1.6px -2px, rgba(0, 0, 0, 0.06) 0px -4px 12px -2px,
		rgba(0, 0, 0, 0.08) 0px 4px 6px -2px, rgba(0, 0, 0, 0.4) 0px 6px 12px -2px;
`;

const Container = styled.div`
	background-color: #fff;

	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	position: fixed;
	top: 0;
	bottom: 0;

	height: 100vh;
	width: 100%;
	max-width: 375px;

	overflow: auto;
	visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
	${({ open }) => open && BOX_SHADOW};

	${({ right, open }) =>
		right
			? css`
					transform: translate(${open ? 0 : 100}%, 0);
					right: 0;
			  `
			: css`
					transform: translate(${open ? 0 : -100}%, 0);
					left: 0;
			  `}

	z-index: 15;
	transition: all 333ms ease-in-out;

	${media.lg`
		max-width: 432px;
	`}

	@media (max-width: 668px) {
		max-width: 100%;
		${({ pushDown }) =>
			pushDown &&
			css`
				position: absolute;
				top: 100vh;
				transform: translate(0px, -250px);
			`}
	}
`;

const Sidebar = forwardRef(({ open, right, pushDown, children }, ref) => (
	<Container ref={ref} open={open} right={right} pushDown={pushDown}>
		{children}
	</Container>
));

Sidebar.propTypes = {
	open: PropTypes.bool,
	right: PropTypes.bool,
	children: PropTypes.node,
	pushDown: PropTypes.bool,
};

Sidebar.defaultProps = {
	open: false,
	right: false,
	pushDown: false,
};

export default Sidebar;

export { default as SidebarHeader } from './Header';
