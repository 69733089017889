import styled, { css } from 'styled-components';

import { colors, fonts } from '../../utils/config';

export const Wrapper = styled.div`
	display: block;
	max-width: 1024px;
	width: 100%;
	background-color: ${colors.cyandark};
	overflow: auto;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	max-height: 40vh;
	z-index: 9999;
`;

export const Disclaimer = styled.div`
	margin-left: auto;
	margin-right: auto;
	padding: 1rem 1rem 0;
	max-width: 1248px;
	z-index: 1;
`;

export const Grid = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-end;
	margin-left: -1rem;
	margin-right: -1rem;
`;

export const GridItem = styled.div`
	flex: 0 0 auto;
	margin-bottom: 0.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
	width: 100%;

	${({ smallSpacingXs }) =>
		smallSpacingXs
			? css`
					margin-bottom: 0.5rem;
			  `
			: ''}

	${({ mediumSpaceNone }) =>
		mediumSpaceNone
			? css`
					@media (min-width: 37.5em) {
						margin-bottom: 0;
					}
			  `
			: ''}

	${({ medium }) => {
		if (medium === 3) {
			return css`
				@media (min-width: 37.5em) {
					width: 25%;
				}
			`;
		}
		if (medium === 9) {
			return css`
				@media (min-width: 37.5em) {
					width: 75%;
				}
			`;
		}
		return '';
	}}

	${({ large }) => {
		if (large === 'auto') {
			return css`
				@media (min-width: 48em) {
					flex: 1 1 0px;
					width: auto;
				}
			`;
		}
		if (large === 'shrink') {
			return css`
				@media (min-width: 48em) {
					flex: 0 0 auto;
					width: auto;
				}
			`;
		}
		return '';
	}}
`;

export const HintText = styled.p`
	color: ${colors.white};
	font-size: 14px;
	line-height: 20px;

	a {
		color: ${colors.white};
	}
`;

export const CookieButton = styled.button`
	background-color: transparent;
	border: 1px solid ${colors.white};
	border-radius: 0;
	color: ${colors.white};
	cursor: pointer;
	display: inline-block;
	font-family: ${fonts.bold};
	font-size: 14px;
	font-weight: ${fonts.weightbold};
	line-height: 20px;
	margin: 0 0 1rem;
	padding: 0.4375rem 1rem;
	text-align: center;
	vertical-align: middle;
	width: auto;
	min-height: 2.375rem;
	-webkit-appearance: none;
`;

export const SaveSelectedButton = styled.button`
	background-color: ${colors.cyandark};
	border: 0;
	border-radius: 0;
	color: ${colors.white};
	cursor: pointer;
	font-family: ${fonts.bold};
	font-size: 14px;
	font-weight: ${fonts.weightbold};
	line-height: 20px;
	margin: 1rem 0;
	padding: 0.4375rem 1rem;
	width: auto;
	min-height: 2.375rem;
`;
