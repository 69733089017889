import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'shared';

const StyledButton = styled.button`
	background-color: ${colors.orange};
	border: 0;
	border-radius: 3px;
	display: block;
	font-size: 15px;
	line-height: 19px;
	margin-top: 16px;
	padding: 8px 40px;
	pointer-events: all;
	position: relative;
	text-align: center;
	white-space: nowrap;
	width: 100%;
	z-index: 1;

	> span {
		color: ${colors.white};
	}

	&:hover {
		cursor: pointer;
	}

	.marker--sharing-regiorad &,
	.marker__tooltip--regiorad & {
		background-color: #06507f;
	}

	.marker--sharing-stella &,
	.marker__tooltip--stella & {
		background-color: #18ace9;
	}

	.marker--sharing-stadtmobil &,
	.marker__tooltip--stadtmobil & {
		background-color: #e2000f;
	}

	.marker--sharing-car2go &,
	.marker__tooltip--car2go & {
		background-color: #1498e3;
	}

	.marker--sharing-flinkster &,
	.marker__tooltip--flinkster & {
		background-color: #e2000f;
	}
`;

const touchstart = e => e.target.setAttribute('data-touchstart', Date.now());

function MarkerButton({ className, onClick, children }) {
	const buttonRef = createRef();

	const handleClick = e => {
		e.preventDefault();
		e.stopPropagation();

		onClick(e);
	};

	const touchend = e => {
		if (parseInt(e.target.getAttribute('data-touchstart'), 10) + 200 > Date.now()) {
			handleClick(e);
		}
	};

	useEffect(() => {
		const button = buttonRef.current;
		button.addEventListener('click', handleClick, false);
		button.addEventListener('touchstart', touchstart, false);
		button.addEventListener('touchend', touchend, false);
	});

	return (
		<StyledButton className={className} type="button" ref={buttonRef}>
			<span>{children}</span>
		</StyledButton>
	);
}

MarkerButton.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
};

MarkerButton.defaultProps = {
	className: '',
};

export default MarkerButton;
