import { ReactComponent as AroundBike } from './aroundBike.svg';
import { ReactComponent as Bike } from './bike.svg';
import { ReactComponent as Bus } from './bus.svg';
import { ReactComponent as BusGrey } from './bus-grey.svg';
import { ReactComponent as Car2go } from './car2go.svg';
import { ReactComponent as CityRailway } from './cityRailway.svg';
import { ReactComponent as CityTrain } from './cityTrain.svg';
import { ReactComponent as Clubs } from './clubs.svg';
import { ReactComponent as Culture } from './culture.svg';
import { ReactComponent as Dot } from './dot.svg';
import { ReactComponent as EducationalInstitutions } from './educationalInstitutions.svg';
import { ReactComponent as FoodAndDrink } from './foodAndDrink.svg';
import { ReactComponent as Flinkster } from './flinkster.svg';
import { ReactComponent as Hospitals } from './hospitals.svg';
import { ReactComponent as Landmarks } from './landmarks.svg';
import { ReactComponent as Recreation } from './recreation.svg';
import { ReactComponent as RegionalTrain } from './regionalTrain.svg';
import { ReactComponent as RegioRad } from './regioRad.svg';
import { ReactComponent as RegioRadLeg } from './regioRad-leg.svg';
import { ReactComponent as StadtMobil } from './stadtMobil.svg';
import { ReactComponent as RBahn } from './r-bahn.svg';
import { ReactComponent as SBahn } from './s-bahn.svg';
import { ReactComponent as ShareNow } from './sharenow.svg';
import { ReactComponent as Stadtbahn } from './stadtbahn.svg';
import { ReactComponent as Stella } from './stella.svg';
import { ReactComponent as Stops } from './stops.svg';
import { ReactComponent as TripTip } from './tripTip.svg';
import { ReactComponent as Zacke } from './zacke.svg';
import { ReactComponent as Train } from './train.svg';
import { ReactComponent as Footpath } from './footpath.svg';
import { ReactComponent as NextGrey } from './next-grey.svg';
import { ReactComponent as Next } from './next.svg';
import { ReactComponent as Stop } from './stop.svg';
import { ReactComponent as Remove } from './remove.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as ArrowUp } from './arrow-up.svg';
import { ReactComponent as ArrowUpLeft } from './arrow-up-left.svg';
import { ReactComponent as ArrowUpRight } from './arrow-up-right.svg';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as ArrowUpGrey } from './arrow-up-grey.svg';
import { ReactComponent as ArrowDownGrey } from './arrow-down-grey.svg';
import { ReactComponent as DeleteGrey } from './delete-grey.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as WhatsApp } from './whatsapp.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Share } from './share.svg';
import { ReactComponent as Change } from './change.svg';
import { ReactComponent as Destination } from './destination.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Start } from './start.svg';
import { ReactComponent as RadroutenPlanerIcon } from './icon-card.svg';

export default {
	AroundBike,
	Bike,
	Bus,
	BusGrey,
	Car2go,
	CityRailway,
	CityTrain,
	Clubs,
	Culture,
	Dot,
	EducationalInstitutions,
	FoodAndDrink,
	Flinkster,
	Hospitals,
	Landmarks,
	Recreation,
	RegionalTrain,
	RegioRad,
	RegioRadLeg,
	StadtMobil,
	RBahn,
	SBahn,
	ShareNow,
	Stadtbahn,
	Stella,
	Stops,
	TripTip,
	Zacke,
	Train,
	Footpath,
	NextGrey,
	Next,
	Stop,
	Remove,
	Plus,
	ArrowUp,
	ArrowUpLeft,
	ArrowUpRight,
	ArrowDown,
	ArrowLeft,
	ArrowRight,
	ArrowUpGrey,
	ArrowDownGrey,
	DeleteGrey,
	Mail,
	WhatsApp,
	Twitter,
	Share,
	Change,
	Destination,
	Info,
	Start,
};

export {
	AroundBike,
	Bike,
	Bus,
	BusGrey,
	Car2go,
	CityRailway,
	CityTrain,
	Clubs,
	Culture,
	Dot,
	EducationalInstitutions,
	FoodAndDrink,
	Flinkster,
	Hospitals,
	Landmarks,
	Recreation,
	RegionalTrain,
	RegioRad,
	RegioRadLeg,
	StadtMobil,
	RBahn,
	SBahn,
	Stadtbahn,
	Stella,
	Stops,
	TripTip,
	Zacke,
	Train,
	Footpath,
	NextGrey,
	Next,
	Stop,
	Remove,
	Plus,
	ArrowUp,
	ArrowUpLeft,
	ArrowUpRight,
	ArrowDown,
	ArrowLeft,
	ArrowRight,
	ArrowUpGrey,
	ArrowDownGrey,
	DeleteGrey,
	Mail,
	WhatsApp,
	Twitter,
	Share,
	Change,
	Destination,
	Info,
	Start,
	RadroutenPlanerIcon,
};
