import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Context from '../context';

import LoadingRoute from './LoadingRoute';
import OwnRoute from './OwnRoute';
import ThemeRoute from './ThemeRoute';

import { RoutePropTypes, ThemeRoutePropTypes, LoadingRoutePropTypes } from './propTypes';

const StyledList = styled.ul`
	margin: 1rem 0 0 0;
	padding: 0;
	list-style: none;
`;

function RouteList({ routes }) {
	const { location } = useHistory();
	const { removeMyRoute } = useContext(Context);

	const [path, position] = location.pathname.split('@');

	return (
		<StyledList>
			{routes.map(route => {
				if (route.my) {
					return (
						<OwnRoute
							key={route.id}
							route={route}
							// to={`${path}/${route.id}@${position}`}
							removeRoute={() => removeMyRoute(route.id)}
						/>
					);
				}
				if (route.id) {
					return (
						<ThemeRoute
							key={route.id}
							route={route}
							to={`${path}/${route.id}@${position}`}
						/>
					);
				}
				return <LoadingRoute key={route.loadingId} />;
			})}
		</StyledList>
	);
}

RouteList.propTypes = {
	routes: PropTypes.arrayOf(
		PropTypes.oneOfType([RoutePropTypes, ThemeRoutePropTypes, LoadingRoutePropTypes]),
	),
};

RouteList.defaultProps = {};

export default RouteList;
