import styled from 'styled-components';
import media from '../../utils/Breakpoints';

import Icon from '../../assets/icons/menu-icon.svg';

export default styled.div`
	position: fixed;
	bottom: 32px;
	left: 8px;
	width: 36px;
	height: 36px;
	background: url(${Icon}) center center no-repeat transparent;
	background-size: 100%;
	z-index: 10;

	${media.lg`
		bottom: 16px;
		left: 16px;
		width: 48px;
		height: 48px;
	`}

	${media.xl`
		bottom: 32px;
		left: 32px;
	`}

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		transform: scale(0.752);
		transform-origin: top right;
	}

	div {
		display: none;
	}

	&:hover {
		cursor: pointer;

		div {
			display: block;
		}
	}
`;
