import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import VectorSource from 'ol/src/source/Vector';

import {
	colors,
	fetchers,
	stringToDate,
	SidebarHeader,
	ImageLoadingIndicator,
	TextBlockLoadingIndicator,
} from 'shared';

import Context from '../context';

import RouteDetails from '../route-planning/route-details';

import {
	activateRouteType,
	drawRouteOnMap,
	getOrCreateLinesLayer,
	mapRoot,
	updateUri,
} from '../../utils';
import useDrawPointMarkers from '../../hooks/useDrawPointMarker';
import useDrawLegsTransportationMarkers from '../../hooks/useDrawLegsTransportationMarkers';

const Content = styled.div`
	padding: 1rem;
`;

const RouteTypeError = styled.h3`
	margin-bottom: 0.2rem;
	padding-left: 20px;
	overflow: hidden;
	position: relative;

	&::before {
		content: '×';
		color: #f00;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
	}
`;

const LoadingButtonsWrapper = styled.div`
	font-size: 2em;
`;

export default function MyRoutesDetails({ onBack }) {
	const { t } = useTranslation();
	const {
		map,
		myRoutes,
		bikeSettings,
		publicTransportSettings,
		setActiveRoute,
		setCurrentRoute,
	} = useContext(Context);
	const setPoints = useDrawPointMarkers();
	const setLegs = useDrawLegsTransportationMarkers();
	const [route, setRoute] = useState(null);
	const [error, setError] = useState(false);

	const { location } = useHistory();

	const id = location.pathname
		.split('/')
		.pop()
		.split('@')
		.shift();

	// eslint-disable-next-line eqeqeq
	const data = myRoutes.find(myRoute => myRoute.id == id);
	// as long as we don't know what the id is (string or number)

	useEffect(() => {
		if (data?.points) setPoints(data.points);
	}, [data, setPoints]);

	useEffect(() => {
		// todo: a leg might be displayed on top of a point!
		if (route?.legs) setLegs(route.legs);
	}, [route, setLegs]);

	useEffect(() => {
		if (map) {
			if (data) {
				const lineLayer = getOrCreateLinesLayer(map, 'lines');
				lineLayer.setSource(new VectorSource());

				let date = stringToDate(data.date);
				const today = new Date();
				today.setHours(0);
				today.setMinutes(0);
				if (date < today) {
					date = new Date();
				}

				fetchers
					.fetchBikeRoute(
						data.points,
						data.routeType,
						{
							routeType: bikeSettings.route,
							fitnessLevel: bikeSettings.fitness,
							accessKm: publicTransportSettings.maxDistanceAccess,
							exitKm: publicTransportSettings.maxDistanceExit,
							date,
						},
						bikeSettings,
					)
					.then(newRoute => {
						drawRouteOnMap(map, newRoute, data.routeType);

						// eslint-disable-next-line no-param-reassign
						newRoute.saved = true;

						if (newRoute === false) {
							throw new Error('Route not found.');
						}

						// eslint-disable-next-line no-param-reassign
						newRoute.routeType = data.routeType;
						activateRouteType(lineLayer, data.routeType);
						setRoute(newRoute);
					})
					.catch(() => {
						setError('error.fetching');
					});
			} else {
				setError('myRoutes.errors.notFound');
			}
		}
	}, [
		map,
		data,
		bikeSettings,
		publicTransportSettings.maxDistanceAccess,
		publicTransportSettings.maxDistanceExit,
		setActiveRoute,
	]);

	if (error !== false) {
		return (
			<>
				<SidebarHeader
					backgroundColor={colors.white}
					onBack={onBack}
					backButtonColor={colors.cyandark}
					isSmall
				/>
				<Content>
					<RouteTypeError>{t('error.headline')}</RouteTypeError>
					<p>{t(error)}</p>
				</Content>
			</>
		);
	}

	if (route === null) {
		return (
			<>
				<SidebarHeader
					backgroundColor={colors.white}
					onBack={onBack}
					backButtonColor={colors.cyandark}
					isSmall
				/>
				<Content>
					<TextBlockLoadingIndicator rows={2} />
					<TextBlockLoadingIndicator rows={0} />
					<ImageLoadingIndicator />
					<TextBlockLoadingIndicator rows={2} />
					<TextBlockLoadingIndicator rows={2} />
					<TextBlockLoadingIndicator rows={1} />
					<LoadingButtonsWrapper className="full-width">
						<TextBlockLoadingIndicator rows={0} />
					</LoadingButtonsWrapper>
				</Content>
			</>
		);
	}

	return (
		<RouteDetails
			route={route}
			changeUri={false}
			onBack={() => {
				const layer = getOrCreateLinesLayer(map, 'lines');
				layer.removeAllFeatures();
				setCurrentRoute(null);
				setActiveRoute(null);
				activateRouteType(layer, null);
				updateUri(false, `${mapRoot}/meine-routen`);
			}}
		/>
	);
}

MyRoutesDetails.propTypes = {
	onBack: PropTypes.func,
};

MyRoutesDetails.defaultProps = {
	onBack: () => {},
};
