import styled from 'styled-components';

const LoadingImage = styled.div`
	background-color: #eee;
	margin: 0.4em 0;
	height: 200px;
	width: 100%;
`;

export default LoadingImage;
