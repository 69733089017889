import { SHARING_API_BASE } from './constants';

const urlCheckCache = {};

/**
 * Check, if a url is valid / exists and has not gone (status code 404 or 410)
 *
 * @name checkUrl
 * @function
 * @param {string} url The url to check.
 * @return {object} Result of the url check.
 */
export default function checkUrl(url) {
	if (typeof urlCheckCache[url] === 'undefined') {
		urlCheckCache[url] = fetch(`${SHARING_API_BASE}check-url?url=${encodeURIComponent(url)}`)
			.then(response => response.json())
			.catch(e => {
				delete urlCheckCache[url];
				throw e;
			});
	}

	return urlCheckCache[url];
}
