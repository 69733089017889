import styled, { css, keyframes } from 'styled-components';
import media from '../../utils/Breakpoints';
import { colors, fonts, boxshadow } from '../../utils/config';

const SUBTLE_BOX_SHADOW = '0px 2px 4px 0 rgba(0,0,0,0.3)';

export const SearchBar = styled.div`
	position: fixed;
	top: 8px;
	left: 8px;
	width: 96%;
	width: calc(100% - 16px);
	margin: 0 auto;
	display: flex;
	align-items: center;
	background-color: ${colors.white};
	border: 1px solid ${colors.graylight};
	box-shadow: ${({ subtleBoxShadow, boxShadowOnlyOnInteraction }) => {
		if (boxShadowOnlyOnInteraction) return 'none';
		if (subtleBoxShadow) return SUBTLE_BOX_SHADOW;
		return boxshadow.default;
	}};
	border-radius: 5px;
	padding: 0;
	font-weight: normal;
	transition: box-shadow 0.2s ease;
	z-index: 30;

	${media.iphone`
		max-width: 358px;
	`}

	${media.lg`
		// position: relative;
		top: 16px;
		left: 16px;
		margin: 0 auto 0 0;
		width: 460px;
		max-width: none;
	`}

	${media.xl`
		top: 32px;
		left: 32px;
	`}

	${props =>
		props.slimLayout
			? css`
					height: 40px;

					${media.lg`
						height: 52px;
					`}

					svg {
						width: 12px;
						height: 12px;
					}
			  `
			: css`
					height: 48px;

					${media.lg`
						height: 58px;
					`}

					@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
						transform: scale(0.752);
						transform-origin: top left;
					}

					svg {
						width: 20px;
						height: 20px;
					}
			  `}

	${props =>
		props.positionrelative &&
		css`
			&& {
				flex-grow: 1;
				position: relative;
				top: 0;
				left: 0;
				width: auto;
				max-width: 100%;
			}
		`}

	${props =>
		props.searchResultListVisible &&
		css`
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		`}

	${props =>
		props.categoryMenuVisible &&
		css`
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		`}

	&:hover & {
		box-shadow: ${({ subtleBoxShadow }) => (subtleBoxShadow ? SUBTLE_BOX_SHADOW : boxshadow.hover)};
	}

	.active & {
		box-shadow: ${({ subtleBoxShadow }) => (subtleBoxShadow ? SUBTLE_BOX_SHADOW : boxshadow.focus)};
	}
`;

export const SearchBarIcon = styled.div`
	background: #fff;
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 1) 15%,
		rgba(255, 255, 255, 1) 100%
	);
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(0, -50%);

	&:hover,
	&:focus,
	&:active {
		cursor: pointer;
		outline: none;

		&::before {
			border-radius: 50%;
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: ${colors.graylight};
		}
	}

	${({ slimLayout }) =>
		slimLayout
			? css`
					width: 40px;
					height: 32px;

					&:hover,
					&:focus,
					&:active {
						&::before {
							width: 32px;
							height: 32px;
						}
					}

					${media.lg`
						width: 50px;
						height: 46px;
					`}
			  `
			: css`
					width: 46px;
					height: 40px;

					&:hover,
					&:focus,
					&:active {
						&::before {
							width: 40px;
							height: 40px;
						}
					}

					${media.lg`
						width: 60px;
						height: 100%;
					`}
			  `}

	svg {
		max-height: 16px;
		width: auto;
		z-index: 1;

		${media.lg`
			max-height: none;
		`}
	}
`;

export const Input = styled.input`
	${'' /* width: 300px; */}
	flex-grow: 1;
	border: 0;
	padding: 0;
	font-size: 14px;
	line-height: 40px;
	height: 40px;
	padding-right: 42px;
	color: ${colors.black};
	font-family: ${fonts.bold};
	font-weight: normal;

	&::-webkit-input-placeholder {
		color: ${colors.graydark};
		font-weight: normal;
	}
	&::-moz-placeholder {
		color: ${colors.graydark};
		font-weight: normal;
	}
	&:-ms-input-placeholder {
		color: ${colors.graydark};
		font-weight: normal;
	}

	&::-ms-clear {
		display: none;
	}

	${media.lg`
		font-size: 18px;
		padding-left: 8px;
	`}

	${({ slimLayout }) =>
		slimLayout
			? css`
					line-height: 32px;
					height: 32px;
					padding-left: 16px;

					${media.lg`
						line-height: 46px;
						height: 46px;
					`}
			  `
			: css`
					line-height: 40px;
					height: 40px;

					${media.lg`
						line-height: 50px;
						height: 50px;
					`}
			  `}
`;

const rotate = keyframes`
	0% {
		transform: rotate(0deg) translate(-50%, -50%);
	}
	100% {
		transform: rotate(360deg) translate(-50%, -50%);
	}
`;

export const SearchLoading = styled.div`
	position: absolute;
	right: 35px;
	top: 50%;
	background-color: #fff;
	border-radius: 15px;
	border: 3px solid ${colors.graylight};
	border-top-color: ${colors.orange};
	width: 30px;
	height: 30px;
	animation: ${rotate} 1s linear infinite;
	transform: translate(-50%, -50%);
	transform-origin: top left;

	${({ slimLayout }) =>
		slimLayout
			? css`
					right: 32px;
					width: 20px;
					height: 20px;

					${media.lg`
						right: 45px;
					`}
			  `
			: css`
					right: 35px;
					width: 30px;
					height: 30px;

					${media.lg`
						right: 65px;
					`}
			  `}
`;
