import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, breakpoints as media } from 'shared';

import { ReactComponent as Close } from './icons/close.svg';

const StyledHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 64px;
	margin: 0;
	padding: 0 32px;
	border-bottom: 1px solid ${colors.graylight};
	background-color: ${colors.white};
	z-index: 3;

	${media.lg`
		height: 96px;
		padding: 0 48px;
	`}
`;

const Headline = styled.h2`
	font-size: 20px;

	${media.lg`
		font-size: 24px;
	`}
`;

const IconWrapper = styled.div`
	width: 20px;
	height: 20px;

	&:hover {
		cursor: pointer;
	}
`;

function Header({ children, onClose }) {
	return (
		<StyledHeader>
			<Headline>{children}</Headline>
			<IconWrapper>
				<Close onClick={onClose} />
			</IconWrapper>
		</StyledHeader>
	);
}

Header.propTypes = {
	onClose: PropTypes.func,
	children: PropTypes.node.isRequired,
};

Header.defaultProps = {
	onClose: () => {},
};

export default Header;
