import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from '../../utils/Breakpoints';
import { colors } from '../../utils/config';

const StyledSwitch = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 50px;
`;

const Text = styled.label`
	color: ${colors.black};
	display: flex;
	flex-grow: 1;
	align-items: center;
	padding: 12px 0;
	height: 100%;

	> svg {
		margin-right: 5px;
		width: ${({ smallIcon }) => (smallIcon ? 20 : 30)}px;
		height: auto;
	}

	&:hover {
		cursor: pointer;
	}
`;

const Button = styled.label`
	display: block;
	position: relative;
	background-color: ${colors.grayblue};
	border-radius: 14px;
	cursor: pointer;
	flex-grow: 0;
	flex-shrink: 0;
	width: 42px;
	height: 22px;
	margin-left: 16px;
	transition: background-color 0.4s;

	${media.lg`
		width: 52px;
		height: 28px;
		margin-left: 24px;
	`}

	&::before {
		content: '';
		position: absolute;
		background-color: ${colors.white};
		border-radius: 50%;
		height: 18px;
		width: 18px;
		left: 2px;
		top: 2px;
		transition: transform 0.4s;

		${media.lg`
			height: 24px;
			width: 24px;
		`}
	}
`;

const Input = styled.input.attrs({ type: 'checkbox' })`
	opacity: 0;
	width: 0;
	height: 0;

	&:checked + ${Button} {
		background-color: ${colors.cyandark};

		&::before {
			transform: translateX(20px);

			${media.lg`
				transform: translateX(24px);
			`}
		}
	}
`;

class Switch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isChecked: false,
		};
	}

	componentDidMount() {
		this.setCheckedState(this.props.defaultChecked);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.defaultChecked !== this.props.defaultChecked ||
			nextState.isChecked !== this.state.isChecked
		) {
			return true;
		}
		return false;
	}

	componentDidUpdate() {
		this.setCheckedState(this.props.defaultChecked);
	}

	setCheckedState = isChecked => {
		this.setState({ isChecked });
	};

	toggleState = () => {
		if (this.props.onChange) {
			this.props.onChange(!this.state.isChecked);
		}

		this.setState(prevState => ({
			isChecked: !prevState.isChecked,
		}));
	};

	render() {
		const { id, value, label, icon: Icon, smallIcon } = this.props;
		const { isChecked } = this.state;

		return (
			<StyledSwitch>
				<Text htmlFor={id} smallIcon={smallIcon}>
					{Icon && <Icon />}
					<span>{label}</span>
				</Text>
				<Input
					type="checkbox"
					checked={isChecked}
					id={id}
					value={value}
					onChange={e => {
						this.toggleState(e);
					}}
				/>
				<Button htmlFor={id} />
			</StyledSwitch>
		);
	}
}

Switch.propTypes = {
	defaultChecked: PropTypes.bool,
	id: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	icon: PropTypes.object,
	smallIcon: PropTypes.bool,
	onChange: PropTypes.func,
};

Switch.defaultProps = {
	defaultChecked: false,
	smallIcon: false,
};

export default Switch;
