import styled from 'styled-components';

export const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 48px;
	height: 48px;
	margin-right: 1rem;

	border: 1px solid #e6e6e6;
	border-radius: 0.4rem;

	> img {
		max-width: 36px;
		object-fit: contain;
	}
`;
