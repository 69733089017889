import React, { Children, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../utils/config';

const StyledButton = styled.button`
	border: 0;
	border-radius: 3px;
	display: block;
	font-size: 15px;
	line-height: 19px;
	margin-bottom: 16px;
	padding: 8px 40px;
	position: relative;
	text-align: center;
	width: 100%;
	z-index: 0;

	> span {
		color: ${colors.white};
	}

	&:hover {
		cursor: pointer;
	}

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 6px;
		left: 12px;
		width: 18px;
		height: 18px;
		background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke-width='2' stroke='%23fff' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M8.467 17H17V1H1v8.533'/%3E%3Cpath d='M10 13.25V8H4.75M10 8l-7 7'/%3E%3C/g%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-size: 70%;
		background-position: center bottom;
	}

	.marker--sharing-regiorad &,
	.marker__tooltip--regiorad & {
		background-color: #06507f;
	}

	.marker--sharing-stella &,
	.marker__tooltip--stella & {
		background-color: #18ace9;
	}

	.marker--sharing-stadtmobil &,
	.marker__tooltip--stadtmobil & {
		background-color: #e2000f;
	}

	.marker--sharing-car2go &,
	.marker__tooltip--car2go & {
		background-color: #1498e3;
	}

	.marker--sharing-flinkster &,
	.marker__tooltip--flinkster & {
		background-color: #e2000f;
	}
`;

const touchstart = e => e.target.setAttribute('data-touchstart', Date.now());

const SharingButton = ({
	iosDeeplink,
	iosStore,
	androidDeeplink,
	androidStore,
	windowsDeeplink,
	windowsStore,
	uri,
	openPopup,
	color,
	children,
}) => {
	const buttonRef = createRef();

	const tryApp = (deeplink, store, storeName) => {
		let timeout;

		if (!deeplink) {
			document.location = uri;
			return;
		}

		function preventPopup() {
			clearTimeout(timeout);
			window.removeEventListener('blur', preventPopup);
			window.removeEventListener('pagehide', preventPopup);
			document.removeEventListener('visibilitychange', preventPopup);
		}

		window.addEventListener('blur', preventPopup);
		window.addEventListener('pagehide', preventPopup);
		document.addEventListener('visibilitychange', preventPopup);

		timeout = setTimeout(() => {
			preventPopup();
			if (store) {
				// Deeplink not working, open store?
				openPopup('Die App dieses Anbieters scheint nicht installiert zu sein.', [
					{
						label: `App aus dem ${storeName} herunterladen`,
						uri: store,
					},
					{
						label: 'Zur Webseite des Anbieters gehen',
						uri,
					},
				]);
			} else {
				document.location = uri;
			}
		}, 400);

		document.location = deeplink;
	};

	const handleClick = e => {
		e.preventDefault();
		e.stopPropagation();

		const ua = window.navigator.userAgent;

		if (/Android/.test(ua)) {
			tryApp(androidDeeplink, androidStore, 'Play Store');
		} else if (/(iPhone|iPad|iPod)/.test(ua)) {
			tryApp(iosDeeplink, iosStore, 'App Store');
		} else if (/(Windows Phone|iemobile|WPDesktop)/i.test(ua)) {
			tryApp(windowsDeeplink, windowsStore, 'Microsoft Store');
		} else {
			document.location = uri;
		}
	};

	const touchend = e => {
		if (parseInt(e.target.getAttribute('data-touchstart'), 10) + 200 > Date.now()) {
			handleClick(e);
		}
	};

	useEffect(() => {
		const button = buttonRef.current;
		button.addEventListener('click', handleClick, false);
		button.addEventListener('touchstart', touchstart, false);
		button.addEventListener('touchend', touchend, false);
	});

	return (
		<StyledButton type="button" ref={buttonRef}>
			<span>{Children.toArray(children)}</span>
		</StyledButton>
	);
};

SharingButton.propTypes = {
	iosDeeplink: PropTypes.any,
	iosStore: PropTypes.any,
	androidDeeplink: PropTypes.any,
	androidStore: PropTypes.any,
	windowsDeeplink: PropTypes.any,
	windowsStore: PropTypes.any,
	uri: PropTypes.string.isRequired,
	openPopup: PropTypes.func,
	children: PropTypes.node.isRequired,
};

SharingButton.defaultProps = {
	openPopup: () => {},
};

export default SharingButton;
