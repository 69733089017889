import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { fetchers, routeCache } from 'shared';
import initialState from './initialState';
import { generateMyRoute, relocateMap, updatePointMarkers } from './helpers';
import { mapRoot, updateUri, history } from '../../utils';

const Context = createContext(null);
export default Context;

let debugEnabled = false;
let usePseudoRouting = true;
if ('URLSearchParams' in window) {
	const params = new URLSearchParams(window.location.search);
	if (params.get('debug') === '1') {
		debugEnabled = true;

		if (params.get('usePseudoRouting') === '0') {
			usePseudoRouting = false;
		}
	}
}

function getBoolean(value, fallback) {
	if (typeof value === 'boolean') {
		return value;
	}
	return fallback;
}

function getSearchParams(points) {
	const searchParams = {
		origin: null,
		destination: null,
	};
	if (points.length > 1 && points[0]) {
		searchParams.origin = points[0].id;
	}
	if (points.length > 0 && points[points.length - 1]) {
		searchParams.destination = points[points.length - 1].id;
	}

	return searchParams;
}

async function getResultById(id, fetchOptions = {}) {
	try {
		const response = await fetchers.searchLocationsAndTipps(id, fetchOptions);
		const results = [];
		const result = results.concat(response.locations).concat(response.tipps || []);
		return result[0];
	} catch (error) {
		if (error.name === 'AbortError') {
			// Fetch aborted
		} else {
			// eslint-disable-next-line no-console
			console.error('Uh oh, an error!', error);
		}
	}
	return null;
}

const routePlanningCheck = new RegExp(`^${mapRoot}(/routenplanung(/details)?)?(@.+)?$`);

export function ContextProvider({ children }) {
	//
	// Map
	//
	const [map, setMap] = useState(null);

	//
	// Markers
	//
	const [stopMarkers, setStopMarkers] = useState([]);
	const [positionMarkers, setPositionMarkers] = useState([]);
	const [poiMarkers, setPoiMarkers] = useState([]);
	const [sharingMarkers, setSharingMarkers] = useState([]);
	const [pointMarkers, setPointMarkers] = useState([]);
	const [mobilityIcons, setMobilityIcons] = useState([]);
	const [bikeMarker, setBikeMarker] = useState();
	const [locationMarker, setLocationMarker] = useState();

	//
	// Date
	//
	const [date, setDate] = useState(initialState.date);

	//
	// Popup
	//
	const [popup, setPopup] = useState(null);

	const openPopup = useCallback((text, options) => {
		if (text === null) {
			setPopup(null);
		} else {
			setPopup({ text, options });
		}
	}, []);

	//
	// Is routeplanning active?
	//
	const [routePlanningActive, setRoutePlanningActive] = useState(
		!!window.location.pathname.match(routePlanningCheck),
	);

	//
	// Left sidebar
	//
	const [isLeftSidebarOpen, setLeftSidebarOpen] = useState(initialState.isLeftSidebarOpen);
	const toggleLeftSidebar = useCallback(
		(newState) => {
			setLeftSidebarOpen(getBoolean(newState, !isLeftSidebarOpen));
			setDate(new Date());
		},
		[isLeftSidebarOpen],
	);

	//
	// Settings sidebar
	//
	const [isSettingsSidebarOpen, setSettingsSidebarOpen] = useState(
		initialState.isSettingsSidebarOpen,
	);
	const toggleSettingsSidebar = useCallback(
		(newState) => {
			setSettingsSidebarOpen(getBoolean(newState, !isSettingsSidebarOpen));
		},
		[isSettingsSidebarOpen],
	);

	//
	// Bike settings
	//
	const [bikeSettings, updateBikeSettings] = useState(initialState.bikeSettings);
	const setBikeSettings = ({ setting, value }) => {
		const newBikeSettings = {
			...bikeSettings,
			[setting]: value,
		};

		window.localStorage.setItem('BIKE_SETTINGS', JSON.stringify(newBikeSettings));

		updateBikeSettings(newBikeSettings);
	};

	//
	// Public transport settings,
	//
	const [publicTransportSettings, updatePublicTransportSettings] = useState(
		initialState.publicTransportSettings,
	);
	const setPublicTransportSettings = ({ setting, value }) => {
		const newPublicTransportSettings = {
			...publicTransportSettings,
			[setting]: value,
		};

		window.localStorage.setItem(
			'PUBLIC_TRANSPORT_SETTINGS',
			JSON.stringify(newPublicTransportSettings),
		);

		updatePublicTransportSettings(newPublicTransportSettings);
	};

	//
	// Settings
	//
	const [settings, updateSettings] = useState(initialState.settings);
	const setSettings = ({ setting, value }) => {
		const newSettings = {
			...settings,
			[setting]: value,
		};

		window.localStorage.setItem('SETTINGS', JSON.stringify(newSettings));

		updateSettings(newSettings);
	};

	//
	// map type
	//
	const [mapType, setMapType] = useState(initialState.mapType);

	//
	// Points for route planning
	//
	const [points, updatePoints] = useState([]);
	const currentPoints = useRef(initialState.points);
	const pointsStr = useRef('');
	const removePoint = useCallback(
		(point) => {
			const newPoints = currentPoints.current.filter((p) => p === null || p.id !== point.id);

			updatePointMarkers(map, newPoints, removePoint, true, setPoints, setPointMarkers);
			relocateMap(map, newPoints);

			if (process.env.NODE_ENV === 'development') {
				window.localStorage.setItem('POINTS', JSON.stringify(newPoints));
			}

			currentPoints.current = newPoints;
			updatePoints(newPoints);

			setHoverRoute(null);
			setActiveRoute(null);
			const searchParams = getSearchParams(newPoints);
			updateUri(false, `${mapRoot}/routenplanung`, searchParams);
			setLeftSidebarOpen(true);
		},
		[map],
	);

	const setPoints = useCallback(
		(newPoints, shouldUpdateUri, shouldRelocateMap) => {
			let resetLines = false;
			const checkedPoints = newPoints.filter((p) => p);
			if (checkedPoints.length > 1) {
				let newPointsStr = '';
				checkedPoints.forEach((point) => {
					newPointsStr += `${point.id}_` || '_';
				});
				if (newPointsStr !== pointsStr.current) {
					pointsStr.current = newPointsStr;
					resetLines = true;
				}
			}

			updatePointMarkers(map, newPoints, removePoint, resetLines, setPoints, setPointMarkers);
			if (getBoolean(shouldRelocateMap, true)) {
				relocateMap(map, newPoints);
			}

			if (process.env.NODE_ENV === 'development') {
				window.localStorage.setItem('POINTS', JSON.stringify(newPoints));
			}

			currentPoints.current = newPoints;

			updatePoints(newPoints);
			routeCache.setRoute(newPoints);

			if (getBoolean(shouldUpdateUri, true)) {
				const searchParams = getSearchParams(newPoints);
				updateUri(false, `${mapRoot}/routenplanung`, searchParams);
				setLeftSidebarOpen(true);
			}
		},
		[map],
	);
	const addPoint = useCallback(
		(point, inBetween = true) => {
			// Remove null values
			const newPoints = currentPoints.current.filter((p) => p !== null);

			// eslint-disable-next-line no-param-reassign
			point.ts = Date.now();

			if (newPoints.length === 0) {
				// If there are no points, add point depending on "inBetween" variable
				if (inBetween) {
					newPoints.push(null);
					newPoints.push(point);
				} else {
					newPoints.push(point);
					newPoints.push(null);
				}
			} else if (inBetween) {
				// Add new point as point before the final position
				newPoints.splice(newPoints.length - 1, 0, point);
			} else {
				// Add new point to the end
				newPoints.push(point);
			}

			currentPoints.current = newPoints;
			setPoints(newPoints);
		},
		[map],
	);

	//
	// Routes
	//
	const [routes, updateRoutes] = useState(initialState.routes);
	const setRoutes = (newRoutes) => {
		if (process.env.NODE_ENV === 'development') {
			window.localStorage.setItem('ROUTES', JSON.stringify(newRoutes));
		}

		updateRoutes(newRoutes);
	};

	//
	// Active route
	//
	const [activeRoute, setActiveRoute] = useState(null);

	//
	// Hover route,
	//
	const [hoverRoute, setHoverRoute] = useState(initialState.hoverRoute);

	//
	// Current route (index)
	//
	const [currentRouteIndex, setCurrentRoute] = useState(null);

	//
	// My routes
	//
	const [myRoutes, setMyRoutes] = useState(initialState.myRoutes);
	const [isSavedModalOpen, setSavedModalOpen] = useState(false);
	const addMyRoute = () => {
		const myNewRoute = generateMyRoute(routes[currentRouteIndex], points, date);

		routes[currentRouteIndex].saved = true;

		const myNewRoutes = [...myRoutes, myNewRoute];

		window.localStorage.setItem('MY_ROUTES', JSON.stringify(myNewRoutes));

		setMyRoutes(myNewRoutes);
		setSavedModalOpen(true);
	};

	const removeMyRoute = (removeId) => {
		const myNewRoutes = myRoutes.filter(({ id }) => id !== removeId);

		window.localStorage.setItem('MY_ROUTES', JSON.stringify(myNewRoutes));

		setMyRoutes(myNewRoutes);
	};

	useEffect(() => {
		setMobilityIcons([]);
		document.getElementById('tooltip').style.display = 'none';
	}, [points]);

	useEffect(() => {
		if (map && initialState.points.length > 0) {
			setPoints(initialState.points, false);
			initialState.points = [];
		}
	}, [map]);

	// Handle loading origin and destination from URL
	useEffect(() => {
		if (!map) return;
		if (!('URLSearchParams' in window)) return;

		const query = new URLSearchParams(history.location.search);

		const origin = query.get('origin');
		const destination = query.get('destination');

		if (!origin && !destination) return;

		let signal;
		let pointAbortController;

		if ('AbortController' in window) {
			pointAbortController = new window.AbortController();
			signal = pointAbortController.signal;
		}

		const pointRequests = [];

		if (origin) {
			pointRequests.push(getResultById(origin, { signal }));

			if (!destination) {
				// Make sure to have origin on first position
				pointRequests.push(Promise.resolve(null));
			}
		}

		if (destination) {
			pointRequests.push(getResultById(destination, { signal }));
		}

		Promise.allSettled(pointRequests).then((promises) => {
			const newPoints = promises.map((promise) =>
				promise.status === 'fulfilled' ? promise.value : null,
			);

			updatePoints(newPoints);
			updatePointMarkers(map, newPoints, removePoint, true, setPoints, setPointMarkers);
		});
	}, [map]);

	//
	// Abort controller to abort previous requests,
	//
	const [abortController, setAbortController] = useState();

	return (
		<Context.Provider
			value={{
				map,
				setMap,

				stopMarkers,
				setStopMarkers,
				positionMarkers,
				setPositionMarkers,
				poiMarkers,
				setPoiMarkers,
				sharingMarkers,
				setSharingMarkers,
				mobilityIcons,
				setMobilityIcons,
				pointMarkers,
				setPointMarkers,
				bikeMarker,
				setBikeMarker,
				locationMarker,
				setLocationMarker,

				date,
				setDate,

				popup,
				openPopup,

				routePlanningActive,
				setRoutePlanningActive,

				isLeftSidebarOpen,
				toggleLeftSidebar,
				isSettingsSidebarOpen,
				toggleSettingsSidebar,

				bikeSettings,
				setBikeSettings,
				publicTransportSettings,
				setPublicTransportSettings,
				settings,
				setSettings,

				mapType,
				setMapType,

				points,
				setPoints,
				addPoint,

				routes,
				setRoutes,

				activeRoute,
				setActiveRoute,
				hoverRoute,
				setHoverRoute,
				currentRouteIndex,
				setCurrentRoute,

				myRoutes,
				addMyRoute,
				removeMyRoute,
				isSavedModalOpen,
				closeSavedModal: () => setSavedModalOpen(false),

				abortController,
				setAbortController,

				debugEnabled,
				usePseudoRouting,
			}}
		>
			{children}
		</Context.Provider>
	);
}

ContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
