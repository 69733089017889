import { API_BASE } from './constants';

const defaultsOptions = {
	SpEncId: 0,
	language: 'de',
	outputFormat: 'rapidJSON',
	coordOutputFormat: 'WGS84[DD.DDDDD]',
	type_sf: 'any',
};

const coordDataCache = {};

/**
 * Fetch Coord data for the given latitude and longitude
 *
 * @name fetchCoordData
 * @function
 * @param {number} lat          The latitude value of the point.
 * @param {number} lng          The longitude value of the point.
 * @param {object} fetchOptions Options passed to the fetch.
 *
 * @return {object} The returned point data.
 */
export default (lat, lng, fetchOptions = {}) => {
	const cacheId = `${lat.toFixed(5)}|${lng.toFixed(5)}`;

	if (typeof coordDataCache[cacheId] === 'undefined') {
		const options = {
			name_sf: `coord:${lng}:${lat}:WGS84[dd.ddddd]`,
			...defaultsOptions,
		};

		const query = Object.keys(options)
			.map(option => `${option}=${encodeURIComponent(options[option])}`)
			.join('&');

		// eslint-disable-next-line no-param-reassign
		fetchOptions.cache = fetchOptions.cache || 'force-cache';

		coordDataCache[cacheId] = fetch(
			`${API_BASE}XML_STOPFINDER_REQUEST?${query}&${window.additionalApiParams}`,
			fetchOptions,
		).then(response => response.json());
	}

	return coordDataCache[cacheId];
};
