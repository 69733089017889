import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icons } from '../Icons';
import { colors, fonts, boxshadow, breakpoints } from '../../utils';

const StyledTooltip = styled.div`
	position: relative;
	display: block;
	width: 100%;
	min-width: 180px;
	max-width: 240px;
	padding: 8px;
	text-align: center;
	border-radius: 3px;
	background-color: ${colors.white};
	border: 1px solid ${colors.graylight};
	box-shadow: ${boxshadow.default};
	margin: 50px;
	z-index: 2;

	&::before,
	&::after {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		top: auto;
		bottom: 0;
		left: 50%;
		border: solid transparent;
		border-width: 16px;
		pointer-events: none;
		transform: translate(-50%, 50%);
	}

	&::before {
		bottom: -17px;
		border-color: rgba(0, 0, 0, 0);
		border-top-color: rgba(0, 0, 0, 0.1);
		filter: blur(2px);
	}

	&::after {
		bottom: -16px;
		border-color: rgba(255, 255, 255, 0);
		border-top-color: #fff;
		z-index: 3;
	}

	${props =>
		props.anchor === 'top' &&
		`
			&::before,
			&::after {
				transform: translate(-50%, -50%);
			}

			&::before {
				top: -18px;
				border-color: rgba(0, 0, 0, 0);
				border-bottom-color: rgba(0, 0, 0, 0.1);
			}

			&::after {
				top: -16px;
				border-color: rgba(255, 255, 255, 0);
				border-bottom-color: #fff;
			}
	`}

	${props =>
		props.anchor === 'left' &&
		`
			&::before,
			&::after {
				top: 50%;
				transform: translate(-50%, -50%);
			}

			&::before {
				left: -16px;
				border-color: rgba(0, 0, 0, 0);
				border-right-color: rgba(0, 0, 0, 0.1);
			}

			&::after {
				left: -12px;
				border-color: rgba(255, 255, 255, 0);
				border-right-color: #fff;
			}
	`}

	${props =>
		props.anchor === 'right' &&
		`
			position: absolute;
			left: 0;
			top: 0;
			transform: translate(-95%, -10%);
			margin: 0 0 0 -30px;

			&::before,
			&::after {
				top: 50%;
				left: auto;
				transform: translate(50%, -50%);
			}

			&::before {
				right: -16px;
				border-color: rgba(0, 0, 0, 0);
				border-left-color: rgba(0, 0, 0, 0.1);
			}

			&::after {
				right: -12px;
				border-color: rgba(255, 255, 255, 0);
				border-left-color: #fff;
			}
	`}

	${props =>
		props.anchor === 'right' &&
		breakpoints.md`
		transform: translate(-100%, 10%);
	`}
`;

const TooltipInner = styled.div`
	${props =>
		props.flex &&
		`
			display: flex;
			align-items: center;
	`}
`;

const TextWrapper = styled.div`
	text-align: left;
	margin-left: 8px;
`;

const Title = styled.h2`
	font-size: 18px;
	line-height: 23px;
	font-family: ${fonts.default};

	${props =>
		props.strong &&
		`
			font-family: ${fonts.bold};
	`}
`;

const Subtitle = styled.p`
	font-size: 15px;
	line-height: 19px;
	color: ${colors.black};

	${props =>
		props.delayed &&
		`
			color: ${colors.orange};
	`}
`;

export default function Tooltip({
	type,
	anchor,
	title,
	subtitle,
	vehicletype,
	departuretime,
	delayed,
}) {
	let tooltipType = '';
	let iconType = '';

	if (Icons[vehicletype]) {
		const Icon = Icons[vehicletype];
		iconType = <Icon />;
	}

	if (type === 'vehicle') {
		tooltipType = (
			<TooltipInner flex>
				{iconType}
				<TextWrapper>
					<Title strong>{title}</Title>
					<Subtitle delayed={delayed}>{departuretime}</Subtitle>
				</TextWrapper>
			</TooltipInner>
		);
	} else if (type === 'detail') {
		tooltipType = (
			<TooltipInner>
				<Title>{title}</Title>
				<p style={{ backgroundColor: 'yellow' }}>DepartureList</p>
				<p style={{ backgroundColor: 'yellow' }}>Button</p>
			</TooltipInner>
		);
	} else {
		tooltipType = (
			<TooltipInner>
				<Title>{title}</Title>
				<Subtitle>{subtitle}</Subtitle>
			</TooltipInner>
		);
	}

	return <StyledTooltip anchor={anchor}>{tooltipType}</StyledTooltip>;
}

Tooltip.propTypes = {
	type: PropTypes.oneOf(['textonly', 'vehicle']).isRequired,
	anchor: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	vehicletype: PropTypes.oneOf(['bus', 'sbahn', 'ubahn']),
	departuretime: PropTypes.string,
	delayed: PropTypes.bool,
};

Tooltip.defaultProps = {
	anchor: 'bottom',
};
