import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { colors, SidebarHeader, MultiSwitch, calculateDistance, fetchers } from 'shared';

// import { useRouteMatch } from 'react-router-dom';
import RouteList from '../RouteList';
import BikeRouteDetails from './details';
import { mapRoot, updateUri } from '../../utils';

import Context from '../context';
import { ScrollContext } from '../ScrollContextProvider';

const SWITCH_ITEMS = [
	{
		value: 'az',
		label: 'bikeRoutes.switch.az',
	},
	{
		value: 'distanceToMapCenter',
		label: 'bikeRoutes.switch.surrounding',
	},
	{
		value: 'distance',
		label: 'bikeRoutes.switch.distance',
	},
	{
		value: 'height',
		label: 'bikeRoutes.switch.height',
	},
];

const SORTING = {
	az: (a, b) => a.name.localeCompare(b.name),
	azReversed: (a, b) => a.name.localeCompare(b.name) * -1,
	distanceToMapCenter: (a, b) => {
		const location = (
			window.location.href.replace(/.+@([^@]+)$/, '$1') || '48.78356,9.18145'
		).split(',');
		const lat = parseFloat(location[0]);
		const lng = parseFloat(location[1]);
		const distanceA = calculateDistance(a.origin.lat, a.origin.lng, lat, lng);
		const distanceB = calculateDistance(b.origin.lat, b.origin.lng, lat, lng);
		return distanceA - distanceB;
	},
	distanceToMapCenterReversed: (a, b) => SORTING.distanceToMapCenter(b, a),
	distance: (a, b) => a.distance - b.distance,
	distanceReversed: (a, b) => b.distance - a.distance,
	height: (a, b) => a.up - b.up,
	heightReversed: (a, b) => b.up - a.up,
};

const Content = styled.div`
	padding: 1rem;
`;

const StyledTitle = styled.h4`
	margin-bottom: 0.5rem;
`;

function useRoutes() {
	const [filter, setFilter] = useState(SWITCH_ITEMS[0].value);
	const [routes, setRoutes] = useState();
	const [search, setSearch] = useState();
	const [activeId, setActiveId] = useState(false);

	useEffect(() => {
		if (!routes) {
			fetchers.fetchThemeRoutes().then(setRoutes);
		}
	}, [routes]);

	return {
		routes: routes
			? routes
				.filter(route =>
					search ? route.name.toLowerCase().includes(search.toLowerCase()) : true,
				)
				.sort(SORTING[filter])
			: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(i => ({
				loadingId: `loading-route-${i}`,
			  })),
		filter,
		setFilter,
		search,
		setSearch,
		activeId,
		setActiveId,
	};
}

const StyledHeader = styled(SidebarHeader)`
	flex-direction: column;
	justify-content: space-between;
`;

const StyledSearch = styled.input`
	margin: 1rem;
	border-radius: 0.2rem;
	border: none;
	padding: 0.5rem;
`;

export default function BikeRoutes({ onBack }) {
	const { t } = useTranslation();
	const { setRoutePlanningActive } = useContext(Context);
	const { applyScrollPosition } = useContext(ScrollContext);
	const { routes, filter, setFilter, search, setSearch, activeId, setActiveId } = useRoutes();

	const { location } = useHistory();

	const routeId = location.pathname
		.split('/')
		.pop()
		.split('@')
		.shift();

	useEffect(() => {
		if (routeId.match(/^\d+$/)) {
			setActiveId(routeId);
		} else {
			setActiveId(false);
		}
	}, [routeId, setActiveId]);

	if (activeId) {
		const id = parseInt(activeId, 10);
		let sortedRoutes = routes;
		if (routes && ['distanceToMapCenter', 'distanceToMapCenterReversed'].indexOf(filter) > -1) {
			sortedRoutes = routes.sort(SORTING.az);
		}
		const index = sortedRoutes && sortedRoutes.findIndex(route => route.id === id);
		const meta = sortedRoutes && {
			routesCount: sortedRoutes.length,
			page: index + 1,
			nextRoute: (sortedRoutes[index + 1] && sortedRoutes[index + 1].id) || null,
			prevRoute: (sortedRoutes[index - 1] && sortedRoutes[index - 1].id) || null,
		};

		return (
			<BikeRouteDetails
				id={activeId}
				details={routes && routes.find(route => route.id === id)}
				meta={meta}
				setActiveId={setActiveId}
				onBack={() => {
					updateUri(false, `${mapRoot}/radrouten`);
					setRoutePlanningActive(false);
					applyScrollPosition();
				}}
			/>
		);
	}

	return (
		<>
			<StyledHeader
				backgroundColor={colors.orange}
				title={t('bikeRoutes.headline')}
				onBack={onBack}
			>
				<StyledSearch
					type="text"
					value={search}
					placeholder={t('bikeRoutes.searchPlaceholder')}
					onChange={e => setSearch(e.target.value)}
				/>
			</StyledHeader>
			<Content>
				<StyledTitle>{t('common.sorting')}</StyledTitle>
				<MultiSwitch
					items={SWITCH_ITEMS.map(({ value, label }) => ({ value, label: t(label) }))}
					selected={filter}
					onChange={newFilter => {
						setFilter(newFilter);
					}}
				/>
				<RouteList routes={routes} />
			</Content>
		</>
	);
}

BikeRoutes.propTypes = {
	onBack: PropTypes.func.isRequired,
};

BikeRoutes.defaultProps = {};

export { BikeRoutes };
export { default as BikeRouteDetails } from './details';
