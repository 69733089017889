import { fetchers, CONSTANTS } from 'shared';

/**
 * Save a route. Returns the hash for the saved route.
 *
 * @name saveRoute
 * @function
 * @param {string} routeType The routeType.
 * @param {array}  points    Points of the route.
 *
 * @return {Promise} A promise that resolves as soon as the route is saved with the latest used parameters for the given routeType.
 */
export function saveRoute(routeType, points) {
	const params = fetchers.getSavedParamsByType(routeType);
	const origin = points.shift();
	const destination = points.pop();

	if (params === undefined) {
		return Promise.resolve({
			success: false,
			error: {
				code: 1605714401597,
				message: `No params saved for routeType "${routeType}".`,
			},
		});
	}

	return fetch(`${CONSTANTS.SHARING_API_BASE}route`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		body: JSON.stringify({
			params,
			options: {
				routeType,
				origin,
				destination,
				vias: points,
			},
		}),
	}).then(response => response.json());
}

export function getParamsByHash(hash) {
	return fetch(`${CONSTANTS.SHARING_API_BASE}route/${hash}`).then(response => response.json());
}
