export default function getObjectSubtree(object, tree = []) {
	if (tree.length === 0) {
		return undefined;
	}
	let key = tree.shift();
	let response = object;
	while (typeof key !== 'undefined') {
		if (typeof response[key] === 'undefined') {
			return undefined;
		}
		response = response[key];
		key = tree.shift();
	}
	return response;
}
