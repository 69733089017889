export function cloneObject(obj) {
	if (obj === null) {
		return obj;
	}
	if (Array.isArray(obj)) {
		return obj.map(entry => cloneObject(entry));
	}
	if (typeof obj === 'object') {
		const newObj = {};
		Object.keys(obj).forEach(key => {
			newObj[key] = cloneObject(obj[key]);
		});
		return newObj;
	}
	return obj;
};
