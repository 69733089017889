import Feature from 'ol/src/Feature';
import Point from 'ol/src/geom/Point';
import { fromLonLat } from 'ol/src/proj';

import { getCoordOfPoint } from 'shared';

// const ol = window.OpenLayers;
// const size = new ol.Size(42, 60);
// const offset = new ol.Pixel(-(size.w / 2), 6 - size.h);

export default function createMarker(point, options = {}) {
	const coord = getCoordOfPoint(point);

	const marker = new Feature({
		...options,
		geometry: new Point(fromLonLat([coord[1], coord[0]])),
	});

	return marker;

	// const icon = new ol.Icon(emptyGif, point?.size || size, point?.offset || offset);

	// const marker = new ol.Marker(toEpsg3857(coord[0], coord[1]), icon);
	// marker.vvsType = point.type;
	// marker.type = 'point';

	// const { imageDiv } = marker.icon;
	// imageDiv.classList.add('marker');
	// imageDiv.classList.add(`marker--${point.type}`);

	// markerLayer.addMarker(marker);

	// return marker;
}
