import styled from 'styled-components';

const StyledPopupOverlay = styled.div`
	background-color: #fff;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	width: 400px;
	max-width: 80vw;
	z-index: 1000;

	p {
		font-weight: bold;
		margin-bottom: 10px;
		padding: 5px 12px;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
`;

export default StyledPopupOverlay;
