import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from '../../utils/Breakpoints';
import { fonts, colors, boxshadow } from '../../utils/config';

const StyledMenuTile = styled.a`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex: 0 0 104px;
	width: 104px;
	height: 144px;
	margin-right: 8px;
	overflow: hidden;
	text-align: center;
	user-select: none;
	border-radius: 5px;
	background-color: ${colors.white};
	box-shadow: ${boxshadow.default};
	transform-origin: 50% 50%;
	transition: box-shadow 0.1s ease, transform 0.1s ease;
	will-change: transform;
	transform: translateZ(0);

	&:last-child {
		margin-right: 0;
	}

	&:focus,
	&:hover {
		cursor: pointer;
		box-shadow: ${boxshadow.hover};
		transform: scale(1.02) translateZ(0);
	}

	${media.lg`
		flex: 0 0 144px;
		width: 144px;
		height: 200px;
	`}
`;

const MediaWrapper = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 96px;

	&&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 3px;
		background-color: ${props => props.borderColor};
	}

	${media.lg`
		height: 128px;
	`}

	img {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		transform: translateZ(0);
	}
`;

const Icon = styled.span`
	position: absolute;
	top: 80px;
	left: 50%;
	display: block;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: ${colors.white};
	box-shadow: ${boxshadow.menutileicon};
	transform: translate(-50%, 0);

	${media.lg`
		top: 104px;
		width: 42px;
		height: 42px;
	`}

	svg {
		transform: scale(0.8) translateZ(0);

		${media.lg`
			transform: none;
		`}
	}
`;

const Title = styled.h2`
	max-width: 70%;
	margin-bottom: 4px;
	font-size: 11px;
	line-height: 13px;
	font-family: ${fonts.default};
	font-weight: normal;
	color: ${colors.black};
	transform: translateZ(0);

	${media.lg`
		height: 32px;
		margin-bottom: 14px;
		font-size: 13px;
		line-height: 16px;
	`}

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		transform: scale(1.2);
		transform-origin: center;
	}
`;

export default function MenuTile({ title, accentColor, img, icon, svg, onClick }) {
	return (
		<StyledMenuTile onClick={onClick}>
			<MediaWrapper borderColor={accentColor}>
				<img src={img.src} alt={img.alt} />
			</MediaWrapper>
			<Icon>
				{icon && (
					<svg viewBox={icon.viewBox} width={icon.width} height={icon.height}>
						<path d={icon.path} fill={accentColor} />
					</svg>
				)}
				{svg}
			</Icon>
			<Title>{title}</Title>
		</StyledMenuTile>
	);
}

MenuTile.propTypes = {
	title: PropTypes.string.isRequired,
	accentColor: PropTypes.string,
	img: PropTypes.shape({
		src: PropTypes.string.isRequired,
		alt: PropTypes.string.isRequired,
	}).isRequired,
	icon: PropTypes.shape({
		viewBox: PropTypes.string.isRequired,
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired,
		path: PropTypes.string.isRequired,
	}),
	onClick: PropTypes.func,
	svg: PropTypes.node,
};
