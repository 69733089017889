export default function checkObjectTree(object, tree = []) {
	if (tree.length === 0) {
		return true;
	}
	let key = tree.shift();
	let check = object;
	while (typeof key !== 'undefined') {
		if (typeof check[key] === 'undefined') {
			return false;
		}
		check = check[key];
		key = tree.shift();
	}
	return true;
}
