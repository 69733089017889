export const API_BASE = 'https://livekarte.vvs.de/proxy/';

export const API_FREIZEITTIPPS_BASE = 'https://livekarte.vvs.de/ausflugstipps/';

export const API_HALTESTELLE_BASE = 'https://haltestelle.vvs.de/';
export const SHARING_API_BASE = 'https://radroutenplaner.vvs.de/api/';


export const API_STOPFINDER_PARAMS = '';

export const API_DEPARTURE_PARAMS = [
	'coordOutputFormat=WGS84%5BDD.DDDDD%5D',
	'lsShowTrainsExplicit=1',
	'stateless=1',
	'language=de',
	'SpEncId=0',
	'anySigWhenPerfectNoOtherMatches=1',
	'type_dm=any',
	'anyObjFilter_dm=2',
	'deleteAssignedStops=1',
	'useRealtime=1',
	'mode=direct',
	'dmLineSelectionAll=1',
	'limit=100',
	'line=VVS:::',
	'outputFormat=rapidJSON',
].join('&');

export const COORD_RADIUS = 10000;

export const COORD_LIMIT = 500;
