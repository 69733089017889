import VectorLayer from 'ol/src/layer/Vector';
import Style from 'ol/src/style/Style';
import Stroke from 'ol/src/style/Stroke';

import { colors } from 'shared';
import VectorSource from 'ol/source/Vector';

// const defaultStyles = {
// 	cursor: 'pointer',
// 	strokeColor: '#ccc',
// 	strokeLinecap: 'butt',
// 	strokeWidth: 5,
// };

const styleMap = {
	default: new Style({
		stroke: new Stroke({
			color: '#ccc',
			lineCap: 'butt',
			width: 5,
		}),
	}),
	Fahrrad: new Style({
		stroke: new Stroke({
			color: colors.bike,
			lineCap: 'butt',
			width: 5,
		}),
	}),
	RegioRad: new Style({
		stroke: new Stroke({
			color: colors.regioRad,
			lineCap: 'butt',
			width: 5,
		}),
	}),
	'R-Bahn': new Style({
		stroke: new Stroke({
			color: colors.rBahn,
			lineCap: 'butt',
			width: 5,
		}),
	}),
	'S-Bahn': new Style({
		stroke: new Stroke({
			color: colors.sBahn,
			lineCap: 'butt',
			width: 5,
		}),
	}),
	'SEV-Bus': new Style({
		stroke: new Stroke({
			color: colors.bus,
			lineCap: 'butt',
			lineDash: [15, 10],
			width: 5,
		}),
	}),
	Linientaxi: new Style({
		stroke: new Stroke({
			color: colors.taxi,
			lineCap: 'butt',
			width: 5,
		}),
	}),
	Bus: new Style({
		stroke: new Stroke({
			color: colors.bus,
			lineCap: 'butt',
			width: 5,
		}),
	}),
	Stadtbahn: new Style({
		stroke: new Stroke({
			color: colors.stadtbahn,
			lineCap: 'butt',
			width: 5,
		}),
	}),
	Zahnradbahn: new Style({
		stroke: new Stroke({
			color: colors.zacke,
			lineCap: 'butt',
			width: 5,
		}),
	}),
	footpath: new Style({
		stroke: new Stroke({
			color: colors.footpath,
			lineCap: 'butt',
			width: 5,
		}),
	}),
};

/**
 *
 * @param {import('ol/src/Map').default} map The OpenLayers Map
 * @param {string} name The name of the layer
 *
 * @returns {VectorLayer}
 */
export function getOrCreateLinesLayer(map, name = 'lines') {
	let layer = map
		.getLayers()
		.getArray()
		.find(checkLayer => checkLayer.get('name') === name);
	if (layer !== undefined) {
		return layer;
	}

	layer = new VectorLayer({
		source: new VectorSource(),
		style: feature => {
			const hovered = feature.get('hovered');
			if (hovered || feature.get('alwaysActive') || feature.get('active')) {
				const style = styleMap[feature.get('productName') || 'default'];
				if (hovered) {
					style.setZIndex(5);
				}
				return style;
			}
			return styleMap.default;
		},
		properties: {
			isLinesLayer: true,
		},
		zIndex: 10,
	});
	layer.set('name', name);
	map.addLayer(layer);

	// layer.div.classList.add('lines-layer');

	return layer;
}
