import { API_BASE } from './constants';

const linesByIdCache = {};

export default async (id, pkKwd = '', fetchOptions = {}) => {
	if (typeof linesByIdCache[id] !== 'undefined') {
		return linesByIdCache[id];
	}

	// eslint-disable-next-line no-param-reassign
	fetchOptions.cache = fetchOptions.cache || 'force-cache';

	linesByIdCache[id] = fetch(
		`${API_BASE}XML_SERVINGLINES_REQUEST?SpEncId=0&deleteAssignedStops=1&lineReqType=2&locationServerActive=1&lsShowTrainsExplicit=1&mergeDir=0&mode=odv&outputFormat=rapidJSON&serverInfo=1&type_sl=stopID&name_sl=${id}&pk_campaign=map&pk_kwd=${pkKwd}`,
		fetchOptions,
	)
		.then((response) => response.json())
		.then((json) => json.lines);
	return linesByIdCache[id];
};
