import { API_BASE } from './constants';

const poiIdCache = {};

/**
 * Fetch POIs around the given position
 *
 * @name fetchPOIsByArea
 * @function
 * @param {string} poiId The id of the poi.
 * @param {object} [fetchOptions={}] Options for the fetch request
 * @return {object} POI for the given id.
 */
export default async (poiId, fetchOptions = {}) => {
	if (typeof poiIdCache[poiId] === 'undefined') {
		// eslint-disable-next-line no-param-reassign
		fetchOptions.cache = fetchOptions.cache || 'force-cache';

		poiIdCache[poiId] = fetch(
			`${API_BASE}poi-by-id?id=${poiId}&${window.additionalApiParams}`,
			fetchOptions,
		).then(response => response.json());
	}
	return poiIdCache[poiId];
};
// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
