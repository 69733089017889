import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { boxshadow, OutlineButton } from 'shared';

import Context from '../context';

const ModalWrapper = styled.div`
	position: fixed;
	display: flex;
	width: 100vw;
	height: 100vh;
	align-items: center;
	justify-content: center;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 100;
`;

const ModalBackdrop = styled.div`
	background-color: rgba(0, 0, 0, 0.32);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
`;

const StyledMapModal = styled.div`
	border-radius: 6px;
	background-color: #fff;
	width: 320px;
	z-index: 2;
	overflow: hidden;
	button,
	a {
		cursor: pointer;

		svg {
			width: 2rem;
			height: 2rem;
		}
	}
	box-shadow: ${boxshadow.default};
`;

const ShareWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	~ * {
		margin-top: 2rem;
	}
`;

const StyledButton = styled(OutlineButton)`
	margin-bottom: 0.5rem;
`;

const StyledHeadline = styled.h4`
	margin: 1rem 2rem 0;
`;

const StyledText = styled.p`
	margin: 1rem 2rem 2rem;
`;

export default function SavedModal() {
	const { isSavedModalOpen, closeSavedModal } = useContext(Context);
	const { t } = useTranslation();

	return (
		<>
			{isSavedModalOpen && (
				<ModalWrapper>
					<ModalBackdrop onClick={closeSavedModal} />
					<StyledMapModal>
						<ShareWrapper>
							<StyledHeadline>
								{t('routePlanning.savedModal.headline')}
							</StyledHeadline>
							<StyledText>{t('routePlanning.savedModal.text')}</StyledText>
							<StyledButton onClick={closeSavedModal}>
								{t('routePlanning.savedModal.button')}
							</StyledButton>
						</ShareWrapper>
					</StyledMapModal>
				</ModalWrapper>
			)}
		</>
	);
}
