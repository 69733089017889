import { API_HALTESTELLE_BASE } from './constants';

const accessibilityCache = {};

/**
 * Fetch accessibility data for the passed stops (max. 50)
 *
 * @name fetchStopPointAccessibilities
 * @function
 * @param {string} stopId The id of the stop
 * @return {object} Object with success and data-array with stopPoints of the stop and their accessibility data.
 */
export default async (stopId, fetchOptions = {}) => {
	const usedId = stopId.replace(/:/g, '_');
	if (typeof accessibilityCache[usedId] === 'undefined') {
		accessibilityCache[usedId] = fetch(
			`${API_HALTESTELLE_BASE}stops/${usedId}/accessibility.json`,
			fetchOptions,
		).then(response => response.json());
	}
	return accessibilityCache[usedId];
};
