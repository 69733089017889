export default function getJoinedLegs(_legs, bike = 'Fahrrad') {
	const legs = [];

	let cumDistance = 0;

	_legs.forEach((leg, l) => {
		if (bike !== 'Fahrrad' && leg.transportation.product.name === 'Fahrrad') {
			// eslint-disable-next-line no-param-reassign
			leg.transportation.product.name = bike;
		}

		if (l === 0) {
			legs.push({ ...leg });
			return;
		}

		const lastIndex = legs.length - 1;
		if (
			leg.transportation.product.name === bike &&
			legs[lastIndex].transportation.product.name === bike
		) {
			const lastPathDescs = legs[lastIndex].pathDescriptions;
			cumDistance = lastPathDescs[lastPathDescs.length - 1].cumDistance;

			legs[lastIndex].distance += leg.distance;
			legs[lastIndex].duration += leg.duration;
			legs[lastIndex].destination = { ...leg.destination };
			legs[lastIndex].pathDescriptions = [
				...lastPathDescs,
				...leg.pathDescriptions.map(pathDesc => ({
					...pathDesc,
					cumDistance: pathDesc.cumDistance + cumDistance,
				})),
			];
			legs[lastIndex].coords = [...legs[lastIndex].coords, ...leg.coords];
			return;
		}

		legs.push({ ...leg });
	});

	return legs;
}
