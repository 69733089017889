import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Remove } from '../../assets/icons';
import Transportation, { TransportationWrapper } from '../Transportation';
import { mapRoot, updateUri } from '../../utils';

import { RoutePropTypes } from './propTypes';
import { StyledListItem } from './styles';

const OwnStyledRoute = styled.div`
	display: grid;
	display: -ms-grid;

	-ms-grid-columns: 1fr min-content;
	grid-template-columns: 1fr min-content;

	-ms-grid-rows: min-content min-content min-content;
	grid-template-rows: min-content min-content min-content;

	width: 100%;
	cursor: pointer;

	.title {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 1;
		grid-row: 1;

		font-size: 1rem;
		margin-bottom: 0.2rem;
	}

	.date-time {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 2;
		grid-row: 2;

		font-size: 0.9rem;
		color: #6c7a80;
	}

	.transportation {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 3;
		grid-row: 3;
		max-width: 100%;
		width: 100%;
		overflow: hidden;
	}

	.remove {
		-ms-grid-column: 2;
		grid-column: 2;
	}

	.details {
		-ms-grid-column: 3;
		grid-column: 3;
	}

	.remove {
		display: flex;
		-ms-grid-row: 1;
		-ms-grid-row-span: 3;
		grid-row: 1 / 4;
		align-items: center;
		justify-content: center;

		button {
			border: none;
			background-color: transparent;
			outline: none;
			cursor: pointer;
			background-color: transparent;
			transition: background-color 200ms ease-in-out;
			border-radius: 50%;
			padding: 0;
			line-height: 0;
			:hover {
				background-color: rgb(108, 122, 128, 0.2);
			}
			svg {
				width: 2.5rem;
				height: 2.5rem;
				path {
					fill: #6c7a80;
				}
			}
		}
	}
`;

function padZero(val) {
	return val.toString().padStart(2, 0);
}

// filters out all legs that are footpath and not at start or beginning of array
function filterFootPath(leg, i, legs) {
	if (i === 0 || i === legs.length - 1) return true;
	return leg.transportation.product.name !== 'footpath';
}

function OwnRoute({ route, removeRoute }) {
	const { arrivalTime, departureTime, title, legs } = route;

	const arrival = new Date(arrivalTime);
	const departure = new Date(departureTime);

	const date = `${departure.getFullYear()}-${padZero(departure.getMonth() + 1)}-${padZero(
		departure.getDate(),
	)}`;

	return (
		<StyledListItem>
			<OwnStyledRoute
				onClick={() => {
					updateUri(false, `${mapRoot}/meine-routen/${route.id}`);
				}}
			>
				<h4 className="title">{decodeURIComponent(title)}</h4>

				<div className="date-time">
					<time dateTime={date}>{departure.toLocaleDateString()}</time>
					{' / '}
					<time dateTime={departure.toLocaleTimeString()}>
						{departure.toLocaleTimeString()}
					</time>
					{' - '}
					<time dateTime={arrival.toLocaleTimeString()}>
						{arrival.toLocaleTimeString()}
					</time>
				</div>

				<div className="transportation">
					<TransportationWrapper>
						{legs.filter(filterFootPath).map((leg, i) => (
							<Transportation key={i} leg={leg} />
						))}
					</TransportationWrapper>
				</div>

				<div className="remove">
					<button
						type="button"
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							removeRoute();
						}}
					>
						<Remove />
					</button>
				</div>
			</OwnStyledRoute>
		</StyledListItem>
	);
}

OwnRoute.propTypes = {
	route: RoutePropTypes.isRequired,
	removeRoute: PropTypes.func.isRequired,
};

export default OwnRoute;
