import { API_BASE } from './constants';

/**
 * Fetch departures for the given stop id
 *
 * @name fetchDepartures
 * @function
 * @param {string} locationId The id of the stop.
 * @param {number} [limit=100] The amount of departures to retrieve.
 * @return {object} Object with stopId as key and an array with the keys "accessible" (boolean) and "map" (string/url).
 */
export default async (locationId, limit = 100, fetchOptions = {}) => {
	// const now = new Date();
	// const dateTimeParams = `&itdDateYear=${now.getFullYear()}&itdDateMonth=${now.getMonth() + 1}&itdDateDay=${now.getDate()}&itdTimeHour=${now.getHours()}&itdTimeMinute=${now.getMinutes()}`;
	// const dateTimeParams = '';

	// eslint-disable-next-line no-param-reassign
	fetchOptions.cache = fetchOptions.cache || 'force-cache';

	const response = await fetch(
		`${API_BASE}departures?q=${locationId}&limit=${limit}&${window.additionalApiParams}`,
		fetchOptions,
	);
	const json = await response.json();
	return json.stopEvents || [];
};
// .catch(() => alert('Verbindung zum Server fehlgeschlagen. Bitte versuchen Sie es erneut.'))
