import React from 'react';
import ReactDOM from 'react-dom';

import Icon from 'ol/src/style/Icon';
import Style from 'ol/src/style/Style';
import { toLonLat } from 'ol/src/proj';

import { fetchers } from 'shared';

import MarkerButton from '../components/MapMarkers/MarkerButton';
import { mapRoot } from './config';
import createMarker from './createMarker';
import { getOrCreateLinesLayer } from './getOrCreateLinesLayer';
import getLayerByName from './getLayerByName';

import sbahn from '../assets/icons/s-bahn.svg';
import bus from '../assets/icons/bus.svg';
import bike from '../assets/icons/bike.svg';
import stadtbahn from '../assets/icons/stadtbahn.svg';
import footpath from '../assets/icons/footpath.svg';
import rbahn from '../assets/icons/r-bahn.svg';
import regiorad from '../assets/icons/regioRad-leg.svg';

let lastRouteType = null;
let lastActiveRouteType = null;

const transportationIcons = {
	'S-Bahn': sbahn,
	Stadtbahn: stadtbahn,
	Bus: bus,
	footpath,
	Fahrrad: bike,
	'R-Bahn': rbahn,
	'SEV-Bus': bus,
	RegioRad: regiorad,
};

/**
 * @param {import('ol/src/layer/Vector').default} layer
 * @param {string} routeType
 */
export function enterLayer(layer, routeType) {
	leaveLayer(layer, null, false);
	layer
		.getSource()
		.getFeatures()
		.forEach(feature => {
			if (feature.get('routeType') === routeType) {
				feature.set('hovered', true);
				feature.changed();
			}
		});
}

/**
 * @param {import('ol/src/layer/Vector').default} layer
 * @param {string} routeType
 * @param {boolean} shouldMatch
 */
export function leaveLayer(layer, routeType, shouldMatch = true) {
	layer
		.getSource()
		.getFeatures()
		.forEach(feature => {
			const matchesRouteType = feature.get('routeType') === routeType;
			if (matchesRouteType === shouldMatch) {
				feature.set('hovered', false);
				feature.changed();
			}
		});
}

/**
 * @param {import('ol/src/layer/Vector').default} layer
 * @param {string} routeType
 */
export function activateRouteType(layer, routeType) {
	lastActiveRouteType = routeType;
	layer
		.getSource()
		.getFeatures()
		.forEach(feature => {
			const matchesRouteType = feature.get('routeType') === routeType;
			if (matchesRouteType === false) {
				feature.set('active', false);
			} else {
				feature.set('active', !feature.get('active'));
			}
		});
}

let pointAbortController = {
	abort: () => {},
};

export function initMapEvents(
	map,
	{
		onFeatureEnter = () => {},
		onFeatureActivate = () => {},
		onFeatureDeactivate = () => {},
		addPoint = () => {},
		setLocationMarker = () => {},
	},
) {
	const linesLayer = getOrCreateLinesLayer(map);
	const transportationLayer = getLayerByName(map, 'transportation');

	//
	// Set styles for transportation layer
	//
	transportationLayer.setStyle(
		feature =>
			new Style({
				image: new Icon({
					anchor: [0.5, 0.5],
					src: transportationIcons[feature.get('type')] || transportationIcons.footpath,
					imgSize: [36, 36],
					size: [36, 36],
					scale: 0.66,
				}),
			}),
	);

	map.on('pointermove', e => {
		map.forEachFeatureAtPixel(
			e.pixel,
			feature => {
				const routeType = feature.get('routeType');
				enterLayer(linesLayer, routeType);
				leaveLayer(linesLayer, routeType, false);
				if (lastRouteType !== routeType) {
					lastRouteType = routeType;
					onFeatureEnter(routeType);
				}
				return true;
			},
			{ layerFilter: layer => layer === linesLayer },
		);
	});

	map.on('singleclick', e => {
		if (!window.location.pathname.match(/\/routenplanung(\/.+)?@.+$/)) {
			return;
		}

		map.forEachFeatureAtPixel(
			e.pixel,
			feature => {
				const routeType = feature.get('routeType');
				if (feature.get('active')) {
					const needsUpdate = lastActiveRouteType !== null;
					activateRouteType(linesLayer, null);

					if (needsUpdate) {
						lastActiveRouteType = null;
						onFeatureDeactivate(routeType);
					}
				} else {
					const needsUpdate = lastActiveRouteType !== routeType;
					activateRouteType(linesLayer, routeType);

					if (needsUpdate) {
						lastActiveRouteType = routeType;
						onFeatureActivate(routeType);
					}
				}
			},
			{ layerFilter: layer => layer === linesLayer },
		);
	});

	map.getTargetElement().addEventListener(
		'contextmenu',
		e => {
			e.preventDefault();
			e.stopPropagation();
			const lonLat = map.getCoordinateFromPixel([
				e.x - map.getTargetElement().offsetLeft,
				e.y,
			]);
			const wgs84 = toLonLat(lonLat);

			// if (positionMarker !== null) {
			// 	markerLayer.removeMarker(positionMarker);
			// 	positionMarker = null;
			// }

			let signal;
			if ('AbortController' in window) {
				pointAbortController.abort();
				pointAbortController = new window.AbortController();
				signal = pointAbortController.signal;
			}

			fetchers
				.fetchCoordData(wgs84[1], wgs84[0], { signal })
				.then(json => {
					if (json.locations.length > 0) {
						const [location] = json.locations;
						['name', 'disassembledName'].forEach(name => {
							location[name] = location[name].replace(
								/\$UNKNOWN_POINT\$/g,
								'Punkt auf Karte',
							);
						});

						if (typeof _paq !== 'undefined') {
							// eslint-disable-next-line no-underscore-dangle
							window._paq.push([
								'trackEvent',
								'Karte', // Category
								'Rechtsklick', // Action
								`${location.name} (${wgs84.lat}, ${wgs84.lon})`, // Name
							]);
						}

						const routePlanningCheck = new RegExp(
							`^${mapRoot}(/routenplanung(/details)?)?(@.+)?$`,
						);
						const showButton = window.location.pathname.match(routePlanningCheck);

						const onHover = () => {
							const content = document.createDocumentFragment();

							ReactDOM.render(
								<div className="marker__tooltip">
									<h3 className="tooltipcontent__title">{location.name}</h3>
									{showButton && (
										<MarkerButton
											onClick={ev => {
												ev.preventDefault();
												setLocationMarker(null);
												addPoint(location, false);
												tooltip.style.display = 'none';
											}}
										>
											Zur Route hinzufügen
										</MarkerButton>
									)}
								</div>,
								content,
							);

							const tooltip = document.getElementById('tooltip');
							tooltip.innerHTML = '';
							tooltip.appendChild(content);
							tooltip.style.display = '';
						};

						const marker = createMarker(location, {
							type: 'address',
							name: location.name,
							state: 3,
							onHover,
						});
						setLocationMarker(marker);

						onHover();

						map.getOverlayById('tooltip').setPosition(
							marker.getGeometry().getCoordinates(),
						);
					}
				})
				.catch(() => {
					// Ignore errors for now (mostly AbortController errors)
				});
		},
		false,
	);

	map.on('click', () => {
		document.getElementById('tooltip').style.display = 'none';
		setLocationMarker(null);
	});
}
