import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { NextGrey } from '../../assets/icons';

import Context from '../context';

import { ResultItem, ResultTitle, ResultTime as ResultInfo } from './ResultStyles';

import getDuration from '../../utils/getDuration';
import getJoinedLegs from '../../utils/getJoinedLegs';
import { getOrCreateLinesLayer } from '../../utils/getOrCreateLinesLayer';
import { activateRouteType, enterLayer } from '../../utils/mapEvents';
import Transportation, { TransportationWrapper } from '../Transportation';

function getPrice(route) {
	const ticket = route?.fare?.tickets?.[0];

	if (!ticket) return null;
	return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
		ticket.priceBrutto,
	);
}

// filters out all legs that are footpath and not at start or beginning of array
function filterFootPath(leg, i, legs) {
	if (i === 0 || i === legs.length - 1) return true;
	return leg.transportation.product.name !== 'footpath';
}

export default function Route({ route, index, onHover }) {
	const { t } = useTranslation();
	const { map, hoverRoute, setHoverRoute, setActiveRoute, setCurrentRoute } = useContext(Context);

	const { origin } = route.legs[0];
	const { destination } = route.legs[route.legs.length - 1];
	const duration = getDuration(origin, destination);
	const price = getPrice(route);

	const legs = getJoinedLegs(
		route.legs,
		route.routeType === 'bikeSharing' ? 'RegioRad' : 'Fahrrad',
	);

	return (
		<ResultItem
			isHovered={hoverRoute === route.routeType}
			onMouseOver={() => {
				if (hoverRoute !== route.routeType) {
					const layer = getOrCreateLinesLayer(map, 'lines');
					enterLayer(layer, route.routeType);
					setHoverRoute(route.routeType);
					onHover();
				}
			}}
			onClick={() => {
				const layer = getOrCreateLinesLayer(map, 'lines');
				setActiveRoute(route.routeType);
				setCurrentRoute(index);
				activateRouteType(layer, route.routeType);
			}}
		>
			<ResultTitle>{t(`routePlanning.${route.routeType}`)}</ResultTitle>
			<ResultInfo>
				{duration}
				{price && ` / ${price}`}
			</ResultInfo>
			<TransportationWrapper>
				{legs.filter(filterFootPath).map(leg => (
					<Transportation
						key={`route-${route.routeType}-${leg.origin.departureTimePlanned}`}
						leg={leg}
					/>
				))}
			</TransportationWrapper>
			<NextGrey className="next" />
		</ResultItem>
	);
}

Route.propTypes = {
	route: PropTypes.shape({
		routeType: PropTypes.string.isRequired,
		legs: PropTypes.arrayOf(
			PropTypes.shape({
				origin: PropTypes.shape({}),
				destination: PropTypes.shape({}),
			}),
		),
	}).isRequired,
	index: PropTypes.number.isRequired,
	onHover: PropTypes.func,
};

Route.defaultProps = {
	onHover: () => {},
};
