import styled from 'styled-components';

export default styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 10px;
	border: 2px solid #e9e9e9;
	background-color: #fff;
	border-radius: 6px;
	font-size: 13px;
	height: 38px;
	cursor: pointer;

	&:disabled {
		cursor: initial;
	}

	svg {
		width: 1rem;
		height: 1rem;
		margin-right: 0.5rem;
	}
`;
