import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	background-color: rgba(0, 0, 0, 0.1);
	padding: 4px;
	border-radius: 6px;
	justify-content: space-evenly;
`;

const Item = styled.span`
	flex: 1 1 auto;

	input {
		visibility: hidden;
		display: none;
	}

	input:checked ~ label {
		background-color: rgba(255, 255, 255, 1);
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 1px 6px rgba(0, 0, 0, 0.23);

		::after {
			content: '▼';
			display: inline-block;
			margin-left: 5px;
			text-align: right;
		}

		&[for$='-reversed']::after {
			transform: rotate(180deg);
		}
	}

	label {
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 4px 8px;
		background-color: transparent;
		transition: all ease-in-out 333ms;
		line-height: 1;
		cursor: pointer;

		:hover {
			background-color: rgba(255, 255, 255, 0.5);
		}
	}
`;

const Separator = styled.span`
	width: 1px;
	height: 1rem;
	background-color: rgba(0, 0, 0, 0.1);
	align-self: center;
	transition: opacity 100ms ease-in-out;
	opacity: ${({ hide }) => (hide ? 0 : 1)};
`;

const isHidden = (item, nextItem, selected) => {
	const isSelected = item.value === selected;
	const nextIsSelected = nextItem && nextItem.value === selected;

	return isSelected || nextIsSelected;
};

function MultiSwitch({ name, items, selected, onChange }) {
	return (
		<Container>
			{items.map(({ value, label }, i) => {
				let suffix = '';
				if (value === selected) {
					suffix = '-reversed';
				}
				return (
					<Fragment key={value}>
						<Item>
							<input
								id={`multi-switch-${value}`}
								name={name || 'multi-switch'}
								type="radio"
								checked={value === selected}
								onChange={() => onChange(value)}
							/>
							<input
								id={`multi-switch-${value}-reversed`}
								name={name || 'multi-switch'}
								type="radio"
								checked={`${value}Reversed` === selected}
								onChange={() => onChange(`${value}Reversed`)}
							/>
							<label htmlFor={`multi-switch-${value}${suffix}`}>{label}</label>
						</Item>
						{i < items.length - 1 && (
							<Separator hide={isHidden({ value }, items[i + 1], selected)} />
						)}
					</Fragment>
				);
			})}
		</Container>
	);
}

MultiSwitch.propTypes = {
	name: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any.isRequired,
			label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		}),
	).isRequired,
	selected: PropTypes.any,
	onChange: PropTypes.func,
};

export default MultiSwitch;
