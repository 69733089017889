import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
	boxshadow,
	colors,
	createGpx,
	breakpoints as media,
	download,
	replaceUmlauts,
	SettingsButton,
	OutlineButton,
} from 'shared';

import { useRouteMatch } from 'react-router-dom';
import context from '../context';
import { saveRoute } from '../../utils/sharingApi';

import { DeleteGrey, Mail, Twitter, WhatsApp } from '../../assets/icons';
import share from '../../assets/icons/share.svg';
import shareHover from '../../assets/icons/share-hover.svg';
import { mapRoot } from '../../utils';

const ModalWrapper = styled.div`
	position: fixed;
	display: flex;
	width: 100vw;
	height: 100vh;
	align-items: center;
	justify-content: center;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 100;
`;

const ModalBackdrop = styled.div`
	background-color: rgba(0, 0, 0, 0.32);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
`;

const StyledMapModal = styled.div`
	border-radius: 6px;
	background-color: #fff;
	width: 375px;
	z-index: 2;
	overflow: hidden;
	button,
	a {
		cursor: pointer;

		svg {
			width: 2rem;
			height: 2rem;
		}
	}
	box-shadow: ${boxshadow.default};
`;

const MapModalLink = styled.header`
	background-color: ${colors.orange};
	padding: 1rem;
	display: flex;
	flex-direction: column;
`;

const Input = styled.input`
	border: none;
	border-radius: 6px;
	background-color: #fff;
	outline: none;
	font-weight: normal;
	line-height: 40px;
	height: 40px;
	padding: 0 1rem;
	font-size: 15px;
	width: 100%;
	margin-top: 1rem;
`;

const CopyButton = styled.button`
	border: none;
	background-color: transparent;
	color: #fff;
	text-transform: uppercase;
	padding: 0;
	margin-top: 1rem;
`;

const CloseButton = styled.button`
	border: none;
	background-color: transparent;
	padding: 0;
	align-self: flex-end;

	svg path {
		fill: #fff;
	}
`;

const ShareLink = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: none;
	display: flex;
	flex-direction: column;

	button {
		background-color: ${colors.graysuperlight};
		border-radius: 50%;
		border: none;
		width: 4rem;
		height: 4rem;
		margin-bottom: 0.5rem;
	}

	color: ${colors.graydark};
	text-decoration: none;
`;

const Hr = styled.div`
	width: 100%;
	border-top: 2px solid ${colors.graysuperlight};
	margin: 1.5rem 0;
`;

const ShareWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	~ * {
		margin-top: 2rem;
	}
`;

const ShareButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-around;
	padding: 1rem 2rem;
`;

const StyledButton = styled(OutlineButton)`
	margin-bottom: 0.5rem;
`;

/**
 * @param {string} copy the text to copy
 */
function copyToClipboard(copy) {
	if (navigator.clipboard) navigator.clipboard.writeText(copy);
	else {
		const el = document.createElement('input');
		el.value = copy;
		el.style.display = 'none';

		document.body.appendChild(el);
		el.focus();
		el.select();
		try {
			document.execCommand('copy');
		} catch (err) {
			console.error(err);
		}

		document.body.removeChild(el);
	}
}

function Modal({ onClose, onDownload, mapLink = window.location.href, addMyRoute, isSaved }) {
	const { t } = useTranslation();

	return (
		<ModalWrapper>
			<ModalBackdrop onClick={() => onClose(false)} />
			<StyledMapModal>
				<MapModalLink>
					<CloseButton type="button" onClick={() => onClose()}>
						<DeleteGrey />
					</CloseButton>
					<Input type="text" readOnly value={mapLink} />
					<CopyButton type="button" onClick={() => copyToClipboard(mapLink)}>
						{t('routePlanning.copyLink')}
					</CopyButton>
				</MapModalLink>
				<ShareWrapper>
					<ShareButtonsWrapper>
						<ShareLink href={`mailto:?subject=${t('mail.subject')}&body=${mapLink}`}>
							<button type="button">
								<Mail />
							</button>
							{t('mail.share')}
						</ShareLink>

						<ShareLink
							href={`https://wa.me/?text=${window.encodeURIComponent(mapLink)}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<button type="button">
								<WhatsApp />
							</button>
							Whats App
						</ShareLink>

						<ShareLink
							href={`https://twitter.com/intent/tweet?text=${window.encodeURIComponent(
								mapLink,
							)}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<button type="button">
								<Twitter />
							</button>
							Twitter
						</ShareLink>
					</ShareButtonsWrapper>
					<Hr />

					<StyledButton onClick={addMyRoute} disabled={isSaved}>
						{isSaved ? t('routePlanning.saved') : t('routePlanning.save')}
					</StyledButton>

					<StyledButton onClick={onDownload}>
						{t('routePlanning.downloadGpx')}
					</StyledButton>
				</ShareWrapper>
			</StyledMapModal>
		</ModalWrapper>
	);
}

Modal.propTypes = {
	mapLink: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	onDownload: PropTypes.func.isRequired,
	addMyRoute: PropTypes.func.isRequired,
	isSaved: PropTypes.bool.isRequired,
};

const StyledSettingsButton = styled(SettingsButton)`
	top: 72px;

	svg * {
		fill: #fff;
	}

	${media.iphone`
		top: 38px;
	`}

	@media screen and (min-width: 1366px) and (max-height: 768px) and (orientation: landscape) {
		transform: scale(0.752);
		transform-origin: top right;
	}
`;

// function hashRouteId(string) {
// 	const [id] = !!string.split('@')[0];
// 	return !!id;
// }

export default function ModalButton() {
	const [isOpen, toggleModal] = useState(false);
	const { currentRouteIndex, addMyRoute, routes, activeRoute, points } = useContext(context);
	const [sharingHash, setSharingHash] = useState(false);
	const match = useRouteMatch(`${mapRoot}/meine-routen/:rest`);

	useEffect(() => {
		if (isOpen && !sharingHash) {
			saveRoute(activeRoute, points).then(json => {
				if (json.success) {
					setSharingHash(json.data.hash);
				}
			});
		}
	}, [isOpen, sharingHash, activeRoute, points]);

	if (currentRouteIndex === null && match === null) return null;

	const isSaved = routes?.[currentRouteIndex]?.saved || !!match?.params?.rest?.split('@')[0];

	return (
		<>
			<StyledSettingsButton
				icon={share}
				iconHover={shareHover}
				onClick={() => toggleModal(true)}
			/>
			{isOpen && (
				<Modal
					onClose={() => toggleModal(false)}
					addMyRoute={addMyRoute}
					onDownload={() => {
						// ACHTUNG: GPX-Erstellung prüfen!
						if (routes?.[currentRouteIndex]) {
							const route = routes[currentRouteIndex];
							let name = `${route.routeType}.gpx`;
							if (route.legs && route.legs.length > 0) {
								const start = replaceUmlauts(route.legs[0].origin.name);
								const end = replaceUmlauts(
									route.legs[route.legs.length - 1].destination.name,
								);
								name = `${start}-nach-${end}.gpx`;
							}

							download(
								createGpx(points, route.legs, 'VVS Radroutenplaner'),
								'application/javascript',
								name,
							);
						}
					}}
					isSaved={isSaved}
					mapLink={
						sharingHash
							? `https://radroutenplaner.vvs.de/r/${sharingHash}`
							: window.location.href
					}
				/>
			)}
		</>
	);
}
