export const dateToDatestring = date =>
	`${`0${date.getDate()}`.substr(-2)}.${`0${date.getMonth() + 1}`.substr(
		-2,
	)}.${date.getFullYear()}`;

export const dateToTimestring = date =>
	`${`0${date.getHours()}`.substr(-2)}:${`0${date.getMinutes()}`.substr(-2)}`;

export const stringToDate = dateTimeString => {
	const date = new Date();
	const parts =
		dateTimeString &&
		dateTimeString.match(/^(\d{4})-(\d{2})-(\d{2})[T ](\d{2}):(\d{2}):(\d{2})Z?$/);
	if (!parts) {
		return date;
	}
	const [, year, month, day, hours, minutes, seconds] = parts;
	date.setUTCFullYear(year);
	date.setUTCMonth(month - 1);
	date.setUTCDate(day);
	date.setUTCHours(hours);
	date.setUTCMinutes(minutes);
	date.setUTCSeconds(seconds);
	return date;
};

export const minutesToDurationString = minutes =>
	`${minutes > 59 ? `${Math.floor(minutes / 60)} Std ` : ''}${Math.floor(minutes % 60)} Min`;
