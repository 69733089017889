export default function nameToIcon(name, keepDash = false) {
	let icon = name
		.replace(/&/g, '-')
		.replace(/ä/g, 'ae')
		.replace(/ü/g, 'ue')
		.replace(/ö/g, 'oe')
		.replace(/Ä/g, 'Ae')
		.replace(/Ö/g, 'Oe')
		.replace(/Ü/g, 'Ue')
		.replace(/ß/g, 'ss')
		.replace(/ /g, '-')
		.replace(/---/g, '-');
	if (!keepDash) {
		icon = icon.replace(/-/g, '');
	}
	return icon.toLowerCase();
}
