import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CheckBox, CheckList, colors, SidebarHeader } from 'shared';

import Context from '../context';

// import { routeItems, fitnessItems, speedItems, pitchItems } from './settings';
import { routeItems, fitnessItems } from './settings';
import { NumberInput } from '../PublicTransportSettings';

const Content = styled.div`
	padding: 1rem;
`;

const IndividualContent = styled.div`
	background-color: #e6e6e6;
	margin: 0 -1rem;
	padding: 1rem;
	position: relative;

	&::before {
		border: 8px solid transparent;
		border-top: 8px solid #fff;
		border-bottom: 0;
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 2rem;
		width: 0;
		height: 0;
	}

	> :first-child {
		margin-top: 1rem;

		> :first-child {
			margin-top: 0;
		}
	}
`;

const Row = styled.div`
	display: flex;
	:first-child {
		flex: 1 1 auto;
	}
	:last-child {
		flex: 0 0 auto;
	}
`;

const Text = styled.label`
	color: ${colors.black};
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	padding: 12px 0;
	height: 100%;

	> input {
		border: 0;
		width: 30px;
	}

	&:hover {
		cursor: pointer;
	}
`;

function BikeSettings({ onBack }) {
	const { bikeSettings, setBikeSettings } = useContext(Context);
	const { t } = useTranslation();

	return (
		<>
			<SidebarHeader
				backgroundColor={colors.orange}
				title={t('bikeSettings.title')}
				onBack={onBack}
				isSmall
			/>
			<Content>
				<CheckList
					title={t('bikeSettings.route.title')}
					items={routeItems.map(({ value, title }) => ({
						value,
						title: t(title),
					}))}
					checked={bikeSettings.route}
					onChange={value => setBikeSettings({ setting: 'route', value })}
				/>
				{bikeSettings.route === 'individual' && (
					<IndividualContent>
						<CheckBox
							title={t('bikeSettings.route.individual.preferThemeRoute')}
							checked={bikeSettings.preferThemeRoute}
							onChange={value =>
								setBikeSettings({ setting: 'preferThemeRoute', value })
							}
						/>
						<CheckBox
							title={t('bikeSettings.route.individual.mainTracks')}
							checked={bikeSettings.preferMainTracks}
							onChange={value =>
								setBikeSettings({ setting: 'preferMainTracks', value })
							}
						/>
						<CheckBox
							title={t('bikeSettings.route.individual.asphaltTracks')}
							checked={bikeSettings.preferAsphaltTracks}
							onChange={value =>
								setBikeSettings({ setting: 'preferAsphaltTracks', value })
							}
						/>
						{/*
						<CheckBox
							title={t('bikeSettings.route.individual.greenTracks')}
							description={t('bikeSettings.route.individual.greenTracksDescription')}
							checked={bikeSettings.preferGreenTracks}
							onChange={value =>
								setBikeSettings({ setting: 'preferGreenTracks', value })
							}
						/>
						*/}
					</IndividualContent>
				)}

				<CheckList
					title={t('bikeSettings.fitness.title')}
					items={fitnessItems.map(({ value, title }) => ({
						value,
						title: t(title),
					}))}
					checked={bikeSettings.fitness}
					onChange={value => setBikeSettings({ setting: 'fitness', value })}
				/>
				{bikeSettings.fitness === 'individual' && (
					<IndividualContent>
						{/*
						<CheckList
							title={t('bikeSettings.fitness.speed.title')}
							items={speedItems.map(({ value, title }) => ({
								value,
								title: t(title, { speed: value }),
							}))}
							checked={bikeSettings.speed}
							onChange={value => setBikeSettings({ setting: 'speed', value })}
						/>
						*/}
						<Row>
							<Text htmlFor="bikeSettings-speed">
								{t('bikeSettings.fitness.speed.title')}
							</Text>
							<NumberInput
								id="bikeSettings-speed"
								defaultValue={bikeSettings.speed}
								suffix="km/h"
								onChange={value => setBikeSettings({ setting: 'speed', value })}
							/>
						</Row>

						{/*
						<CheckList
							title={t('bikeSettings.fitness.pitch.title')}
							items={pitchItems.map(({ value, title }) => ({
								value,
								title: t(title, { pitch: value }),
							}))}
							checked={bikeSettings.pitch}
							onChange={value => setBikeSettings({ setting: 'pitch', value })}
						/>
						*/}
						<Row>
							<Text htmlFor="bikeSettings-pitch">
								{t('bikeSettings.fitness.pitch.title')}
							</Text>
							<NumberInput
								id="bikeSettings-pitch"
								defaultValue={bikeSettings.pitch}
								suffix="%"
								max={100}
								onChange={value => setBikeSettings({ setting: 'pitch', value })}
							/>
						</Row>
					</IndividualContent>
				)}
			</Content>
		</>
	);
}

BikeSettings.propTypes = {
	onBack: PropTypes.func.isRequired,
};

export default BikeSettings;
