import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CheckBoxItem = styled.label`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 2rem;
	margin-bottom: 1rem;

	input {
		display: none;
	}

	span[role='img'] {
		display: inline-block;
		margin-left: 1rem;
		min-width: 1rem;
	}

	svg {
		height: 1rem;
		width: auto;
	}
`;

const Content = styled.span`
	cursor: pointer;
	text-align: start;

	strong {
		display: block;
	}
`;

function CheckBox({ title, description, checked, onChange }) {
	return (
		<CheckBoxItem>
			<Content>
				<strong>{title}</strong>
				{description && <small>{description}</small>}
			</Content>
			<input
				type="checkbox"
				checked={checked}
				onChange={e => {
					onChange(e.target.checked);
				}}
			/>
			<span role="img" aria-label="checked">
				<svg hidden={!checked} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8">
					<polyline
						points="1,4 3,7 7,1"
						fill="none"
						stroke="#0F7FAC"
						strokeLinecap="round"
					/>
				</svg>
			</span>
		</CheckBoxItem>
	);
}

CheckBox.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default CheckBox;
