import React from 'react';
import PropTypes from 'prop-types';

import StyledPopup from './StyledPopup';
import StyledPopupOption from './StyledPopupOption';

function Popup({ text, options, closePopup }) {
	return (
		<StyledPopup>
			<p>{text}</p>
			<ul>
				{options.map(option => (
					<StyledPopupOption key={`styled-popup-${option.label}`} onClick={closePopup}>
						<a href={option.uri} target="_blank" rel="noopener noreferrer">
							{option.label}
						</a>
					</StyledPopupOption>
				))}
				<StyledPopupOption onClick={closePopup}>
					<button type="button">Abbrechen</button>
				</StyledPopupOption>
			</ul>
		</StyledPopup>
	);
}

Popup.propTypes = {
	text: PropTypes.string,
	options: PropTypes.array,
	closePopup: PropTypes.func,
};

Popup.defaultProps = {
	text: '',
	options: [],
	closePopup: () => {},
};

export default Popup;
