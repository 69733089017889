export default function getDuration(origin, destination) {
	const start = origin.departureTimeEstimated || origin.departureTimePlanned;
	const end = destination.arrivalTimeEstimated || destination.arrivalTimePlanned;

	const startDate = new Date(start);
	const endDate = new Date(end);

	const diff = endDate - startDate;
	const diffDate = new Date(diff);
	diffDate.setHours(diffDate.getHours() - 1);

	let duration = '';
	if (diff >= 1000 * 60 * 60) {
		duration += `${diffDate.getHours()} Std `;
	}
	duration += `${diffDate.getMinutes()} Min`;

	return duration;
}
