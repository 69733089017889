import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { colors, fonts } from '../../utils/config';

const Wrapper = styled.div`
	background: transparent;
`;

const Item = styled.div`
	position: relative;
	border: none;
`;

const Title = styled.button`
	background-color: transparent;
	border: 0;
	font-family: ${fonts.bold};
	font-size: 14px;
	font-weight: ${fonts.weightbold};
	line-height: 20px;
	margin-right: 72px;
	padding-left: 24px;
	padding-top: 8px;
	padding-bottom: 8px;
	position: relative;

	&:hover {
		cursor: pointer;
	}

	&::before,
	&::after {
		background-color: ${colors.black};
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		transform-origin: center;
		width: 6px;
		height: 2px;
	}

	&::before {
		left: 0;
		transform: rotate(45deg);
	}
	&::after {
		left: 3px;
		transform: rotate(-45deg);
	}

	${({ isOuter }) => {
		if (isOuter) {
			return css`
				color: ${colors.white};
				margin-bottom: 16px;
				margin-top: 16px;
				padding: 0 0 0 24px;

				&::before,
				&::after {
					background-color: ${colors.white};
					margin-top: -1px;
				}

				&:hover {
					color: ${colors.black};

					&::before,
					&::after {
						background-color: ${colors.black};
					}
				}
			`;
		}
		return '';
	}}

	${({ isOpen }) => {
		if (isOpen) {
			return css`
				&::before {
					transform: rotate(-45deg);
				}
				&::after {
					transform: rotate(45deg);
				}
			`;
		}
		return '';
	}}
`;

const Content = styled.div`
	background: transparent;
	background-color: ${colors.graysuperlight};
	color: ${colors.black};
	margin: 0 -16px 1rem;
	padding-left: 16px;
	padding-right: 16px;

	p,
	ul li {
		font-size: 14px;
	}

	a {
		color: ${colors.black};
	}
`;

export default function Accordion({ toggleSwitch, openedText, closedText, isOuter, children }) {
	const [open, setOpen] = useState(false);

	return (
		<Wrapper data-accordion data-allow-all-closed="true">
			<Item data-accordion-item>
				<Title
					onClick={() => {
						setOpen(!open);
					}}
					isOuter={isOuter}
					isOpen={open}
				>
					{open ? openedText : closedText}
				</Title>
				{toggleSwitch}
				{open && <Content data-tab-content>{children}</Content>}
			</Item>
		</Wrapper>
	);
}

Accordion.propTypes = {
	toggleSwitch: PropTypes.element,
	openedText: PropTypes.string,
	closedText: PropTypes.string,
	isOuter: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

Accordion.defaultProps = {
	openedText: 'Weniger anzeigen',
	closedText: 'Mehr anzeigen',
};
