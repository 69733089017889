import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoints as media } from 'shared';

import i18n from '../i18n';
import Context from './context';
import updateUri from '../utils/updateUri';
import { setMyTimeout } from '../utils/timeouts';
import { mapRoot } from '../utils';

const Button = ({ children, ...props }) => (
	<button type="button" {...props}>
		{children}
	</button>
);

const Wrapper = styled.div`
	display: flex;
    flex-wrap: wrap;
    gap: 8pt;
	position: fixed;
	bottom: 0;
	background-color: #f60033;
	padding: 1rem;
	opacity: 0.66;
	z-index: 999;
	right: 44px;
	${media.lg`
		${({ isSettingsSidebarOpen }) => isSettingsSidebarOpen && `right: 432px;`}
		${({ isLeftSidebarOpen }) => isLeftSidebarOpen && `left: 432px;`}
	`}

	${({ isSettingsSidebarOpen }) => isSettingsSidebarOpen && `right: 362px;`}
	${({ isLeftSidebarOpen }) => isLeftSidebarOpen && `left: 362px;`}
`;

export default () => {
	const {
		isLeftSidebarOpen,
		isSettingsSidebarOpen,
		toggleSettingsSidebar,
		toggleLeftSidebar,
		setRoutes,
		setPoints,
	} = useContext(Context);
	const { t } = useTranslation();

	const goBack = () => {
		toggleLeftSidebar(false);
		// wait for the animation, else the content be gone before the sidebar is hidden
		setMyTimeout(
			'leftSidebar',
			() => {
				updateUri(false, mapRoot);
			},
			500,
		);
	};

	return (
		<Wrapper
			isLeftSidebarOpen={isLeftSidebarOpen}
			isSettingsSidebarOpen={isSettingsSidebarOpen}
		>
			<Button disabled={t('lang') === 'de'} onClick={() => i18n.changeLanguage('de')}>
				de
			</Button>
			<Button disabled={t('lang') === 'en'} onClick={() => i18n.changeLanguage('en')}>
				en
			</Button>
			<Button onClick={toggleLeftSidebar}>toggleLeftSidebar</Button>
			<Button onClick={toggleSettingsSidebar}>toggleRightSidebar</Button>
			<Button
				onClick={() => {
					if (window.location.pathname.match(/^\/rad-einstellungen/)) {
						goBack();
					} else {
						toggleLeftSidebar(true);
						updateUri(false, `${mapRoot}/rad-einstellungen`);
					}
				}}
			>
				Rad-Einstellungen
			</Button>
			<Button
				onClick={() => {
					if (window.location.pathname.match(/^\/oepnv-einstellungen/)) {
						goBack();
					} else {
						toggleLeftSidebar(true);
						updateUri(false, `${mapRoot}/oepnv-einstellungen`);
					}
				}}
			>
				Öpnv-Einstellungen
			</Button>
			<Button
				onClick={() => {
					window.localStorage.removeItem('POINTS');
					setPoints([]);
				}}
			>
				Clear Points
			</Button>
			<Button
				onClick={() => {
					window.localStorage.removeItem('ROUTES');
					setRoutes([]);
				}}
			>
				Clear Routes
			</Button>
			<Button
				onClick={() => {
					window.localStorage.removeItem('MY_ROUTES');
					setRoutes([]);
				}}
			>
				Clear myRoutes
			</Button>
		</Wrapper>
	);
};
