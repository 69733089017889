import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from '../Wrapper';
import Gradient from '../Gradient';
import LoadingImage from './LoadingImage';

const ImageLoadingIndicator = ({ className, height }) => (
	<Wrapper className={className}>
		<LoadingImage style={{ height }} />
		<Gradient />
	</Wrapper>
);

ImageLoadingIndicator.propTypes = {
	className: PropTypes.string,
	height: PropTypes.string,
};

ImageLoadingIndicator.defaultProps = {
	className: '',
	height: '200px',
};

export default ImageLoadingIndicator;
