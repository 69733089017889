import { API_FREIZEITTIPPS_BASE } from './constants';

const accessibilityCache = {};

/**
 * Fetch accessibility data for the passed stops (max. 50)
 *
 * @name fetchAccessibility
 * @function
 * @param {array<StopItem>} stopsList A list of stops to fetch the accessibility data.
 * @return {object} Object with stopId as key and an array with the keys "accessible" (boolean) and "map" (string/url).
 */
export default async (stopsList, fetchOptions = {}) => {
	let stops = '';
	if (stopsList && stopsList.length > 0) {
		stops = `?q=${stopsList.slice(0, 50).join(',')}`;
	}

	// eslint-disable-next-line no-param-reassign
	fetchOptions.cache = fetchOptions.cache || 'force-cache';

	if (typeof accessibilityCache[stops] === 'undefined') {
		accessibilityCache[stops] = fetch(
			`${API_FREIZEITTIPPS_BASE}accessible-stops.json${stops}`,
			fetchOptions,
		).then((response) => response.json());
	}
	return accessibilityCache[stops];
};
