/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';

import Accordion from './Accordion';
import Switch from './Switch';

import { getCookie, setCookie, cleanUpCookies } from './helpers';

import {
	Wrapper,
	Disclaimer,
	Grid,
	GridItem,
	HintText,
	CookieButton,
	SaveSelectedButton,
} from './styles';

export default function CookieBanner() {
	const [cookieSelection, setCookieSelection] = useState(false);
	const [trackingCookies, checkTrackingCookies] = useState(false);
	const [thirdPartyCookies, checkThirdPartyCookies] = useState(false);

	useEffect(() => {
		const newCookieSelection = parseInt(getCookie('vvsOptIn') || '0', 10);

		setCookieSelection(newCookieSelection);

		if ((newCookieSelection & 2) === 2) {
			checkTrackingCookies(true);
		}
		if ((newCookieSelection & 4) === 4) {
			checkThirdPartyCookies(true);
		}
	}, []);

	if (window.location.hostname === 'localhost') return null;

	if (cookieSelection || cookieSelection === false) {
		return null;
	}

	const neccessarySwitch = (
		<Switch id="vvsNeccessary" title="Notwendige Cookies" value="1" checked disabled />
	);
	const trackingSwitch = (
		<Switch
			id="vvsTracking"
			title="Tracking Cookies"
			value="2"
			checked={trackingCookies}
			onChange={checked => checkTrackingCookies(checked)}
		/>
	);
	const thirdPartySwitch = (
		<Switch
			id="vvsThirdParty"
			title="Cookies von Drittanbietern"
			value="4"
			checked={thirdPartyCookies}
			onChange={checked => checkThirdPartyCookies(checked)}
		/>
	);

	return (
		<Wrapper>
			<Disclaimer>
				<Grid>
					<GridItem medium={9} large="auto" smallSpacingXs mediumSpacingNone>
						<HintText>
							Auf unserer Webseite verwenden wir Cookies. Zum einen handelt es sich{' '}
							dabei um notwendige Cookies, um die Basisfunktionen von vvs.de nutzen zu{' '}
							können. Zum anderen nutzen wir Cookies zu Analysezwecken, damit wir die{' '}
							Qualität der Webseite laufend messen und verbessern können. In unserer{' '}
							<a
								href="https://www.vvs.de/datenschutz/"
								title="Datenschutzerklärung"
								target="_blank"
							>
								Datenschutzerklärung
							</a>{' '}
							finden Sie Informationen zu den verwendeten Cookies. Dort, und hier{' '}
							unten unter &quot;Mehr anzeigen&quot;, können Sie die Zustimmung zu{' '}
							einzelnen Cookies setzen oder widerrufen.
						</HintText>
					</GridItem>
					<GridItem medium={3} large="shrink">
						<CookieButton
							onClick={() => {
								setCookie('vvsOptIn', 7);
								setCookieSelection(7);
							}}
						>
							Allen Cookies zustimmen
						</CookieButton>
					</GridItem>
					<GridItem>
						<Accordion closedText="Mehr anzeigen" openedText="Weniger anzeigen" isOuter>
							<Accordion
								closedText="Notwendige Cookies"
								openedText="Notwendige Cookies"
								toggleSwitch={neccessarySwitch}
							>
								<p>
									Session-Cookies: Diese speichern eine sogenannte Session-ID, mit{' '}
									welcher sich verschiedene Anfragen Ihres Browsers der{' '}
									gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner{' '}
									wiedererkannt werden, wenn Sie auf die Website zurückkehren. Die{' '}
									Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder{' '}
									Sie den Browser schließen.
								</p>
							</Accordion>
							<Accordion
								closedText="Tracking Cookies"
								openedText="TrackingCookies"
								toggleSwitch={trackingSwitch}
							>
								<p>
									Auf dieser Website werden mit Technologien von Matomo (
									<a href="https://www.matomo.org" target="_blank">
										www.matomo.org
									</a>
									) Daten zu Marketing- und Optimierungszwecken gesammelt und{' '}
									gespeichert. Alle mitMatomo erhobenen Daten werden{' '}
									ausschließlich auf Servern des VVS erhoben und verarbeitet.
								</p>
							</Accordion>
							<Accordion
								closedText="Cookies von Drittanbietern"
								openedText="Cookies von Drittanbietern"
								toggleSwitch={thirdPartySwitch}
							>
								<ul>
									<li>Feinstaub Widget während Feinstaubperiode</li>
									<li>
										GPS um die Verfolgung der geografischen Position zu{' '}
										ermöglichen und damit die Nutzung der Fahrplanauskunft{' '}
										komfortabler zu machen
									</li>
									<li>
										YouTube Erklär-Videos und Filme/Clips zu verschiedenen{' '}
										Aktionen/Kampagnen
									</li>
								</ul>
							</Accordion>
							<SaveSelectedButton
								onClick={() => {
									let newCookieSelection = 1;
									let needsReload = false;
									if (trackingCookies) {
										newCookieSelection += 2;
									} else if ((cookieSelection & 2) === 2) {
										needsReload = true;
									}
									if (thirdPartyCookies) {
										newCookieSelection += 4;
									} else if ((cookieSelection & 4) === 4) {
										needsReload = true;
									}
									setCookie('vvsOptIn', newCookieSelection);
									cleanUpCookies(newCookieSelection);
									setCookieSelection(newCookieSelection);

									if (needsReload) {
										window.location.reload();
									}
								}}
							>
								Gewählten Cookies zustimmen
							</SaveSelectedButton>
						</Accordion>
					</GridItem>
				</Grid>
			</Disclaimer>
		</Wrapper>
	);
}
