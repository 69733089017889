import { fromLonLat } from 'ol/src/proj';
import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import { getCoordOfPoint } from 'shared';

import Context from '../components/context';
import { createMarker } from '../utils';

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export default function useDrawPointMarkers() {
	const { map, setPointMarkers } = useContext(Context);
	const [points, setPoints] = useState();
	const markers = useRef([]);

	useEffect(() => {
		if (points && map) {
			const newMarkers = points.map((point, i) => {
				const coord = getCoordOfPoint(point);
				const lonLat = fromLonLat([coord[1], coord[0]]);

				const marker = createMarker(point, {
					type: 'routepoint',
					label: ALPHABET.charAt(i),
					state: 3,
					onHover: () => {
						const tooltip = document.getElementById('tooltip');

						ReactDOM.render(
							<div className="marker__tooltip marker__tooltip--sharing marker__tooltip--regiorad">
								<h2 className="tooltipcontent__title">
									{point.shownLabel ||
										point.name ||
										point.disassembledName ||
										point.desc}
								</h2>
							</div>,
							tooltip,
						);

						map.getOverlayById('tooltip').setPosition(lonLat);
						tooltip.style.display = '';
					},
				});

				return marker;
			});

			setPointMarkers(newMarkers);

			markers.current = newMarkers;
		}
		return () => {
			if (!map) return;
			setPointMarkers([]);
		};
	}, [map, points, setPointMarkers]);

	return setPoints;
}
